<script setup lang="ts"></script>
<template>
  <div :class="$style.row">
    <slot />
  </div>
</template>
<style module lang="scss">
.row {
  display: flex;
  min-width: 100%;
  margin-left: -12px;
  margin-right: -12px;
}
</style>
