<script setup lang="ts">
import {
  loadStripe,
  SetupIntent,
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret,
} from '@stripe/stripe-js'
import Alert from '@/components/common/Alert.vue'
import Button from '@/components/common/Button.vue'
import { useWorkspaceApi } from '@/composables/api'
import { onMounted, reactive } from 'vue'

defineProps<{
  cta?: string
}>()

let stripe: Stripe | null = null
const { api } = useWorkspaceApi()

const returnUrl = `${
  new URL(location.pathname, location.href).href
}?flow=upgrade`

const emit = defineEmits<{
  (e: 'complete', intent: SetupIntent): void
}>()

const state = reactive<{
  loading: boolean
  error?: string
  elements?: StripeElements
}>({
  loading: false,
})

const createStripeElement = () => {
  api.workspace.billingIntent().then((data) => {
    const options: StripeElementsOptionsClientSecret = {
      clientSecret: data.secret,
      appearance: {
        theme: 'stripe',
        variables: {
          fontFamily: '"Inter, Helvetica Neue", Helvetica, sans-serif',
        },
      },
    }
    state.elements = stripe?.elements(options)
    const paymentElement = state.elements?.create('payment')
    paymentElement?.mount('#stripe-element')
  })
}
const verifySetupIntent = (clientSecret: string) => {
  state.loading = true
  stripe?.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
    switch (setupIntent?.status) {
      case 'succeeded': {
        emit('complete', setupIntent)
        break
      }
      case 'processing': {
        state.loading = false
        state.error =
          "Processing payment details. We'll update you when processing is complete."
        break
      }
      case 'requires_payment_method': {
        state.loading = false
        state.error =
          'Failed to process payment details. Please try another payment method.'
        break
      }
    }
  })
}
const handleSubmit = () => {
  state.loading = true
  stripe &&
    state.elements &&
    stripe
      .confirmSetup({
        elements: state.elements,
        confirmParams: {
          return_url: returnUrl,
        },
      })
      .then((result) => {
        if (result.error) {
          state.loading = false
          state.error = result.error.message
        }
      })
}

onMounted(async () => {
  stripe = await loadStripe(import.meta.env.VITE_APP_STRIPE_KEY)
  const clientSecret = new URLSearchParams(window.location.search).get(
    'setup_intent_client_secret',
  )
  if (clientSecret) {
    verifySetupIntent(clientSecret)
  } else {
    createStripeElement()
  }
})
</script>
<template>
  <form :class="$style.form" @submit.prevent="handleSubmit">
    <Alert :error="state.error" />
    <div id="stripe-element"></div>
    <div :class="$style.footer">
      <Button
        size="3"
        variant="solid"
        theme="neutral"
        :loading="state.loading"
        >{{ cta ?? 'Pay Now' }}</Button
      >
      <p>Cancel anytime within the trial period to avoid charges.</p>
    </div>
  </form>
</template>
<style module lang="scss">
.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  justify-content: flex-end;
  row-gap: 12px;
  margin-top: 8px;

  p {
    @extend .regular-2;
    text-align: center;
    color: var(--neutral-11);
  }
}
</style>
