<script setup lang="ts">
import { ref } from 'vue'
import { Button } from './'
import { showToast } from '@/utils'

const codeRef = ref<HTMLElement>()
defineProps<{
  code: string
}>()

const handleCopy = () => {
  codeRef.value &&
    navigator.clipboard.writeText(codeRef.value?.innerText).then(() => {
      showToast({ text: 'Copied!' })
    })
}
</script>
<template>
  <div :class="[$style.code, 'theme-dark']">
    <code ref="codeRef" v-html="code" />
    <Button
      size="2"
      variant="soft"
      theme="neutral"
      icon="squareBehindSquare1"
      :class="$style.copy"
      @click="handleCopy"
    />
  </div>
</template>
<style module lang="scss">
.code {
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--neutral-alpha-4);
  background-color: var(--panel-2);
  padding: 24px 80px 24px 24px;

  code {
    color: var(--neutral-12);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .copy {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
</style>
