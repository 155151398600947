import { LexoRank } from 'lexorank'

export const findSiblings = <T>(
  index: number,
  oldIndex: number | null,
  items: T[],
): { previous?: T; next?: T } => {
  const adjustedArray = [...items]
  oldIndex !== null && adjustedArray.splice(oldIndex, 1)
  adjustedArray.splice(index, 0, {} as T)

  if (index === 0) {
    return { next: adjustedArray[1] }
  } else if (index === adjustedArray.length) {
    return { previous: adjustedArray[index - 1] }
  } else {
    return {
      previous: adjustedArray[index - 1],
      next: adjustedArray[index + 1],
    }
  }
}

export const getNewLexorank = <T extends { rank: string }>(payload: {
  previous?: T
  next?: T
}): LexoRank => {
  const { previous, next } = payload
  if (!previous && !!next) {
    return LexoRank.parse(next.rank).genPrev()
  } else if (!next && !!previous) {
    return LexoRank.parse(previous.rank).genNext()
  } else if (!!previous && !!next) {
    return LexoRank.parse(next.rank).between(LexoRank.parse(previous.rank))
  }

  return LexoRank.min()
}
