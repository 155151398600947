<script setup lang="ts">
import {
  Button,
  Confirm,
  DropDown,
  Icon,
  Popover,
  Popup,
  type DropDownItem,
} from '@/components/common'
import type { Team } from '../../models/team'
import { computed, reactive, ref } from 'vue'
import { useTasksStore } from '../../store'
import ManageMembers from '../ManageMembers.vue'
import IconMenu from '../IconMenu.vue'

const store = useTasksStore()
const props = defineProps<{
  team: Team
}>()

const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const memberPopup = ref<InstanceType<typeof Popup> | null>(null)

const rename = reactive({
  isActive: false,
  name: props.team.name,
})

const handleUpdate = () => {
  rename.name.length &&
    store.updateTeam(props.team.id, { name: rename.name }).then(() => {
      rename.isActive = false
    })
}

const menu: DropDownItem[] = [
  {
    id: 'rename',
    title: 'Rename',
    handler: () => {
      rename.isActive = true
    },
  },
  {
    id: 'manage',
    title: 'Manage Members',
    handler: () => memberPopup.value?.show(),
  },
  {
    id: 'delete',
    title: 'Delete',
    type: 'destructive',
    handler: () =>
      confirmPopup.value?.show({
        confirmString: 'delete',
        title: 'Delete team',
        message: `You're about to delete team with ${props.team.tasksCount} tasks in it. This action is permanent and cannot be undone. You and your workspace members will lose access to this team forever. Please, confirm your purpose by typing “delete” in the input below. `,
        actions: [
          {
            title: 'Cancel',
            theme: 'neutral',
            variant: 'ghost',
          },
          {
            title: `Delete Column`,
            theme: 'danger',
            isConfirm: true,
            handler: () => store.deleteTeam(props.team.id),
          },
        ],
      }),
  },
]

const teamIcon = computed(() => {
  return props.team.icon || 'arCube1'
})

const teamColor = computed(() => {
  return rename.isActive ? '#1e1f24' : props.team.color
})
</script>
<template>
  <router-link
    v-slot="{ navigate }"
    custom
    :to="{
      name: 'TasksTeam',
      params: { teamId: team.id },
    }"
  >
    <tr :class="$style.row">
      <td width="32">
        <Popover>
          <template #content>
            <IconMenu
              :team="team"
              @update="(data) => store.updateTeam(team.id, data)"
            />
          </template>
          <Button variant="ghost" theme="neutral">
            <template #icon>
              <span :class="$style.pipelineIcon">
                <Icon
                  :icon="rename.isActive ? 'pencil' : teamIcon"
                  :size="18"
                  :class="$style['team-color']"
                />
              </span>
            </template>
          </Button>
        </Popover>
      </td>
      <td v-if="rename.isActive" :class="$style.title">
        <input
          v-model="rename.name"
          v-focus
          :class="$style.textField"
          @keypress.enter="handleUpdate"
        />
      </td>
      <td v-else :class="$style.title" @click="navigate">
        {{ team.name }}
      </td>
      <td width="124" :class="$style.meta" @click="navigate">
        {{ team.tasksCount || 0 }} tasks
      </td>
      <td width="124" :class="$style.meta" @click="navigate">
        {{ team.membersCount || 0 }} members
      </td>
      <td width="32">
        <DropDown :width="160" :menu="menu" :close-on-select="true">
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            icon="dotGrid1x3Horizontal"
          />
        </DropDown>
        <Confirm ref="confirmPopup" />
        <Popup ref="memberPopup" title="Members">
          <ManageMembers :team="team" />
        </Popup>
      </td>
    </tr>
  </router-link>
</template>
<style module lang="scss">
@import url(./common.module.scss);
.title {
  @extend .medium-3;
}
.meta {
  @extend .regular-3;
  color: var(--neutral-11);
}
.row {
  cursor: pointer;

  .team-color {
    color: v-bind('teamColor') !important;
  }
}
</style>
