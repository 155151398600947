<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  title: string
}>()
const { title } = toRefs(props)
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.head">
      <hr />
      <div :class="$style.head_title">DANGER ZONE</div>
    </div>
    <div :class="$style.actions">
      <div :class="$style.title">{{ title }}</div>

      <slot />
    </div>
  </div>
</template>

<style module lang="scss">
.container {
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 16px;
    width: 100%;

    hr {
      width: 100%;
      margin: 0;
      height: 2px;
      border: none;
      background-size: 16px 50%;
      background-image: linear-gradient(
        to right,
        transparent 8px,
        var(--neutral-alpha-4) 8px
      );
    }

    .head_title {
      @extend .medium-2;

      position: absolute;
      color: var(--neutral-alpha-10);
      background-color: var(--panel-1);
      padding: 0 4px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      @extend .medium-3;
    }
  }
}
</style>
