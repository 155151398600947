import type { InjectionKey, Ref } from 'vue'
import Icon from './Icon.vue'
import App from '@/models/app'

type IconInstance = InstanceType<typeof Icon>

export interface DropDownItem {
  id?: string | number
  type?: 'separator' | 'item' | 'primary' | 'destructive'
  title?: string
  subtitle?: string
  icon?: IconInstance['$props']['icon']
  handler?: () => void
}

export type SelectItem = Record<string, unknown>

// Provide Inject Keys
export const PopupKey = {
  show: Symbol() as InjectionKey<() => void>,
  hide: Symbol() as InjectionKey<() => void>,
}

export const PopoverKey = {
  show: Symbol() as InjectionKey<() => void>,
  hide: Symbol() as InjectionKey<() => void>,
}

export const UpgradePopupKey = {
  show: Symbol() as InjectionKey<() => void>,
}

export const ActiveAppKey = Symbol() as InjectionKey<Ref<App | null>>
