<script setup lang="ts">
import { provide } from 'vue'
import { PopoverKey } from '../types'

const props = defineProps<{ close: () => void }>()
provide(PopoverKey.hide, props.close)
</script>
<template>
  <div :class="$style.container"><slot /></div>
</template>
<style module lang="scss">
.container {
  margin: 12px 0px;
  border-radius: 8px;
  border: 1px solid var(--neutral-alpha-4);
  background: var(--panel-translucent);
  overflow: hidden;
  /* Shadow/With Blur */
  box-shadow:
    0px 12px 32px -16px var(--support-alternative-shadow-blur-1),
    0px 12px 60px 0px var(--support-alternative-shadow-blur-2);

  backdrop-filter: blur(24px);
}
</style>
