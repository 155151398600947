<script setup lang="ts">
import {
  Popover,
  ResourceSelector,
  AddTag,
  MenuItem,
  EmptyView,
  Tag,
} from '@/components/common'
import { useCrmStore } from '../../store'
import { toRefs } from 'vue'
import type { PipelineContact } from '../../models/pipelineContact'
import { useCrmPipelineStore } from '../../pipelineStore'
import type { Tag as TagModel } from '@/models/tag'

const props = defineProps<{
  contact: PipelineContact
  mode: 'pipeline' | 'global'
}>()
const { contact, mode } = toRefs(props)

const store = useCrmPipelineStore()

const crmStore = useCrmStore()
const { tags } = toRefs(crmStore)

const handleOpen = () => {
  tags.value.length === 0 && crmStore.loadTags()
}

const handleTags = (data: TagModel[]) => {
  const action =
    mode.value === 'pipeline' ? store.addContactTags : crmStore.addContactTags
  action(contact.value, data)
}
</script>
<template>
  <Popover @open="handleOpen" :class="$style.popper">
    <template #content>
      <ResourceSelector
        :items="tags"
        :selected="contact.tags || []"
        item-key="id"
        title-key="name"
        selection="multi"
        @update="handleTags"
      >
        <template #empty="{ search }">
          <MenuItem
            v-if="search.length"
            :title="`Create new tag '${search}'`"
            @click="crmStore.createTag(search)"
          />
          <EmptyView v-else title="No tags found" />
        </template>
      </ResourceSelector>
    </template>
    <div :class="$style.display">
      <div v-if="contact.tags?.length" :class="$style.tags">
        <Tag
          v-for="tag in contact.tags"
          :key="tag.id"
          variant="outline"
          size="2"
        >{{ tag.name }}</Tag
        >
      </div>
      <AddTag v-else size="1">Add Tag</AddTag>
    </div>
  </Popover>
</template>
<style module lang="scss">
.popper {
  width: 100%;
  height: 100%;

  > div:first-of-type {
    height: 100%;
  }

  .display {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0 16px;
    display: flex;
    align-items: center;

    .tags {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 100%;
    }
  }
}

</style>
