<script setup lang="ts">
import { Button, Icon, Popover } from '@/components/common'
import FilterMenu from './menus/FilterMenu.vue'
import type { ContactSearch } from '../store'

const emit = defineEmits<{
  (e: 'update', data: ContactSearch): void
}>()
const { search } = defineProps<{
  search: ContactSearch
  mode: 'pipeline' | 'global'
}>()

const handleRemove = (k: keyof ContactSearch) => {
  emit('update', { ...search, [k]: undefined })
}
</script>
<template>
  <hr :class="$style.hr" />
  <div :class="$style.container">
    <div v-if="search.assignee?.length" :class="$style.tag">
      {{ search.assignee?.length }} assignee
      <Icon icon="crossSmall" :size="16" @click="handleRemove('assignee')" />
    </div>
    <div v-if="search.company?.length" :class="$style.tag">
      {{ search.company?.length }} companies
      <Icon icon="crossSmall" :size="16" @click="handleRemove('company')" />
    </div>
    <div v-if="search.status?.length" :class="$style.tag">
      {{ search.status?.length }} statuses
      <Icon icon="crossSmall" :size="16" @click="handleRemove('status')" />
    </div>
    <div v-if="search.pipeline?.length" :class="$style.tag">
      {{ search.pipeline?.length }} pipelines
      <Icon icon="crossSmall" :size="16" @click="handleRemove('pipeline')" />
    </div>
    <div v-if="search.tags?.length" :class="$style.tag">
      {{ search.tags?.length }} tags
      <Icon icon="crossSmall" :size="16" @click="handleRemove('tags')" />
    </div>
    <Popover>
      <template #content>
        <FilterMenu
          :mode="mode"
          :search="search"
          @update="(data) => $emit('update', data)"
        />
      </template>
      <Button size="2" variant="ghost" theme="neutral" icon="circlePlus" />
    </Popover>
  </div>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 32px;
}
.hr {
  margin-top: -16px;
  margin-bottom: 16px;
}
.tag {
  @extend .medium-3;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--neutral-alpha-3);
  padding: 0px 12px;
  height: 32px;
  column-gap: 4px;

  i {
    cursor: pointer;
  }
}
</style>
