<script setup lang="ts">
import { useTasksTeamStore } from '@modules/tasks/teamStore.ts'
import { storeToRefs } from 'pinia'
import type { Task } from '@/modules/tasks/models/task.ts'
import type { SortableEvent } from '@modules/tasks/components/BoardView/types.ts'
import type { TaskStatus } from '@modules/tasks/models/taskStatus.ts'
import draggable from 'vuedraggable'
import StatusGroup from '@modules/tasks/components/ListView/StatusGroup.vue'

const store = useTasksTeamStore()

const  { statuses , stageTaskMap: tasksPerStatuses } = storeToRefs(store)
const handleStatusMove = (event: SortableEvent<Task>) => {
  const { newIndex, oldIndex } = event
  store.moveStatus(oldIndex, newIndex)
}
</script>

<template>
  <div>
    <draggable
      :class="$style.list"
      :model-value="statuses"
      :disabled="true"
      animation="200"
      group="stages"
      item-key="id"
      @update="handleStatusMove"
    >
      <template #item="{ element: status }: { element: TaskStatus }">
       <StatusGroup :status="status" :tasks="tasksPerStatuses[status.id] || []"/>
      </template>
    </draggable>
  </div>
</template>

<style module lang="scss">
.list {
  padding-bottom: 8px;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: var(--neutral-12);
  }
}
</style>
