<script setup lang="ts">
import { toRefs } from 'vue'
import type { PipelineStage } from '../../models/pipelineStage'
import { useCrmPipelineStore } from '../../pipelineStore'
import StageIndicator from '../StageIndicator.vue'
import { ResourceSelector, Button, Popover } from '@/components/common'
import type { PipelineContact } from '../../models/pipelineContact'

defineProps<{
  contact: PipelineContact
  stage: PipelineStage
}>()

const store = useCrmPipelineStore()
const { stages } = toRefs(store)
</script>
<template>
  <Popover v-if="stage && contact">
    <template #content>
      <ResourceSelector
        :items="stages"
        :selected="stage"
        item-key="id"
        title-key="title"
        selection="single"
        @update="(item) => store.moveContactToStage(contact, item)"
      >
        <template #icon="{ item }">
          <StageIndicator
            :size="16"
            :stage="item?.order || 0"
            :total-stages="stages.length"
          />
        </template>
      </ResourceSelector>
    </template>

    <Button size="2" variant="ghost" theme="neutral">
      <template #icon>
        <StageIndicator
          :size="16"
          :stage="stage.order"
          :total-stages="stages.length"
        />
      </template>
      {{ stage.title }}
    </Button>
  </Popover>
</template>
<style module lang="scss"></style>
