<script setup lang="ts">
import { TaskLog } from '@modules/tasks/models/taskLog.ts'
import { Activity } from '@/models/activity.ts'
import TaskActivity from '@modules/tasks/components/Log/TaskActivity.vue'
import TaskComment from '@modules/tasks/components/Log/TaskComment.vue'
import type { TaskComment as TaskCommentModel } from '@modules/tasks/models/taskComment.ts'

defineProps<{ taskLog: TaskLog }>()
const emit = defineEmits<{
  (e: 'commentReply', data: TaskCommentModel): void
}>()
</script>

<template>
  <TaskActivity
    v-if="taskLog.loggableType === `App\\Models\\Activity`"
    :activity="taskLog.loggable as Activity"
  />
  <TaskComment
    v-else-if="taskLog.loggableType === `App\\Modules\\Task\\Models\\Comment`"
    :comment="taskLog.loggable as TaskCommentModel"
    @reply="(data) => emit('commentReply', data)"
  />
</template>
