<script setup lang="ts">
import { Popup, TextField, Button } from '@/components/common'
import { onMounted, ref } from 'vue'
import { clsx } from 'clsx'

const link = ref<string>('')
const popup = ref<InstanceType<typeof Popup> | null>(null)
const props = defineProps<{
  onAdd: (payload: { href: string }) => void
}>()

const handleAdd = () => {
  if (link.value.length === 0) return
  props.onAdd({ href: link.value })
  popup.value?.hide()
}

onMounted(() => {
  popup.value?.show()
})
</script>
<template>
  <Popup ref="popup" title="Add Link">
    <div :class="clsx($style.container, 'popup-content')">
      <TextField v-model="link" type="url" placeholder="Link" />
    </div>
    <div class="popup-content popup-actions">
      <Button size="2" theme="neutral" @click="popup?.hide">Cancel</Button>
      <Button size="2" :disabled="link.length === 0" @click="handleAdd"
        >Add link</Button
      >
    </div>
  </Popup>
</template>
<style module lang="scss">
.container {
  width: 360px;

  input {
    width: 100%;
  }
}
</style>
