<script setup lang="ts">
import { toRefs } from 'vue'
import { Icon } from '.'

type IconInstance = InstanceType<typeof Icon>

const props = defineProps<{
  size?: '1' | '2' | '3'
  icon?: IconInstance['$props']['icon']
}>()
const { size, icon } = toRefs(props)
</script>
<template>
  <span :class="[$style.container, $style[`size-${size}`]]">
    <Icon v-if="icon" :icon="icon" />
    <slot />
  </span>
</template>
<style module lang="scss">
.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9999px;
}

.size {
  &-1 {
    @extend .medium-2;
    padding: 0px 6px;
    height: 20px;
    column-gap: 2px;

    i {
      width: 12px;
      height: 12px;
    }
  }

  &-2 {
    @extend .medium-2;
    padding: 0px 8px;
    height: 24px;
    column-gap: 4px;

    i {
      width: 16px;
      height: 16px;
    }
  }

  &-3 {
    @extend .medium-3;
    padding: 0px 12px;
    height: 32px;
    column-gap: 4px;

    i {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
