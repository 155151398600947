import {
  wrapItem,
  blockTypeItem,
  Dropdown,
  DropdownSubmenu,
  icons,
  MenuItem,
  type MenuElement,
  type MenuItemSpec,
} from 'prosemirror-menu'
import { toggleMark } from 'prosemirror-commands'
import { wrapInList } from 'prosemirror-schema-list'
import AddLink from './AddLink.vue'
import { render, h } from 'vue'
import type { Command, EditorState } from 'prosemirror-state'
import type { Attrs, MarkType, NodeType, Schema } from 'prosemirror-model'

// Helpers to create specific types of items

type Options = Partial<MenuItemSpec> & {
  attrs?: Attrs | null
}

function canInsert(state: EditorState, nodeType: NodeType) {
  const $from = state.selection.$from
  for (let d = $from.depth; d >= 0; d--) {
    const index = $from.index(d)
    if ($from.node(d).canReplaceWith(index, index, nodeType)) return true
  }
  return false
}

function cmdItem(cmd: Command, options: Options) {
  const passedOptions: MenuItemSpec = {
    label: options.title as string,
    ...options,
    run: cmd,
  }
  if (!options.enable && !options.select)
    passedOptions[options.enable ? 'enable' : 'select'] = (state) => cmd(state)

  return new MenuItem(passedOptions)
}

function markActive(state: EditorState, type: MarkType) {
  const { from, $from, to, empty } = state.selection
  if (empty) return !!type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

function markItem(markType: MarkType, options: Options) {
  const passedOptions: Options = {
    ...options,
    active(state) {
      return markActive(state, markType)
    },
    enable(state) {
      return !state.selection.empty
    },
  }
  return cmdItem(toggleMark(markType), passedOptions)
}

function linkItem(markType: MarkType) {
  return new MenuItem({
    title: 'Add or remove link',
    icon: icons.link,
    active(state) {
      return markActive(state, markType)
    },
    enable(state) {
      return !state.selection.empty
    },
    run(state, dispatch, view) {
      if (markActive(state, markType)) {
        toggleMark(markType)(state, dispatch)
        return true
      }
      const vueComponent = h(AddLink, {
        onAdd: (attrs) => {
          toggleMark(markType, attrs)(view.state, view.dispatch)
          view.focus()
        },
      })
      const wrapper = document.body.appendChild(document.createElement('div'))
      render(vueComponent, wrapper)
    },
  })
}

function wrapListItem(nodeType: NodeType, options: Options) {
  return cmdItem(wrapInList(nodeType, options.attrs), options)
}

const cut = (arr: MenuElement[]) => arr.filter((x) => x)

// :: (Schema) → Object
// Given a schema, look for default mark and node types in it and
// return an object with relevant menu items relating to those marks:
//
// **`toggleStrong`**`: MenuItem`
//   : A menu item to toggle the [strong mark](#schema-basic.StrongMark).
//
// **`toggleEm`**`: MenuItem`
//   : A menu item to toggle the [emphasis mark](#schema-basic.EmMark).
//
// **`toggleCode`**`: MenuItem`
//   : A menu item to toggle the [code font mark](#schema-basic.CodeMark).
//
// **`toggleLink`**`: MenuItem`
//   : A menu item to toggle the [link mark](#schema-basic.LinkMark).
//
// **`insertImage`**`: MenuItem`
//   : A menu item to insert an [image](#schema-basic.Image).
//
// **`wrapBulletList`**`: MenuItem`
//   : A menu item to wrap the selection in a [bullet list](#schema-list.BulletList).
//
// **`wrapOrderedList`**`: MenuItem`
//   : A menu item to wrap the selection in an [ordered list](#schema-list.OrderedList).
//
// **`wrapBlockQuote`**`: MenuItem`
//   : A menu item to wrap the selection in a [block quote](#schema-basic.BlockQuote).
//
// **`makeParagraph`**`: MenuItem`
//   : A menu item to set the current textblock to be a normal
//     [paragraph](#schema-basic.Paragraph).
//
// **`makeCodeBlock`**`: MenuItem`
//   : A menu item to set the current textblock to be a
//     [code block](#schema-basic.CodeBlock).
//
// **`makeHead[N]`**`: MenuItem`
//   : Where _N_ is 1 to 6. Menu items to set the current textblock to
//     be a [heading](#schema-basic.Heading) of level _N_.
//
// **`insertHorizontalRule`**`: MenuItem`
//   : A menu item to insert a horizontal rule.
//
// The return value also contains some prefabricated menu elements and
// menus, that you can use instead of composing your own menu from
// scratch:
//
// **`insertMenu`**`: Dropdown`
//   : A dropdown containing the `insertImage` and
//     `insertHorizontalRule` items.
//
// **`typeMenu`**`: Dropdown`
//   : A dropdown containing the items for making the current
//     textblock a paragraph, code block, or heading.
//
// **`fullMenu`**`: [[MenuElement]]`
//   : An array of arrays of menu elements for use as the full menu
//     for, for example the [menu bar](https://github.com/prosemirror/prosemirror-menu#user-content-menubar).
export function buildMenuItems(schema: Schema) {
  const r: Record<string, MenuElement> = {}
  let type
  if ((type = schema.marks.strong))
    r.toggleStrong = markItem(type, {
      title: 'Toggle strong style',
      icon: icons.strong,
    })
  if ((type = schema.marks.em))
    r.toggleEm = markItem(type, { title: 'Toggle emphasis', icon: icons.em })
  if ((type = schema.marks.code))
    r.toggleCode = markItem(type, {
      title: 'Toggle code font',
      icon: icons.code,
    })
  if ((type = schema.marks.link)) r.toggleLink = linkItem(type)

  if ((type = schema.nodes.bullet_list))
    r.wrapBulletList = wrapListItem(type, {
      title: 'Wrap in bullet list',
      icon: icons.bulletList,
    })
  if ((type = schema.nodes.ordered_list))
    r.wrapOrderedList = wrapListItem(type, {
      title: 'Wrap in ordered list',
      icon: icons.orderedList,
    })
  if ((type = schema.nodes.blockquote))
    r.wrapBlockQuote = wrapItem(type, {
      title: 'Wrap in block quote',
      icon: icons.blockquote,
    })
  if ((type = schema.nodes.paragraph))
    r.makeParagraph = blockTypeItem(type, {
      title: 'Change to paragraph',
      label: 'Plain',
      class: 'ProseMirror-icon',
    })
  if ((type = schema.nodes.code_block))
    r.makeCodeBlock = blockTypeItem(type, {
      title: 'Change to code block',
      label: 'Code',
    })
  if ((type = schema.nodes.heading))
    for (let i = 1; i <= 10; i++)
      r['makeHead' + i] = blockTypeItem(type, {
        title: 'Change to heading ' + i,
        label: 'H' + i,
        attrs: { level: i },
        class: 'ProseMirror-icon',
      })
  if ((type = schema.nodes.horizontal_rule)) {
    const hr = type
    r.insertHorizontalRule = new MenuItem({
      title: 'Insert horizontal rule',
      label: 'Horizontal rule',
      enable(state) {
        return canInsert(state, hr)
      },
      run(state, dispatch) {
        dispatch(state.tr.replaceSelectionWith(hr.create()))
      },
    })
  }

  r.insertMenu = new Dropdown(cut([r.insertImage, r.insertHorizontalRule]), {
    label: 'Insert',
  })
  r.typeMenu = new Dropdown(
    cut([
      r.makeParagraph,
      r.makeCodeBlock,
      r.makeHead1 &&
        new DropdownSubmenu(
          cut([
            r.makeHead1,
            r.makeHead2,
            r.makeHead3,
            r.makeHead4,
            r.makeHead5,
            r.makeHead6,
          ]),
          { label: 'Heading' },
        ),
    ]),
    { label: 'Type...' },
  )

  return r
}

export const composeMenu = (schema: Schema) => {
  const r = buildMenuItems(schema)
  return [
    cut([r.toggleStrong, r.toggleEm, r.toggleCode, r.toggleLink]),
    [r.makeHead1, r.makeHead2, r.makeParagraph],
  ]
}
