import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './routes'
import Pusher from 'pusher-js'
import Vue3Toasity, { toast, type ToastContainerOptions } from 'vue3-toastify'

import '@stripe/stripe-js'
import * as Sentry from '@sentry/vue'

import './css/index.scss'

import posthog from 'posthog-js'

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
})

window.Pusher = Pusher

const app = createApp(App)

app.directive('focus', {
  mounted(el) {
    el.focus()
  },
})

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_API_HOST],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(router)
app.use(Vue3Toasity, {
  autoClose: 3000,
  position: toast.POSITION.BOTTOM_CENTER,
  theme: 'dark',
  hideProgressBar: true,
  transition: 'slide',
} as ToastContainerOptions)
app.use(createPinia())
app.mount('#app')
