<script setup lang="ts">
import { provide, readonly, ref, toRefs } from 'vue'
import Popper from 'vue3-popper'
import PopoverContainer from './PopoverContent.vue'
import { onClickOutside } from '@vueuse/core'

defineOptions({ inheritAttrs: false })

type PopperInstance = InstanceType<typeof Popper>

const props = defineProps<{
  placement?: PopperInstance['$props']['placement']
  offset?: PopperInstance['$props']['offsetDistance']
}>()
const { placement, offset } = toRefs(props)

const emit = defineEmits<{
  (e: 'open'): void
  (e: 'close'): void
  (e: 'closeOutside'): void
}>()

const isOpen = ref(false)

const popperRef = ref(null)
onClickOutside(popperRef, () => {
  emit('closeOutside')
})

const handleOpen = () => {
  isOpen.value = true
  emit('open')
}
const handleClose = () => {
  isOpen.value = false
  emit('close')
}

provide('isOpen', readonly(isOpen))
</script>
<template>
  <Popper
    ref="popperRef"
    :placement="placement"
    :show="$attrs.show as boolean"
    :offset-distance="offset || '0'"
    v-bind="$attrs"
    @open:popper="handleOpen"
    @close:popper="handleClose"
  >
    <slot :is-open="isOpen"></slot>
    <template #content="data">
      <PopoverContainer v-if="isOpen || $attrs.show" v-bind="data">
        <slot name="content" v-bind="data"></slot>
      </PopoverContainer>
    </template>
  </Popper>
</template>
