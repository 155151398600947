import type User from '@/models/user'
import { type AxiosInstance } from 'axios'

export class AccountService {
  constructor(private instance: AxiosInstance) {}

  update = (body: Partial<User>) =>
    this.instance
      .put<User>('/user/profile-information', body)
      .then((res) => res.data)

  uploadAvatar = (file: File) => {
    const formData = new FormData()
    formData.append('avatar', file)
    return this.instance
      .post<string>('/users/avatar', formData)
      .then((res) => res.data)
  }

  getById = (id: string) =>
    this.instance.get<User>(`/users/${id}`).then((res) => res.data)

  updateEmail = (email: string, password: string) =>
    this.instance
      .put<User>('/user/profile-information', { email, password })
      .then((res) => res.data)

  updatePassword = (current: string, password: string) =>
    this.instance
      .put(`/user/password`, { current_password: current, password })
      .then((res) => res.data)

  deleteAccount = () => undefined
}
