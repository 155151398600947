<script setup lang="ts">
import { computed, ref, toRefs } from 'vue'

import {
  Avatar,
  Button,
  DropDown,
  Icon,
  Popup,
  type DropDownItem,
} from '@/components/common'
import type { WorkspaceMember } from '@/models/user'
import type WorkspaceInvitation from '@/models/workspaceInvitation'
import { useApplicationStore } from '@/store'
import { useWorkspaceStore } from '@/store/workspace'
import { showToast } from '@/utils'

const props = defineProps<{
  member?: WorkspaceMember
  invitation?: WorkspaceInvitation
}>()
const { member, invitation } = toRefs(props)
const { user } = useApplicationStore()

const workspaceStore = useWorkspaceStore()

const deleteMemberPopup = ref<InstanceType<typeof Popup> | null>()
const deleteInvitationsPopup = ref<InstanceType<typeof Popup> | null>()
const updateRolePopup = ref<InstanceType<typeof Popup> | null>()

const isMemberOwner = computed(() => member.value?.role === 'owner')

const handleCopyEmail = () => {
  const email = member.value?.email || invitation.value?.email

  navigator.clipboard.writeText(email ?? '').then(() => {
    showToast({ text: 'Email copied to clipboard', type: 'success' })
  })
}

const handleCancelInvite = () => {
  deleteInvitationsPopup.value?.show()
}

const handleRemoveInvitation = async () => {
  invitation.value && workspaceStore.removeInvitation(invitation.value.id)
  deleteInvitationsPopup.value?.hide()

  showToast({ text: 'Invite canceled', type: 'success' })
}

const handleUpdateRole = async () => {
  const newRole = isMemberOwner.value ? 'admin' : 'owner'

  member.value && workspaceStore.updateRole(member.value.id, newRole)

  updateRolePopup.value?.hide()

  const value = member.value?.name ?? member.value?.email ?? 'User'
  showToast({ text: `${value} is now ${newRole}`, type: 'success' })
}

const handleRemoveMember = async () => {
  const value = member.value?.name ?? member.value?.email ?? 'User'

  member.value && workspaceStore.removeMember(member.value.id)
  deleteMemberPopup.value?.hide()

  showToast({ text: `${value} was removed`, type: 'success' })
}

const copyEmailOption = computed<DropDownItem>(() => ({
  title: 'Copy email',
  handler: handleCopyEmail,
}))

const cancelInvite = computed<DropDownItem>(() => ({
  title: 'Cancel invite',
  handler: handleCancelInvite,
}))

const changeRoleOption = computed<DropDownItem>(() => ({
  title: `Make ${isMemberOwner.value ? 'Admin' : 'Owner'}`,
  handler: () => updateRolePopup.value?.show(),
}))

const removeMemberOption = computed<DropDownItem>(() => ({
  title: 'Remove member',
  handler: () => deleteMemberPopup.value?.show(),
}))

const dropdownOptions = computed<DropDownItem[]>(() => {
  const isCurrentUser = member.value?.id && member.value?.id === user?.id

  if (isCurrentUser || isMemberOwner.value) {
    return [copyEmailOption.value]
  }

  if (invitation.value) {
    return [copyEmailOption.value, cancelInvite.value]
  }

  return [
    changeRoleOption.value,
    copyEmailOption.value,
    removeMemberOption.value,
  ]
})

const displayData = computed(() => {
  if (member.value) {
    const name = member.value.name?.trim()

    return {
      main: name || member.value?.email,
      sub: name && member.value?.email,
    }
  }

  return {
    main: invitation.value?.email,
  }
})
</script>
<template>
  <DropDown
    close-on-select
    placement="bottom-end"
    :menu="dropdownOptions"
    :class="$style.dropdown"
    :width="160"
  >
    <template #default="{ isOpen }">
      <Button
        size="2"
        variant="ghost"
        theme="neutral"
        icon="dotGrid1x3Horizontal"
        :class="isOpen && $style.opened"
      />
    </template>
  </DropDown>

  <Popup ref="deleteMemberPopup" title="Remove member">
    <div :class="$style.content">
      <div :class="$style.description">
        You&apos;re about to remove this member from your workspace. This member
        will lose his access to all apps in this workspace.
      </div>

      <div :class="$style.member">
        <Avatar
          v-if="member?.avatar"
          :src="member?.avatar"
          :title="displayData.main"
          :size="32"
        />
        <div v-else :class="$style.placeholder">
          <Icon icon="people" :size="18" />
        </div>

        <div :class="$style.labels">
          <div :class="$style.main">{{ displayData.main }}</div>
          <div :class="$style.sub">{{ displayData.sub }}</div>
        </div>

        <Icon icon="peopleRemove" :size="20" :class="$style.icon" />
      </div>
    </div>

    <div :class="$style.actions">
      <Button
        type="submit"
        size="3"
        variant="solid"
        theme="danger"
        @click.prevent="handleRemoveMember"
      >
        Remove member
      </Button>
    </div>
  </Popup>

  <Popup
    ref="updateRolePopup"
    :title="`Make ${isMemberOwner ? 'admin' : 'owner'}`"
  >
    <div :class="$style.content">
      <div v-if="isMemberOwner" :class="$style.description">
        You&apos;re about to promote this member to Admin role.
      </div>

      <div v-else :class="$style.description">
        You&apos;re about to promote this member to Owner role. Owners have
        access to billing settings and can invite or delete members in this
        workspace.
      </div>

      <div :class="$style.member">
        <Avatar v-if="member?.avatar" :src="member?.avatar" :size="32" />
        <div v-else :class="$style.placeholder">
          <Icon icon="people" :size="18" />
        </div>

        <div :class="$style.labels">
          <div :class="$style.main">{{ displayData.main }}</div>
          <div :class="$style.sub">{{ displayData.sub }}</div>
        </div>

        <Icon icon="arrowUpCircle" :size="20" :class="$style.icon" />
      </div>
    </div>

    <div :class="$style.actions">
      <Button
        type="submit"
        size="3"
        variant="solid"
        theme="neutral"
        @click.prevent="handleUpdateRole"
      >
        Make {{ isMemberOwner ? 'admin' : 'owner' }}
      </Button>
    </div>
  </Popup>

  <Popup ref="deleteInvitationsPopup" title="Remove Invitation">
    <div :class="$style.content">
      <div :class="$style.description">
        Are you sure you want to remove this invitation from your workspace?
      </div>

      <div :class="$style.member">
        <div :class="$style.placeholder">
          <Icon icon="people" :size="18" />
        </div>

        <div :class="$style.labels">
          <div :class="$style.main">{{ invitation?.email }}</div>
        </div>
      </div>
    </div>
    <div :class="$style.actions">
      <Button
        type="submit"
        size="3"
        variant="solid"
        theme="danger"
        @click.prevent="handleRemoveInvitation"
      >
        Remove invitation
      </Button>
    </div>
  </Popup>
</template>
<style module lang="scss">
.dropdown {
  color: var(--neutral-12);
  text-align: left;
}

.opened {
  background-color: var(--neutral-alpha-3);
}

.content {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 52px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  .description {
    @extend .regular-3;

    color: var(--neutral-11);
  }

  .member {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 24px 12px 16px;
    border-radius: 12px;
    background-color: var(--neutral-alpha-3);

    .placeholder {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: var(--neutral-4);
      color: var(--neutral-8);
    }

    .labels {
      display: flex;
      flex-direction: column;
      gap: 2px;
      flex: 1;

      .main {
        @extend .medium-3;
      }

      .sub {
        @extend .regular-2;
        color: var(--neutral-11);
      }
    }

    .icon {
      color: var(--neutral-alpha-9);
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
}
</style>
