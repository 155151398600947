<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { AssigneeBadge, Button, Popup } from '@/components/common'
import { ref } from 'vue'
import { Team } from '../../models/team'
import ManageMembers from '../ManageMembers.vue'
import { useTasksTeamStore } from '../../teamStore'

const props = defineProps<{
  team: Team
}>()
const { team } = toRefs(props)

const store = useTasksTeamStore()
const { members: teamMembers } = toRefs(store)

const memberPopup = ref<InstanceType<typeof Popup> | null>(null)

const handleMemberPopup = () => {
  memberPopup.value?.show()
}

const bundledMembers = computed(() => {
  const members = teamMembers?.value
  if (!members) return []

  if (members.length > 3) return members.slice(0, 3)
  return members
})

const membersCount = computed(() => {
  const members = teamMembers?.value

  let count = `0 Members`
  if (!members) return count

  if (members.length <= 3) count = 'Members'

  if (members.length > 3) {
    const others = members.length - 3
    count = `+${others} Members`
  }

  return count
})
</script>
<template>
  <div :class="$style.container">
    <AssigneeBadge
      :users="bundledMembers"
      :avatar-only="true"
      @click="handleMemberPopup"
    />
    <Button
      :class="$style.button"
      type="button"
      variant="ghost"
      theme="neutral"
      size="2"
      @click="handleMemberPopup"
      >{{ membersCount }}</Button
    >
  </div>

  <Popup ref="memberPopup" title="Members">
    <ManageMembers :team="team" />
  </Popup>
</template>
<style module lang="scss">
.container {
  padding: 0 12px;
  display: flex;
  align-items: center;

  .button {
    background: transparent;
    padding: 0px;
  }

  p {
    @extend .medium-3;
  }
}
</style>
