<script setup lang="ts">
import { ref } from 'vue'

import {
  Button,
  TextField,
  Select,
  type SelectItem,
  Icon,
} from '@/components/common'
import { showToast } from '@/utils'
import { useInboxStore } from '../store'
import { useWorkspaceApi } from '@/composables/api'
import { providers } from './constants'

const email = ref('')
const provider = ref<SelectItem>()
const testMailSent = ref<boolean>(false)
const copied = ref(false)

const { api, isStatus } = useWorkspaceApi()
const store = useInboxStore()

const handleCopy = () => {
  navigator.clipboard.writeText(store.forwardEmail).then(() => {
    copied.value = true
    showToast({ text: 'Email copied to clipboard', type: 'success' })
  })
}

const handleSend = () => {
  api.inbox.sendTestEmail(email.value).then(() => {
    testMailSent.value = true
    showToast({ text: 'Email sent' })
  })
}
</script>

<template>
  <div :class="$style.modal">
    <form @submit.prevent="handleSend">
      <div :class="$style['popup-content']">
        <ul :class="$style.steps">
          <li>
            <span :class="$style.order">1</span>
            <div :class="$style.content">
              Select your email provider
              <Select
                v-model="provider"
                size="2"
                placeholder="Select your domain provider"
                item-key="label"
                :options="providers"
              />
            </div>
          </li>
          <li v-if="provider">
            <span :class="$style.order">2</span>
            <div :class="$style.content">
              <p>Copy forwarding email address</p>
              <TextField
                readonly="true"
                variant="outline"
                size="2"
                :class="$style.forward"
                :value="store.forwardEmail"
              >
                <template #post>
                  <Button
                    icon="squareBehindSquare1"
                    size="3"
                    variant="ghost"
                    theme="neutral"
                    @click.prevent="handleCopy"
                  />
                </template>
              </TextField>
              <div v-if="provider.value !== 'other'" :class="$style.tutorial">
                <div :class="$style['icon-overlay']">
                  <Icon icon="playCircle" :size="24" />
                </div>
                <div :class="$style['video-link']">
                  <p>Need help setting up forwarding?</p>
                  <a :href="provider.tutorial as string" target="_blank">
                    <Button
                      type="button"
                      size="2"
                      theme="neutral"
                      variant="ghost"
                    >
                      Watch video
                    </Button>
                  </a>
                </div>
              </div>
              <div v-else :class="$style.notice">
                <Icon icon="playCircle" :size="20" />
                <p>
                  Log into your email account and locate the email forwarding
                  settings. Next, enter the address provided above as your
                  forwarding address and save the changes.
                </p>
              </div>
            </div>
          </li>
          <li v-if="copied">
            <span :class="$style.order">3</span>
            <div :class="$style.content">
              Enter your existing email account
              <TextField
                v-model="email"
                type="email"
                size="2"
                placeholder="hello@mywebsite.com"
              />
              <Button
                :disabled="!email"
                :class="$style.send"
                size="2"
                theme="neutral"
                variant="soft"
                :loading="isStatus('posting')"
              >
                Send test email
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </form>
    <div :class="$style['popup-footer']">
      <Button
        :disabled="!testMailSent"
        size="3"
        theme="neutral"
        variant="solid"
        :loading="isStatus('posting')"
        @click="$router.push({ name: 'HDInbox' })"
      >
        Continue to Inbox
      </Button>
    </div>
  </div>
</template>

<style module lang="scss">
.modal {
  width: 560px;
  max-height: 570px;
  overflow: auto;

  .popup-content {
    height: 440px;
    padding: 20px;
    flex-grow: 1;

    .steps {
      li:not(:last-child) {
        margin-bottom: 24px;
      }

      li {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
      }

      .order {
        @extend .medium-2;
        min-width: 24px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        background-color: var(--neutral-alpha-3);
      }

      .content {
        @extend .medium-3;
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 8px;

        .forward {
          padding-right: 0px;
        }

        .tutorial {
          margin-top: 8px;
          display: flex;
          align-items: cemter;
          column-gap: 16px;

          .icon-overlay {
            width: 92px;
            height: 52px;
            border-radius: 8px;
            background: var(--overlay-dark-9);
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              color: var(--basic-white);
            }
          }

          .video-link {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
              @extend .regular-3;
            }

            button {
              text-decoration: underline;
            }
          }
        }

        .notice {
          margin-top: 8px;
          display: flex;
          align-items: flex-start;
          column-gap: 6px;

          i {
            color: var(--neutral-8);
          }

          p {
            @extend .regular-3;
            color: var(--neutral-12);
          }
        }
      }

      .help {
        @extend .regular-3;
        color: var(--neutral-11);
      }

      .send {
        margin-top: 8px;
        align-self: flex-start;
      }
    }
  }
  .popup-footer {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 16px 20px;
    border-top: 1px solid var(--neutral-alpha-4);
  }
}
</style>
