<script setup lang="ts">
import { Button, EmptyView, Icon, Popup, TextField } from '@/components/common'
import Form from '@/modules/inbox/components/shortcuts/Form.vue'
import type Shortcut from '@/modules/inbox/models/shortcut'
import { useInboxStore } from '@/modules/inbox/store'
import { computed, ref, toRefs } from 'vue'
import ShortcutItem from './ShortcutItem.vue'
import { showToast } from '@/utils'

const formRef = ref<typeof Popup>()
const inboxStore = useInboxStore()
inboxStore.getShortcuts()

const { shortcuts } = toRefs(inboxStore)

const search = ref('')
const editShortcut = ref<Shortcut>()

const showPopup = () => {
  editShortcut.value = undefined
  formRef.value && formRef.value.show()
}

const handleEdit = (index: number) => {
  const shortcut = inboxStore.shortcuts[index]
  editShortcut.value = shortcut
  formRef.value && formRef.value.show()
}

const handleDelete = (index: number) => {
  const toDelete = inboxStore.shortcuts[index]
  inboxStore
    .deleteShortcut(toDelete)
    .then(() => showToast({ text: 'Shortcut deleted', type: 'success' }))
  formRef.value && formRef.value.hide()
}

const handleDeleteFromPopup = () => {
  const shortcutIndex = shortcuts.value.findIndex(
    (e) => e.id === editShortcut.value?.id,
  )

  handleDelete(shortcutIndex)

  formRef.value && formRef.value.hide()
}

const shortcutExists = computed(
  () => inboxStore.isStatus('loading') || !!shortcuts.value?.length,
)

const displayShortcuts = computed(() => {
  if (!search.value) {
    return shortcuts.value
  }

  return shortcuts.value.filter((s) => {
    const lowerCommand = s.command.toLowerCase()
    const lowerDescription = s.description.toLowerCase()

    return (
      lowerCommand.includes?.(search.value?.toLowerCase()) ||
      lowerDescription.includes?.(search.value?.toLowerCase())
    )
  })
})

const displayAmount = computed(() => displayShortcuts.value.length)
</script>
<template>
  <div
    v-if="!inboxStore.isStatus('loading') || shortcuts.length > 0"
    :class="[$style.section, $style.last]"
  >
    <div :class="$style.title">Shortcuts</div>

    <div v-if="shortcutExists" :class="$style.row">
      <TextField
        v-model="search"
        size="1"
        variant="outline"
        placeholder="Search shortcut"
        :class="$style.search"
      >
        <template #pre>
          <Icon icon="magnifyingGlass" :class="$style.icon" :size="20" />
        </template>

        <template v-if="search?.length" #post>
          <Button
            variant="ghost"
            theme="neutral"
            size="1"
            icon="crossSmall"
            @click.prevent="search = ''"
          />
        </template>
      </TextField>

      <Button size="2" variant="soft" theme="neutral" @click="showPopup">
        Create new shortcut
      </Button>
    </div>

    <template v-if="shortcutExists && displayAmount">
      <div :class="$style.amount">
        {{ displayAmount }} shortcut{{ displayAmount === 1 ? '' : 's' }}
      </div>

      <div :class="$style.shortcuts">
        <ShortcutItem
          v-for="(s, index) in displayShortcuts"
          :key="s.id"
          :shortcut="s"
          @edit="() => handleEdit(index)"
          @delete="() => handleDelete(index)"
        />
      </div>
    </template>

    <EmptyView
      v-else
      :class="$style.empty"
      icon="codeBrackets"
      :title="
        shortcutExists && !displayAmount
          ? 'No shortcut found.'
          : `You don't have any shortcuts yet.`
      "
      :description="
        shortcutExists && !displayAmount
          ? 'Check spelling or try to search for someone else. You can also create a new shortcut with this name.'
          : 'Create your first shortcut and speed up your messaging with your customers!'
      "
    >
      <Button
        v-if="!shortcutExists"
        size="2"
        variant="soft"
        theme="neutral"
        @click="showPopup"
      >
        Create new shortcut
      </Button>
    </EmptyView>
  </div>

  <Popup
    ref="formRef"
    :title="editShortcut ? 'Update shortcut' : 'New shortcut'"
  >
    <Form :shortcut="editShortcut">
      <template v-if="editShortcut?.id" #custom-action>
        <Button
          type="button"
          theme="danger"
          size="3"
          variant="soft"
          @click.prevent="handleDeleteFromPopup"
        >
          Delete shortcut
        </Button>
      </template>
    </Form>
  </Popup>
</template>

<style module lang="scss">
@import './base.scss';

.search {
  width: 256px;

  .icon {
    color: var(--neutral-alpha-9);
    min-width: 20px;
  }
}

.amount {
  @extend .medium-2;
}

.shortcuts {
  display: flex;
  flex-direction: column;
}

.empty {
  margin-top: 64px;
}
</style>
