<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { storeToRefs } from 'pinia'
import { reactive, ref } from 'vue'

import { Avatar, Button, FileField, Icon, TextField } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { showToast } from '@/utils'

import SettingsHeader from './SettingsHeader.vue'
import DangerZone from './danger-zone/DangerZone.vue'
import DeleteWorkspace from './workspace/DeleteWorkspace.vue'

const workspaceStore = useWorkspaceStore()
const { workspace } = storeToRefs(workspaceStore)

const file = ref<File>()
const filePreview = ref<string>()

const form = reactive({
  title: workspace.value?.title || '',
})

const rules = {
  title: { required },
}

const form$ = useVuelidate(rules, form, { $scope: false })

const handleLogo = async (selected: File | File[]) => {
  file.value = selected as File
  const reader = new FileReader()
  reader.readAsDataURL(selected as File)
  reader.onloadend = function () {
    filePreview.value = `${this.result}`
  }
}

const handleSave = async () => {
  await workspaceStore.updateWorkspace(form, file.value).then(() => {
    showToast({ text: 'Workspace updated!' })
  })
}
</script>

<template>
  <SettingsHeader title="Edit Workspace" subtitle="Manage your workspace" />

  <hr :class="$style.separator" />
  <div :class="$style.container">
    <div :class="$style.field">
      <div :class="$style.label">Profile picture</div>
      <div :class="[$style.value, $style.logoContainer]">
        <Avatar
          v-if="filePreview || workspace?.logo"
          :src="filePreview || workspace?.logo"
          :size="40"
        />

        <div v-else :class="$style.placeholder">
          <Icon icon="block" :size="20" />
        </div>

        <FileField
          :multiple="false"
          accept="image/*"
          @update:model-value="handleLogo"
        >
          <Button as="span" variant="soft" theme="neutral" size="2">
            Upload new
          </Button>
        </FileField>
      </div>
    </div>

    <div :class="$style.field">
      <div :class="$style.label">Workspace name</div>
      <TextField
        v-model="form$.title.$model"
        size="2"
        placeholder="Workspace name"
        :class="$style.value"
      />
    </div>

    <Button
      variant="soft"
      theme="neutral"
      size="2"
      :disabled="form$.$invalid"
      @click="handleSave"
    >
      Save changes
    </Button>
  </div>

  <DangerZone title="Delete workspace">
    <DeleteWorkspace />
  </DangerZone>
</template>

<style module lang="scss">
@import url('./styles.scss');

.logoContainer {
  display: flex;
  align-items: center;
  gap: 22px;

  .placeholder {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral-3);
    color: var(--neutral-8);
  }
}
</style>
