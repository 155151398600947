<script setup lang="ts"></script>
<template>
  <li :class="$style.title">
    <slot />
  </li>
</template>
<style module lang="scss">
.title {
  @extend .medium-2;
  padding: 6px 8px;
  color: var(--neutral-alpha-10);
}
</style>
