<script setup lang="ts">
import { noop } from 'lodash'
import { useApplicationStore } from './store'
import { ref } from 'vue'
import Icon from '@/components/common/Icon.vue'
import { checkIsAuthRoute } from './utils'
import { useRouter } from 'vue-router'

const router = useRouter()
const hasInitialized = ref(false)

const store = useApplicationStore()

store
  .getUser('me')
  .then((user) => {
    const isAuthRoute = checkIsAuthRoute(
      router.currentRoute.value.name as string,
    )
    user && isAuthRoute && router.replace({ name: 'Splash' })
  })
  .catch(noop)
  .finally(() => {
    hasInitialized.value = true
  })
</script>

<template>
  <router-view v-if="hasInitialized" />
  <div v-else :class="$style.layout">
    <div :class="$style.container">
      <Icon icon="vanillaIcon" :size="100" class="animate-bounce" />
      <b>Loading...</b>
    </div>
  </div>
</template>

<style module lang="scss">
.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.container {
  display: flex;
  width: 128px;
  height: 128px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
