<script setup lang="ts">
import UpgradePopup from '@/components/payment/Popup.vue'
import { onMounted, ref } from 'vue'
import { Popup } from '../common'

const upgradePopup = ref<typeof Popup>()

const triggerUpgrade = () => {
  upgradePopup.value?.show()
}

onMounted(() => {
  const flow = new URLSearchParams(window.location.search).get('flow')
  if (flow && flow === 'upgrade') {
    triggerUpgrade()
  }
})

defineExpose({
  triggerUpgrade,
})
</script>
<template>
  <Popup ref="upgradePopup">
    <UpgradePopup
      title="Unlock all of Vanilla's apps!"
      description="Start your 2-day free trial now and enjoy access to more than one app.
          Enter your credit card details to begin your free trial."
    />
  </Popup>
</template>
<style module lang="scss"></style>
