<script setup lang="ts">
import Echo from 'laravel-echo'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { computed, inject, onMounted, ref, watch } from 'vue'

import { useApplicationStore } from '@/store'
import App from '@/models/app.ts'
import { useWorkspaceStore } from '@/store/workspace.ts'

import EmptyView from '@common/EmptyView.vue'
import { Page } from '@/components/common/layout'
import { Button, Toggle, Icon, Skeleton } from '@/components/common'

import NotificationItem from '@/components/notifications/NotificationItem.vue'
import Notification from '@/models/notification.ts'
import { Pagination } from '@/utils/api/types.ts'

const route = useRoute()
const { api, isStatus } = useWorkspaceStore()
const appStore = useApplicationStore()
const socket = inject<Echo>('socket')

const store = useWorkspaceStore()
const { unreadNotificationSummary, apps }  = storeToRefs(store)

const notifications = ref<Pagination<Notification>>(  {
  data: [],
    links: {
    next: '',
  },
  meta: {
    current_page: 0,
      per_page: 0,
      total: 0,
  },
},)

const showRead = ref(true)

const selectedApp = computed<App | null>(() => {
  return route.query.app
    ? apps.value.find((app) => app.key === route.query.app) || null
    : null
})

const newNotificationsCount = computed<number>(() => {
  return route.query.app ?
    unreadNotificationSummary.value.breakdown.find(i => i.app == route.query.app)?.count ?? 0 :
    unreadNotificationSummary.value.total
})

const fetchNotifications = () => {
  const params: { [key: string]: string | boolean } = {}

  if (route.query.app) {
    params['filter[app]'] = route.query.app as string
  }

  if (!showRead.value) {
    params['filter[unread]'] = true
  }

  api.workspace.getNotifications(params)
    .then(response => notifications.value = response)
}

watch(
  () => route.query.app,
  () => {
    fetchNotifications()
  },
)

fetchNotifications()

onMounted(() => {
  socket &&
  socket
    .private(`notifications.${store.workspace?.id}.${appStore.user?.id}`)
    .listen('.NewNotification', (data: Notification) => notifications.value.data.unshift(data))
})

const removeNotification = (id: string) => {
  api.workspace.deleteNotification(id)
  notifications.value.data = notifications.value.data.filter(i => i.id !== id)
}

const readAllNotification = () => {
  api.workspace.readAllNotifications()
  notifications.value.data.map(i => i.readAt = new Date())
}
</script>

<template>
  <Page :full-screen="true">
    <template #topBar>
      Notifications
      <div :class="$style.actions">
        <div :class="$style.action">
          <span>Show Read</span>
          <Toggle v-model="showRead" size="2" @update:model-value="fetchNotifications"/>
        </div>
        <Button icon="checkList" size="2" variant="outline" theme="neutral" @click="readAllNotification">
          Mark all as read
        </Button>
      </div>
    </template>

    <div :class="$style.container">
      <div :class="$style.navigation">
        <div :class="$style.group">
          <ul :class="$style.group">
            <li>
              <router-link
                :class="[$style.route, !selectedApp ? $style.activeRoute: null]"
                :to="{ name: 'Notifications' }"
              >
                All Notifications

                <span
                  v-if="unreadNotificationSummary.total"
                  :class="$style.remarks"
                >
                {{ unreadNotificationSummary.total }}
                </span>
              </router-link>
            </li>
          </ul>

          <div>
            <div :class="$style.groupName">Apps</div>

            <ul :class="$style.group">
              <li v-for="app in apps" :key="app.key">
                <router-link
                  :class="[$style.route, selectedApp?.key === app.key ? $style.activeRoute: null]"
                  :to="{ name: 'Notifications', query: { app: app.key } }"
                >
                  <div :class="$style.name">
                    <Icon :icon="app.icon" :size="18" />
                    {{ app.name }}
                  </div>

                  <span
                    v-if="unreadNotificationSummary.breakdown.find(i => i.app === app.key)?.count"
                    :class="$style.remarks"
                  >
                  {{ unreadNotificationSummary.breakdown.find(i => i.app === app.key)?.count }}
                  </span>
                </router-link>
              </li>
            </ul>
          </div>

          <div :class="$style.group">
            <router-link
              :class="[$style.route, $style.settingsRoute]"
              :to="{ name: 'NotificationsSettings' }"
            >
              <div :class="$style.name">
                <Icon icon="settingsGear2" :size="18" />
                Settings
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div :class="$style.content">
        <div :class="$style.inner">
          <div v-if="isStatus('loading')">
            <Skeleton v-for="n in 5" :key="n" :height="50" :marginTop="16"/>
          </div>

          <div v-else>
            <EmptyView
              v-if="notifications.data.length === 0"
              :icon="selectedApp ? selectedApp.icon : 'bell2'"
              :title="`You don’t have any notifications${selectedApp ? ` in ${selectedApp.name}` : ''}.`"
              :description="`Once you’ll get a new notification${selectedApp ? ` in ${selectedApp.name},` : ''} it will appear here.`"
            />

            <div v-else>
              <div :class="$style.header">
                <h2 :class="$style.title">
                  {{ selectedApp ? selectedApp?.name : 'All Notifications' }}
                </h2>
                <p :class="$style.count">
                  {{ newNotificationsCount }} new notifications
                </p>
              </div>

              <NotificationItem
                v-for="notification in notifications.data"
                :key="notification.id"
                :notification="notification"
                @remove="removeNotification"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<style module lang="scss">
.actions {
  display: flex;
  gap: 20px;
  .action {
    display: flex;
    gap: 12px;
    margin: auto 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 32px;
  height: 100%;
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 196px;
  padding-top: 32px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .groupName {
      @extend .medium-2;
      color: var(--neutral-alpha-10);
      padding: 4px 0;
      margin-top: 20px;
    }

    .settingsRoute {
      margin-top: 20px;
    }

    .route {
      @extend .medium-3;
      padding: 10px 8px;
      transition: all 0.1s;
      display: flex;
      align-items: center;
      column-gap: 6px;
      border-radius: 6px;
      line-height: unset;
      justify-content: space-between;
      color: var(--neutral-12);

      .name {
        display: flex;
        align-items: center;
        column-gap: 6px;
      }

      .remarks {
        @extend .medium-2;
        background: var(--danger-9);
        color: var(--basic-white);
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }

      i {
        color: var(--neutral-11);
      }

      &:hover {
        background-color: var(--neutral-alpha-3);
      }
    }

    .activeRoute {
      background-color: var(--neutral-alpha-3);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  align-items: center;
  padding-top: 32px;

  .inner {
    width: 556px;

    .header {
      margin-bottom: 2rem;
      .title {
        @extend .semibold-6;
        color: var(--neutral-12);
      }
      .count {
        @extend .regular-3;
        color: var(--neutral-11);
      }
    }
  }
}
</style>
