//generated using pnpm build:icons - do not override manually

import arCube1Filled from './ar-cube-1-filled.svg'
import arCube1 from './ar-cube-1.svg'
import arrowInbox from './arrow-inbox.svg'
import arrowOutOfBox from './arrow-out-of-box.svg'
import arrowRight from './arrow-right.svg'
import arrowUpCircle from './arrow-up-circle.svg'
import at from './at.svg'
import bell2 from './bell-2.svg'
import bellAlarm from './bell-alarm.svg'
import bellAlarmOutline from './bell-alarm-outline.svg'
import block from './block.svg'
import book from './book.svg'
import box2AltFill from './box-2-alt-fill.svg'
import box2AltFillOutline from './box-2-alt-fill-outline.svg'
import brokenChainLink from './broken-chain-link.svg'
import bubble3 from './bubble-3.svg'
import bubbleCheck from './bubble-check.svg'
import bubbles from './bubbles.svg'
import bulletList from './bullet-list.svg'
import calendar2 from './calendar-2.svg'
import calendarAdd4 from './calendar-add-4.svg'
import calenderAdd from './calender-add.svg'
import chainLink from './chain-link.svg'
import chart5 from './chart-5.svg'
import check from './check.svg'
import checkList from './checklist.svg'
import chevronDownSmall from './chevron-down-small.svg'
import chevronGrabberVertical from './chevron-grabber-vertical.svg'
import chevronLeftSmall from './chevron-left-small.svg'
import chevronRightSmall from './chevron-right-small.svg'
import circleCheck from './circle-check.svg'
import circleCheckOutline from './circle-check-outline.svg'
import circleExclamation from './circle-exclamation.svg'
import circleHalfFill from './circle-half-fill.svg'
import circleInfo from './circle-info.svg'
import circlePlus from './circle-plus.svg'
import circleQuestionmark from './circle-questionmark.svg'
import circleWarning from './circle-warning.svg'
import codeBrackets from './code-brackets.svg'
import creditCard2 from './credit-card-2.svg'
import crm from './crm.svg'
import crmOutline from './crm-outline.svg'
import crossSmall from './cross-small.svg'
import crown from './crown.svg'
import dotGrid1x3Horizontal from './dot-grid-1x3-horizontal.svg'
import eSignature from './e-signature.svg'
import editSmall2 from './edit-small-2.svg'
//@ts-ignore
import email1 from './email-1.svg?skipsvgo'
import emailEnvelope from './email-envelope.svg'
import emojiSmile from './emoji-smile.svg'
import eyeClosed from './eye-closed.svg'
import eyeOpen from './eye-open.svg'
import fileBend from './file-bend.svg'
import filter1 from './filter-1.svg'
//@ts-ignore
import google from './google.svg?skipsvgo'
import group2 from './group-2.svg'
import group2Outline from './group2-outline.svg'
import hashtag from './hashtag.svg'
import hire from './hire.svg'
import inbox from './inbox.svg'
import knowledgebase from './knowledgebase.svg'
import layoutAlignLeft from './layout-align-left.svg'
import layoutAlignRight from './layout-align-right.svg'
import layoutColumn from './layout-column.svg'
import layoutGrid2 from './layout-grid-2.svg'
import lock from './lock.svg'
import magnifyingGlass from './magnifying-glass.svg'
import note1 from './note-1.svg'
import numberedList from './numbered-list.svg'
import pageAdd from './page-add.svg'
import paperclip2 from './paperclip-2.svg'
import pencil from './pencil.svg'
import peopleAdd from './people-add.svg'
import peopleCircle from './people-circle.svg'
import peopleRemove from './people-remove.svg'
import people from './people.svg'
import playCircle from './play-circle.svg'
import plusSmall from './plus-small.svg'
import priority0 from './priority-0.svg'
//@ts-ignore
import priority1 from './priority-1.svg?skipsvgo'
//@ts-ignore
import priority2 from './priority-2.svg?skipsvgo'
import priority3 from './priority-3.svg'
import projectManagement from './project-management.svg'
import projectManagementOutline from './project-management-outline.svg'
import sendMessage from './send-message.svg'
import settingsGear1 from './settings-gear-1.svg'
import settingsGear2 from './settings-gear-2.svg'
import slash from './slash.svg'
import slideTallAdd from './slide-tall-add.svg'
import socialMedia from './social_media.svg'
import sparklesTwo2 from './sparkles-two-2.svg'
import squareBehindSquare1 from './square-behind-square-1.svg'
import stateStart from './state-start.svg'
import stopwatch from './stopwatch.svg'
import subscription from './subscription.svg'
//@ts-ignore
import successSparklesInner from './success-sparkles-inner.svg?skipsvgo'
//@ts-ignore
import successSparklesOuter from './success-sparkles-outer.svg?skipsvgo'
import survey from './survey.svg'
import thumbsUp from './thumbs-up.svg'
import ticketStatusActive from './ticket_status_active.svg'
import ticketStatusComplete from './ticket_status_complete.svg'
import ticketStatusLive from './ticket_status_live.svg'
import ticketStatusNew from './ticket_status_new.svg'
import ticketStatusSpam from './ticket_status_spam.svg'
import trashCan2Outline from './trash-can-2-outline.svg'
import trashCan2 from './trash-can-2.svg'
import unassignedIcon from './unassigned-icon.svg'
import vanillaIcon from './vanilla_icon.svg'
import vanillaLogo from './vanilla_logo.svg'
import wiki from './wiki.svg'

export default {
  arCube1Filled,
  arCube1,
  arrowInbox,
  arrowOutOfBox,
  arrowRight,
  arrowUpCircle,
  at,
  bell2,
  bellAlarm,
  bellAlarmOutline,
  block,
  book,
  box2AltFill,
  box2AltFillOutline,
  brokenChainLink,
  bubble3,
  bubbleCheck,
  bubbles,
  bulletList,
  calendar2,
  calendarAdd4,
  calenderAdd,
  chainLink,
  chart5,
  check,
  checkList,
  chevronDownSmall,
  chevronGrabberVertical,
  chevronLeftSmall,
  chevronRightSmall,
  circleCheck,
  circleCheckOutline,
  circleExclamation,
  circleHalfFill,
  circleInfo,
  circlePlus,
  circleQuestionmark,
  circleWarning,
  codeBrackets,
  creditCard2,
  crm,
  crmOutline,
  crossSmall,
  crown,
  dotGrid1x3Horizontal,
  eSignature,
  editSmall2,
  email1,
  emailEnvelope,
  emojiSmile,
  eyeClosed,
  eyeOpen,
  fileBend,
  filter1,
  google,
  group2,
  group2Outline,
  hashtag,
  hire,
  inbox,
  knowledgebase,
  layoutAlignLeft,
  layoutAlignRight,
  layoutColumn,
  layoutGrid2,
  lock,
  magnifyingGlass,
  note1,
  numberedList,
  pageAdd,
  paperclip2,
  pencil,
  peopleAdd,
  peopleCircle,
  peopleRemove,
  people,
  playCircle,
  plusSmall,
  priority0,
  priority1,
  priority2,
  priority3,
  projectManagement,
  projectManagementOutline,
  sendMessage,
  settingsGear1,
  settingsGear2,
  slash,
  slideTallAdd,
  socialMedia,
  sparklesTwo2,
  squareBehindSquare1,
  stateStart,
  stopwatch,
  subscription,
  successSparklesInner,
  successSparklesOuter,
  survey,
  thumbsUp,
  ticketStatusActive,
  ticketStatusComplete,
  ticketStatusLive,
  ticketStatusNew,
  ticketStatusSpam,
  trashCan2Outline,
  trashCan2,
  unassignedIcon,
  vanillaIcon,
  vanillaLogo,
  wiki,
}
