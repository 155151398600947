<script setup lang="ts">
import VueDatePicker, { VueDatePickerProps } from '@vuepic/vue-datepicker'
import { Button, PopoverKey } from '.'
import { inject, ref } from 'vue'

defineProps<VueDatePickerProps>()
defineEmits<{
  (e: 'update:modelValue', data: Date | Date[]): void
}>()

const closePopover = inject(PopoverKey.hide)

const today = ref(new Date().getDate())

const datePicker = ref<typeof VueDatePicker>()

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const handleCancel = () => {
  closePopover?.()
}

const handleSelect = () => {
  datePicker.value?.selectDate()
  closePopover?.()
}
</script>
<template>
  <div :class="[$style.container, 'datePicker']">
    <VueDatePicker
      v-bind="$props"
      ref="datePicker"
      :model-value="$props.modelValue"
      @update:model-value="
        (data: Date | Date[]) => $emit('update:modelValue', data)
      "
    >
      <template #month-year="{ month, year, handleMonthYearChange }">
        <div :class="$style.navigation">
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            icon="chevronLeftSmall"
            @click="handleMonthYearChange?.(false)"
          />
          <p>{{ months[month || 0] }}, {{ year }}</p>
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            icon="chevronRightSmall"
            @click="handleMonthYearChange?.(true)"
          />
        </div>
      </template>
      <template #calendar-header="{ day }">
        <div :class="$style.header">
          {{ day }}
        </div>
      </template>
      <template #day="{ day }">
        <div :class="[$style.day, day === today && $style.today]">
          {{ day }}
        </div>
      </template>
      <template #action-buttons>
        <div :class="$style.actions">
          <Button size="3" variant="ghost" theme="neutral" @click="handleCancel"
            >Cancel</Button
          >
          <Button size="3" variant="solid" theme="neutral" @click="handleSelect"
            >Apply</Button
          >
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>
<style module lang="scss">
.container {
  :global(.dp__calendar_header_separator) {
    display: none;
  }
  :global(.dp__today) {
    border: none;
  }

  :global(.dp__calendar_row) {
    column-gap: 4px;
  }
  :global(.dp__action_row) {
    border-top: 1px solid var(--neutral-alpha-4);
    padding: 16px 20px;
  }
}
.navigation {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background: var(--neutral-alpha-3);
  height: 40px;
  padding: 0px 4px;
  margin-bottom: 8px;

  p {
    @extend .semibold-3;
    flex-grow: 1;
    text-align: center;
  }
}
.header {
  @extend .medium-3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-11);
  height: 100%;
  width: 100%;
}
.day {
  @extend .medium-4;
  position: relative;

  &.today::after {
    content: ' ';
    position: absolute;
    bottom: -3px;
    left: calc(50% - 2px);
    width: 4px;
    height: 4px;
    border-radius: 999px;
    background-color: var(--neutral-12);
  }
}
.actions {
  display: flex;
  column-gap: 8px;
}
</style>
