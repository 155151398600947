<script setup lang="ts">
import { Menu, MenuItem } from '@/components/common'
import { useInboxStore } from '../../store'
import { computed, ref } from 'vue'
import type Shortcut from '../../models/shortcut'

const emit = defineEmits<{
  (e: 'select', item: Shortcut): void
  (e: 'manage'): void
}>()

const store = useInboxStore()
const search = ref<string>('')
const highlighted = ref<number>()

const displayItems = computed(() => {
  if (search.value.length === 0) return store.shortcuts
  return store.shortcuts.filter(
    (s) =>
      (s.command &&
        s.command.toLowerCase().includes(search.value.toLowerCase())) ||
      (s.description &&
        s.description.toLowerCase().includes(search.value.toLowerCase())),
  )
})

const performSearch = (query: string) => {
  search.value = query || ''
  highlighted.value === undefined
}

const handleDownKey = () => {
  if (highlighted.value === undefined) return (highlighted.value = 0)
  highlighted.value = Math.min(
    displayItems.value.length - 1,
    highlighted.value + 1,
  )
}

const handleUpKey = () => {
  if (highlighted.value === undefined) return (highlighted.value = 0)
  highlighted.value = Math.max(0, highlighted.value - 1)
}

const handleEnter = () => {
  if (highlighted.value === undefined) return
  const item = displayItems.value[highlighted.value]
  emit('select', item)
}

const handleTab = () => {
  if (displayItems.value.length === 0) return
  const item = displayItems.value[highlighted.value || 0]
  emit('select', item)
}

defineExpose({
  performSearch,
  handleDownKey,
  handleUpKey,
  handleEnter,

  // below method are specific for shortcuts menu in message composer of inbox module
  handleTab,
})
</script>
<template>
  <Menu :width="218">
    <MenuItem
      v-for="(s, index) in displayItems"
      :key="s.id"
      :title="`/${s.command}`"
      :subtitle="s.description"
      :highlighted="index === highlighted"
      @click="$emit('select', s)"
    />
    <hr />
    <MenuItem title="Manage shortcuts" @click="$emit('manage')" />
  </Menu>
</template>
<style module lang="scss"></style>
