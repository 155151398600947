import { TaskSearch } from '@/modules/tasks/types'

export const generateFilters = (search?: TaskSearch, selfAssigned = false) => {
  const filters = {
    include: 'tags,assignees,team,status,team.statuses',
    ...(search?.team && { 'filter[team_id]': search.team.id }),
    ...(search?.assignee && {
      'filter[assignees]': search.assignee.map((a) => a.id),
    }),
    ...(selfAssigned && { 'filter[assigned_to_me]': true }),
    ...(search?.priority && {
      'filter[priority]': search.priority.map((p) => p.id),
    }),
    ...(search?.status && {
      'filter[status_id]': search.status.map((s) => s.id),
    }),
    ...(search?.dueDate && {
      'filter[due_date]': search.dueDate.date,
    }),
    ...(search?.tags && {
      'filter[tags]': search.tags.map((t) => t.id),
    }),
  }

  return filters
}

export const normalizeText = (text: string) => {
  let normalized = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n')
  normalized = normalized.trim()

  return normalized
}
