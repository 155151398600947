<script lang="ts" setup>
import { ref, onMounted, useAttrs } from 'vue'

const attrs = useAttrs()
const emit = defineEmits<{
  (e: 'select', data: Event): void
}>()

onMounted(() => {
  const picker = new window.EmojiMart.Picker({
    onEmojiSelect: handleEmojiSelect,
    ...attrs, // https://github.com/missive/emoji-mart?tab=readme-ov-file#options--props
  })
  container.value && container.value.append(picker)
})

const container = ref()

const handleEmojiSelect = (e: Event) => {
  emit('select', e)
}
</script>

<template>
  <div ref="container"></div>
</template>
