<script setup lang="ts">
import { Icon } from '@/components/common'
import { Page } from '@/components/common/layout'
import AppDetail from '@/components/marketplace/AppDetail.vue'
import { useApplicationStore } from '@/store'
import { useWorkspaceStore } from '@/store/workspace'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const appId = Number(route.params.appId)

const workspaceStore = useWorkspaceStore()
workspaceStore.getApps()

const store = useApplicationStore()
store.getApp(appId)

const appTitle = computed(() => {
  return store.app?.name
})
</script>
<template>
  <Page>
    <template #topBar>
      <div :class="$style.breadcrumb">
        <router-link
          :to="{ name: 'Marketplace' }"
          :class="$style['parent-link']"
        >
          Marketplace
        </router-link>
        <Icon icon="chevronRightSmall" :size="16" />
        {{ appTitle }}
      </div>
    </template>
    <div v-if="store.app" :class="$style.container">
      <AppDetail :app="store.app" />
    </div>
  </Page>
</template>
<style module lang="scss">
.popupContainer {
  .description {
    color: var(--neutral-11);
    @extend .regular-3;
    width: 340px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 4px;

  i {
    color: var(--neutral-7);
  }

  .parent-link {
    @extend .medium-3;
    color: var(--neutral-alpha-11);
  }
}
.container {
  margin: 0 auto;
  padding: 32px;
}
</style>
