import Icon from '@common/Icon.vue'

type IconInstance = InstanceType<typeof Icon>

// this should match key values in db's apps table
export type AppKey = 'inbox' | 'crm' | 'settings' | 'projectManagement'

interface Url {
  name: string
}

export enum AppStatus {
  Planning = 0,
  Available = 1,
  Consideration = 2,
  Development = 3,
}

export default interface App {
  id: number
  key: AppKey
  name: string
  description: string
  subTitle: string
  cover: string
  icon: IconInstance['$props']['icon']
  url: Url
  claimed: boolean
  status: AppStatus
}

export const SettingApp: App = {
  id: 0,
  key: 'settings',
  name: 'Settings',
  icon: 'settingsGear2',
  description: '',
  subTitle: '',
  cover: '',
  status: AppStatus.Available,
  claimed: false,
  url: { name: 'Settings' },
}