<script setup lang="ts">
import {
  AssigneeMenu,
  Icon,
  Menu,
  MenuItem,
  ResourceSelector,
} from '@/components/common'
import { ref, watch, inject, type Ref, toRefs } from 'vue'
import { useCrmPipelineStore } from '../../pipelineStore'
import StageIndicator from '../StageIndicator.vue'
import { useCrmStore, type ContactSearch } from '../../store'
import type User from '@/models/user'
import type { Company } from '@/models/company'
import type { PipelineStage } from '../../models/pipelineStage'
import type { Tag } from '@/models/tag'
import type { Pipeline } from '../../models/pipeline'

const { search } = defineProps<{
  search: ContactSearch
  mode: 'pipeline' | 'global'
}>()
const emit = defineEmits<{
  (e: 'update', data: ContactSearch): void
}>()

const crmStore = useCrmStore()
const { pipelines, companies, tags } = toRefs(crmStore)
companies.value.length === 0 && crmStore.loadCompanies()
tags.value.length === 0 && crmStore.loadTags()

const store = useCrmPipelineStore()
const { stages } = toRefs(store)

const menu = ref<
  'assignee' | 'company' | 'pipeline' | 'status' | 'tags' | undefined
>(undefined)
const isOpen = inject<Ref<boolean>>('isOpen')

watch(
  () => isOpen?.value,
  () => {
    menu.value = undefined
  },
)

const handleAssignee = (data: User[]) => {
  emit('update', {
    ...search,
    assignee: data.length ? data : undefined,
  })
}

const handleCompany = (data: Company[]) => {
  emit('update', {
    ...search,
    company: data.length ? data : undefined,
  })
}

const handlePipeline = (data: Pipeline[]) => {
  emit('update', {
    ...search,
    pipeline: data.length ? data : undefined,
  })
}

const handleStatus = (data: PipelineStage[]) => {
  emit('update', {
    ...search,
    status: data.length ? data : undefined,
  })
}

const handleTag = (data: Tag[]) => {
  emit('update', {
    ...search,
    tags: data.length ? data : undefined,
  })
}
</script>

<template>
  <AssigneeMenu
    v-if="menu === 'assignee'"
    :assignees="search.assignee"
    @update="handleAssignee"
  />
  <ResourceSelector
    v-else-if="menu === 'company'"
    :items="companies"
    :selected="search.company"
    item-key="id"
    title-key="name"
    selection="multi"
    @update="handleCompany"
  />
  <ResourceSelector
    v-else-if="menu === 'pipeline'"
    :items="pipelines"
    :selected="search.pipeline"
    item-key="id"
    title-key="name"
    selection="multi"
    @update="handlePipeline"
  />
  <ResourceSelector
    v-else-if="menu === 'status'"
    :items="stages"
    :selected="search.status"
    item-key="id"
    title-key="title"
    selection="multi"
    @update="handleStatus"
  >
    <template #icon="{ item }">
      <StageIndicator
        :size="16"
        :stage="item?.order || 0"
        :total-stages="stages.length"
      />
    </template>
  </ResourceSelector>
  <ResourceSelector
    v-else-if="menu === 'tags'"
    :items="tags"
    :selected="search.tags"
    item-key="id"
    title-key="name"
    selection="multi"
    @update="handleTag"
  />
  <Menu v-else :width="164">
    <MenuItem title="Assignee" @click="menu = 'assignee'">
      <template #icon>
        <Icon icon="peopleCircle" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Company" @click="menu = 'company'">
      <template #icon>
        <Icon icon="block" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem
      v-if="mode === 'global'"
      title="Pipeline"
      @click="menu = 'pipeline'"
    >
      <template #icon>
        <Icon icon="layoutColumn" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem v-else title="Status" @click="menu = 'status'">
      <template #icon>
        <Icon icon="circleHalfFill" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Tags" @click="menu = 'tags'">
      <template #icon>
        <Icon icon="hashtag" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
  </Menu>
</template>

<style module lang="scss">
.icon {
  color: var(--neutral-11);
}
</style>
