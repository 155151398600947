import type { AxiosInstance } from 'axios'
import type { Pagination } from './types'
import type { Company } from '@/models/company'

export class CompanyService {
  constructor(private instance: AxiosInstance) {}

  createCompany = (name: string) =>
    this.instance
      .post<Company>(`/companies`, { name })
      .then((response) => response.data)

  loadCompanies = (request: { search: string }) =>
    this.instance
      .get<Pagination<Company>>(`/companies`, { params: request })
      .then((response) => response.data)
}
