<script setup lang="ts">
import type User from '@/models/user'
import {
  markdownParser,
  markdownSerializer,
} from '@/utils/prosemirror/extended-schema'
import { editorSetup } from '@/utils/prosemirror/index.js'

import { EditorState, Plugin, Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { onMounted, ref, toRaw, toRefs, watch } from 'vue'

const emit = defineEmits<{
  (e: 'update:modelValue', data: string): void
  (e: 'focus'): void
  (e: 'blur'): void
}>()

const props = defineProps<{
  modelValue: string
  placeholder?: string
  plugins?: Plugin[]
}>()
const { modelValue, placeholder, plugins } = toRefs(props)

const view = ref<EditorView | null>(null)
const fieldRef = ref<HTMLDivElement | null>(null)

const createState = (content: string, plugins: Plugin[]) => {
  return EditorState.create({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    doc: markdownParser.parse(content)!,

    plugins: editorSetup({
      schema: markdownParser.schema,
      flying: true,
      placeholder: placeholder?.value || 'Type your reply...',
      plugins,
    }),
  })
}

onMounted(() => {
  view.value = new EditorView(fieldRef.value, {
    state: createState(modelValue.value, plugins?.value || []),
    handleDOMEvents: {
      focus: () => {
        emit('focus')
      },
      blur: () => {
        emit('blur')
      },
    },
    dispatchTransaction(transaction: Transaction) {
      const view = this as unknown as EditorView
      const newState = this.state.apply(transaction)
      view.updateState(newState)
      const lastValue = markdownSerializer.serialize(newState.doc)
      emit('update:modelValue', lastValue)
    },
  })
})

watch(
  () => props.placeholder,
  () => {
    const v = toRaw(view.value)
    const state = v?.state as EditorState
    const tr = state.tr.setMeta('placeholder', props.placeholder)
    tr && v?.dispatch(tr)
  },
)

const insertText = (text: string, range?: { from: number; to: number }) => {
  const v = toRaw(view.value)
  const state = v?.state
  if (range) {
    const tr = state?.tr.insertText(text, range.from, range.to)
    tr && v?.dispatch(tr)
  } else {
    const tr = state?.tr.insertText(text)
    tr && v?.dispatch(tr)
  }
}

const insertTaggingUser = (user: User, range: { from: number; to: number }) => {
  const v = toRaw(view.value)
  const state = v?.state as EditorState

  const instance = state.schema.nodes.tag
  const node = instance.create({ id: user.id, name: user.name })

  const [from, to] = [
    range?.from || state?.selection?.from || 1,
    range?.to || state?.selection?.to || 1,
  ]

  const tr = state.tr.replaceRangeWith(from, to, node)
  v?.dispatch(tr)
}

const clear = () => {
  const v = toRaw(view.value)
  const state = v?.state as EditorState
  const tr = state.tr.insertText('', 0, state.doc.content.size)
  tr && v?.dispatch(tr)
}

defineExpose({ clear, insertText, insertTaggingUser })
</script>
<template>
  <div ref="fieldRef"></div>
</template>
<style lang="scss">
.ProseMirror {
  @extend .regular-3;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: 'liga' 0;
  line-height: 1.2;
  outline: none;
  height: 100%;

  &.placeholder {
    color: var(--neutral-11);
  }

  img {
    width: 100%;
  }
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror li {
  position: relative;
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

/* Make sure li selections wrap around markers */

li.ProseMirror-selectednode {
  outline: none;
}

li.ProseMirror-selectednode:after {
  content: '';
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}

/* Protect against generic img rules */

img.ProseMirror-separator {
  display: inline !important;
  border: none !important;
  margin: 0 !important;
}
.ProseMirror-textblock-dropdown {
  min-width: 3em;
}

.ProseMirror-menu {
  margin: 0 -4px;
  line-height: 1;
}

.ProseMirror-tooltip .ProseMirror-menu {
  width: -webkit-fit-content;
  width: fit-content;
  white-space: pre;
}

.ProseMirror-menuitem {
  margin-right: 3px;
  display: inline-block;
}

.ProseMirror-menuseparator {
  border-right: 1px solid #ddd;
  margin-right: 3px;
  height: 32px;
}

.ProseMirror-menu-dropdown,
.ProseMirror-menu-dropdown-menu {
  font-size: 90%;
  white-space: nowrap;
}

.ProseMirror-menu-dropdown {
  vertical-align: 1px;
  cursor: pointer;
  position: relative;
  padding-right: 15px;
}

.ProseMirror-menu-dropdown-wrap {
  padding: 1px 0 1px 4px;
  display: inline-block;
  position: relative;
}

.ProseMirror-menu-dropdown:after {
  content: '';
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid currentColor;
  opacity: 0.6;
  position: absolute;
  right: 4px;
  top: calc(50% - 2px);
}

.ProseMirror-menu-dropdown-menu,
.ProseMirror-menu-submenu {
  position: absolute;
  background: white;
  color: #666;
  border: 1px solid #aaa;
  padding: 2px;
}

.ProseMirror-menu-dropdown-menu {
  z-index: 15;
  min-width: 6em;
}

.ProseMirror-menu-dropdown-item {
  cursor: pointer;
  padding: 2px 8px 2px 4px;
}

.ProseMirror-menu-dropdown-item:hover {
  background: #f2f2f2;
}

.ProseMirror-menu-submenu-wrap {
  position: relative;
  margin-right: -4px;
}

.ProseMirror-menu-submenu-label:after {
  content: '';
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid currentColor;
  opacity: 0.6;
  position: absolute;
  right: 4px;
  top: calc(50% - 4px);
}

.ProseMirror-menu-submenu {
  display: none;
  min-width: 4em;
  left: 100%;
  top: -3px;
}

.ProseMirror-menu-active {
  background: #eee;
  border-radius: 4px;
}

.ProseMirror-menu-disabled {
  opacity: 0.3;
}

.ProseMirror-menu-submenu-wrap:hover .ProseMirror-menu-submenu,
.ProseMirror-menu-submenu-wrap-active .ProseMirror-menu-submenu {
  display: block;
}

.ProseMirror-menubar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  min-height: 1em;
  color: #666;
  background: white;
  z-index: 10;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  align-items: center;

  &.static {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }

  &.flying {
    position: absolute;
    z-index: 100;
    box-shadow: -0.5px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 2px;
  }
}

.ProseMirror-icon {
  display: flex;
  width: 36px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ProseMirror-menu-disabled.ProseMirror-icon {
  cursor: default;
}

.ProseMirror-icon svg {
  fill: currentColor;
  height: 1em;
}

.ProseMirror-icon span {
  vertical-align: text-top;
}
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
/* Add space around the hr to make clicking it easier */

.ProseMirror ul,
.ProseMirror ol {
  padding-left: 30px;
}

.ProseMirror blockquote {
  padding-left: 1em;
  border-left: 3px solid #eee;
  margin-left: 0;
  margin-right: 0;
}

.ProseMirror-prompt {
  background: white;
  padding: 5px 10px 5px 15px;
  border: 1px solid silver;
  position: fixed;
  border-radius: 3px;
  z-index: 11;
  box-shadow: -0.5px 2px 5px rgba(0, 0, 0, 0.2);
}

.ProseMirror-prompt h5 {
  margin: 0;
  font-weight: normal;
  font-size: 100%;
  color: #444;
}

.ProseMirror-invalid {
  background: #ffc;
  border: 1px solid #cc7;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  min-width: 10em;
}

.ProseMirror-prompt-buttons {
  margin-top: 5px;
  display: none;
}

.ProseMirror-tagged-user {
  @extend .semibold-4;
}
</style>
