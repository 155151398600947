<script setup lang="ts">
import AttachmentFile from '@modules/tasks/components/Attachment/AttachmentFile.vue'
import AttachmentImage from '@modules/tasks/components/Attachment/AttachmentImage.vue'
import { UploadedFile } from '@/models/file.ts'

const emit = defineEmits<{
  (e: 'removeImage', id: number): void
  (e: 'removeFile', id: number): void
}>()

const { images, files, removable } = defineProps<{
  images: (UploadedFile | File)[]
  files: (UploadedFile | File)[]
  removable?: boolean
}>()
</script>

<template>
  <div v-if="files.length" :class="$style.files">
    <AttachmentFile
      v-for="(file, idx) in files"
      :key="`file-${idx}`"
      :file="file"
      :removable="removable"
      @remove="emit('removeFile', idx)"
    />
  </div>

  <div v-if="images.length" :class="$style.images">
    <AttachmentImage
      v-for="(image, idx) in images"
      :key="`image-${idx}`"
      :image="image"
      :removable="removable"
      @remove="emit('removeImage', idx)"
    />
  </div>
</template>

<style module lang="scss">
.images,
.files {
  display: flex;
  width: 80%;
  gap: 8px;
  margin-top: 8px;
  border-radius: 8px;
  padding: 8px;
  flex-wrap: wrap;
}
</style>
