<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  modelValue: boolean
  isRadio?: boolean
  isHalf?: boolean
  intermediate?: boolean
  size?: '1' | '2' | '3'
}>()
const { modelValue } = toRefs(props)

const emit = defineEmits<{
  (e: 'click', event: Event): void
  (e: 'update:modelValue', data: boolean, event: Event): void
}>()

const handleChange = (e: Event) => {
  const target = e.target as HTMLInputElement
  emit('update:modelValue', target.checked, e)
}
</script>
<template>
  <component
    :is="$slots.default ? 'label' : 'span'"
    :class="[$style.label]"
    @click="(e: Event) => emit('click', e)"
  >
    <input
      :type="isRadio ? 'radio' : 'checkbox'"
      :class="[$style.input, intermediate && $style.intermediate]"
      :checked="modelValue"
      @input="handleChange"
    />

    <span
      :class="[
        'transition',
        $style.control,
        $style[`size-${size || '1'}`],
        isRadio && $style.radio,
        $slots.default && $style.gap,
      ]"
    />

    <slot />
  </component>
</template>
<style module lang="scss">
.control {
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid var(--neutral-alpha-6);
  transition-property: background-color;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    border: solid var(--neutral-1);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.size {
  &-1 {
    height: 16px;
    width: 16px;

    &:after {
      margin-top: 1px;
      width: 3px;
      height: 8px;
    }

    &.radio::after {
      margin-top: 3.5px;
      width: 5px;
      height: 5px;
    }
  }

  &-2 {
    height: 20px;
    width: 20px;

    &:after {
      margin-top: 3px;
      width: 3px;
      height: 9px;
    }

    &.radio::after {
      margin-top: 4.5px;
      width: 7px;
      height: 7px;
    }
  }

  &-3 {
    height: 24px;
    width: 24px;

    &:after {
      margin-top: 3px;
      width: 4px;
      height: 11px;
    }

    &.radio::after {
      margin-top: 6px;
      width: 8px;
      height: 8px;
    }
  }
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.gap {
  margin-right: 6px;
}

.radio {
  border-radius: 999px;
}

.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .radio:after {
    background-color: var(--neutral-1);
    border-radius: 999px;
  }

  &:hover input ~ .control {
    border-color: var(--neutral-alpha-7);
    background-color: var(--neutral-alpha-2);
  }
  input:checked ~ .control,
  input.intermediate ~ .control {
    background-color: var(--neutral-12);
    border-color: var(--blue-500);
  }
  input:checked ~ .control:hover,
  input.intermediate ~ .control:hover {
    opacity: 0.8;
  }
  input:checked ~ .control:after,
  input.intermediate ~ .control:after {
    display: block;
  }

  input.intermediate ~ .control:after {
    background-color: var(--neutral-1);
    border: none;
    border-width: 0;
    transform: rotate(0);
    border-radius: 999px;
  }

  input.intermediate ~ .control {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input.intermediate ~ .control::after {
    height: 2px;
    margin: 0;
  }

  input.intermediate ~ .size-1::after {
    width: 8px;
  }

  input.intermediate ~ .size-2::after {
    width: 10px;
  }

  input.intermediate ~ .size-3::after {
    width: 12px;
  }
}
</style>
