import { marked } from 'marked'
import markedLinkifyIt from 'marked-linkify-it'

const preprocessMarkdown = (markdown: string) => {
  return markdown.replace(/\[user-id="(\w+)" name="(.+?)"\]/g, '**@$2**')
}

export const renderMarkdown = (markdown: string | null, breaks = true) => {
  if (!markdown) return ''
  const preprocessedMarkdown = preprocessMarkdown(markdown)

  marked.use(markedLinkifyIt({}, {}))
  return marked.parse(preprocessedMarkdown, { breaks })
}
