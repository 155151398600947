<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import $style from './styles.module.scss'
import {
  Icon,
  Avatar,
  Popover,
  UpgradePopupKey,
  ActiveAppKey,
} from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { useApplicationStore } from '@/store'
import { computed, onMounted, provide, ref } from 'vue'
import { clsx } from 'clsx'
import { Menu } from './constants'
import type App from '@/models/app'
import ProfileMenu from '@/components/Dashboard/ProfileMenu.vue'
import BillingBanner from '@/components/Dashboard/BillingBanner.vue'
import { useInboxStore } from '@modules/inbox/store'
import UpgradeFlow from '@/components/payment/UpgradeFlow.vue'
import { storeToRefs } from 'pinia'
import useWebsockets from '@/composables/websockets.ts'
import { UnreadNotificationSummary } from '@/models/notification.ts'
import { AppKey } from '@/models/app'
import { useTasksStore } from '@/modules/tasks/store'
import { useCrmStore } from '@/modules/crm/store'

const route = useRoute()
const router = useRouter()
const socket = useWebsockets()
const upgradeFlow = ref<typeof UpgradeFlow>()

const workspaceId = Number(route.params.workspaceId)

const appStore = useApplicationStore()
const workspaceStore = useWorkspaceStore()
workspaceStore.setCurrentWorkspace(workspaceId)
workspaceStore.getMembers()
workspaceStore.getUnreadNotificationSummary()
workspaceStore.getApps().then((data) => {
  workspaceStore.initializeApps()
  if (data.length === 0) {
    router.replace({ name: 'OnboardingMarketplace' })
  } else if (route.matched.length === 1) {
    // don't redirect to app if user is landing on a page
    router.push(data[0].url)
  }
})

const { unreadNotificationSummary } = storeToRefs(workspaceStore)

const subMenus = computed<Record<AppKey, Menu>>(() => ({
  inbox: useInboxStore().menuItems,
  crm: useCrmStore().menuItems,
  projectManagement: useTasksStore().menuItems,

  settings: { children: [] },
}))

const activeApp = computed<App | null>(() => {
  if (workspaceStore.apps.length === 0) return null
  if (route.matched.length > 1) {
    const name = route.matched[1].name
    const index = workspaceStore.apps.findIndex((app) => app.url.name === name)
    if (index >= 0) {
      return workspaceStore.apps[index]
    }
  }
  return null
})

onMounted(() => {
  socket &&
    socket
      .private(`notifications.${workspaceId}.${appStore.user?.id}`)
      .listen(
        '.UnreadNotificationCountChanged',
        (data: UnreadNotificationSummary) =>
          (unreadNotificationSummary.value = data),
      )
})

provide('workspaceId', workspaceId)
provide(UpgradePopupKey.show, () => upgradeFlow.value?.triggerUpgrade())
provide(ActiveAppKey, activeApp)
provide('socket', socket)
</script>
<template>
  <UpgradeFlow ref="upgradeFlow" />
  <Transition name="slide-left">
    <nav v-if="appStore.sidebars.left" :class="$style.navigation">
      <div :class="$style.topBar">
        <Icon icon="vanillaLogo" :width="80" :height="24" />
        <Popover placement="bottom-start">
          <template #content> <ProfileMenu /> </template>
          <Avatar
            :title="appStore.user?.name"
            :src="appStore.user?.avatar"
            :size="24"
          />
        </Popover>
      </div>
      <ul :class="$style.content">
        <!-- <li :class="$style.item">
          <Icon icon="magnifyingGlass" :size="18" />
          <span :class="$style.label">Search</span>
        </li> -->
        <li>
          <router-link :class="$style.item" :to="{ name: 'Notifications' }">
            <Icon icon="bell2" :size="18" />
            <span :class="$style.label">Notifications</span>

            <span
              v-if="unreadNotificationSummary.total"
              :class="$style.remarks"
            >
              {{ unreadNotificationSummary.total }}
            </span>
          </router-link>
        </li>
        <li :class="$style.gap" />
        <li v-for="app in workspaceStore.apps" :key="app.id">
          <router-link :to="app.url" :class="$style.item">
            <span :class="$style.icon">
              <Icon :icon="app.icon" :size="16" />
            </span>

            <span :class="$style.label">
              {{ app.name }}
              <Icon icon="chevronRightSmall" :size="18" />
            </span>

            <span
              v-if="(subMenus[app.key].badge || 0) > 0"
              :class="$style.remarks"
            >
              {{ subMenus[app.key].badge }}
            </span>
          </router-link>
          <Transition name="navigation-submenu">
            <ul
              v-if="
                (activeApp?.key === app.key &&
                  subMenus[app.key].children.length > 0) ||
                subMenus[app.key].children
                  .map((r) => r.url.name)
                  .includes(route.name as string)
              "
              :class="clsx($style.content, $style.subMenu)"
            >
              <li v-for="item in subMenus[app.key].children" :key="item.icon">
                <router-link :to="item.url" :class="$style.item">
                  <Icon :icon="item.icon" :size="18" />
                  <span :class="$style.label">{{ item.title }}</span>
                </router-link>

                <ul
                  v-if="item.children && item.children?.length"
                  :class="clsx($style.content, $style.subMenu)"
                >
                  <li v-for="subItem in item.children" :key="item.icon">
                    <router-link :to="subItem.url" :class="$style.item">
                      <Icon :icon="subItem.icon" :size="18" />
                      <span :class="$style.label">{{ subItem.title }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </Transition>
        </li>
        <li :class="$style.gap" />
        <li>
          <router-link :class="$style.item" :to="{ name: 'Marketplace' }">
            <Icon icon="circlePlus" :size="18" />
            <span :class="$style.label">App marketplace</span>
          </router-link>
        </li>
      </ul>
      <BillingBanner v-if="!workspaceStore.workspace?.isPremium" />
    </nav>
  </Transition>
  <router-view v-if="workspaceStore.apps.length > 0" />
</template>
