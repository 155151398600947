import { useWorkspaceApi } from '@/composables/api'
import type { Pagination } from '@/utils/api/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Pipeline } from './models/pipeline'
import type Contact from '@/models/contact'
import type Api from '@/utils/api'
import type { Company } from '@/models/company'
import type { Tag } from '@/models/tag'
import type User from '@/models/user'
import type { PipelineStage } from './models/pipelineStage'
import type { PipelineContact } from './models/pipelineContact'
import { Menu } from '@/pages/Dashboard/constants'

interface CRMStore extends ReturnType<typeof useWorkspaceApi> {
  pagination?: Pagination<Contact>
  contacts: PipelineContact[]
  companies: Company[]
  pipelines: Pipeline[]
  tags: Tag[]
  api: Api
  isAddContactOpen: boolean
}

export interface ContactSearch {
  assignee?: User[]
  company?: Company[]
  pipeline?: Pipeline[]
  status?: PipelineStage[]
  tags?: Tag[],
  search?: string
}

export const useCrmStore = defineStore('crm', {
  state: (): CRMStore => ({
    contacts: [],
    companies: [],
    pipelines: [],
    tags: [],
    isAddContactOpen: false,
    ...useWorkspaceApi(),
  }),
  getters: {
    menuItems(): Menu {
      return {
        children: [
          {
            icon: 'group2',
            title: 'All contacts',
            url: { name: 'CRMContacts' },
          },
          {
            icon: 'layoutColumn',
            title: 'Pipelines',
            url: { name: 'CRMPipelines' },
          },
          {
            icon: 'settingsGear2',
            title: 'Settings',
            url: { name: 'CRMSettings' },
          },
        ],
      }
    },
  },
  actions: {
    addContact(contact: PipelineContact) {
      this.isAddContactOpen = false
      this.contacts = [contact, ...this.contacts]
      Object.assign(this.pagination || {}, {
        totalItems: (this.pagination?.totalItems || 0) + 1,
      })
    },
    loadContacts(search: ContactSearch) {
      this.api.crm
        .loadContacts({
          ...(search.search
            ? { search:search.search }
            : {}),
          ...(search.company
            ? { companies: search.company.map((c) => c.id) }
            : {}),
          ...(search.tags ? { tags: search.tags.map((c) => c.id) } : {}),
          ...(search.assignee
            ? { assignees: search.assignee.map((c) => c.id) }
            : {}),
          ...(search.pipeline
            ? { pipelines: search.pipeline.map((c) => c.id) }
            : {}),
        })
        .then((data) => {
          this.$patch((state) => {
            state.pagination = data
            state.contacts = data.data
          })
        })
    },
    createPipeline(name: string) {
      return this.api.crm.createPipeline(name).then((data) => {
        this.pipelines.push(data)
      })
    },
    updatePipeline(id: number, name: string) {
      return this.api.crm.updatePipeline(id, name).then((data) => {
        const index = this.pipelines.findIndex((p) => p.id === id)
        index !== -1 && (this.pipelines[index] = data)
      })
    },
    duplicatePipeline(id: number) {
      return this.api.crm.duplicatePipeline(id).then((data) => {
        this.pipelines.push(data)
      })
    },
    deletePipeline(id: number) {
      const updated = [...this.pipelines].filter((c) => c.id !== id)
      this.pipelines = updated

      return this.api.crm.deletePipeline(id)
    },
    loadPipelines() {
      return this.api.crm.loadPipelines().then((data) => {
        this.pipelines = data
      })
    },
    loadCompanies() {
      this.companies.length === 0 &&
        this.api.company
          .loadCompanies({ search: '' })
          .then((data) => (this.companies = data.data))
    },
    loadTags() {
      return this.api.tag.loadTags('crm').then((data) => {
        this.tags = data
      })
    },
    createTag(name: string) {
      return this.api.tag.createTag(name, 'crm').then((data) => {
        this.tags.push(data)
      })
    },
    bulkAssignContact(ids: number[], user: User) {
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].assignee = user
        }
      })
      return this.api.crm.updateBulk({
        ids,
        assigneeId: user.id,
      })
    },
    assignContact(contact: PipelineContact, user: User) {
      const index = this.contacts.findIndex((c) => c.id === contact.id)
      Object.assign(this.contacts[index], {
        assignee: user,
      })

      this.api.crm.updateContact(contact.id, {
        assigneeId: user?.id || null,
      })
    },
    async bulkAssignCompany(ids: number[], company: Company) {
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].company = company
        }
      })
      return this.api.crm.updateBulk({
        ids,
        companyId: company.id,
      })
    },
    addContactTags(contact: PipelineContact, tags: Tag[]) {
      const index = this.contacts.findIndex((c) => c.id === contact.id)
      Object.assign(this.contacts[index], {
        tags: tags,
      })

      this.api.crm.updateContactTags(
        contact.id,
        tags.map((t) => t.id),
      )
    },
    async bulkAddContactTags(ids: number[], added: Tag[], removed: Tag[]) {
      const removedIds = removed.map((c) => c.id)
      const addedIds = added.map((c) => c.id)
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].tags = [
            ...(c.tags || []).filter(
              (d) => !removedIds.includes(d.id) && !addedIds.includes(d.id),
            ),
            ...added,
          ]
        }
      })
      return this.api.crm.updateBulk({
        ids,
        tagsAdded: addedIds,
        tagsRemoved: removedIds,
      })
    },
    removeContacts(ids: number[]) {
      const updated = [...this.contacts].filter((c) => !ids.includes(c.id))
      this.contacts = updated
      this.pagination?.totalItems && (this.pagination.totalItems -= ids.length)
      Object.assign(this.pagination || {}, {
        totalItems: (this.pagination?.totalItems || 0) - ids.length,
      })
      return this.api.crm.deleteBulk({
        ids,
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCrmStore, import.meta.hot))
}
