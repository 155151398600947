<script setup lang="ts">
import type Ticket from '../models/ticket'
import { ISODate } from '@/utils/helpers'
import { toRefs } from 'vue'
import { useInboxStore } from '../store'
import TicketStatus from './TicketStatus.vue'
import {
  Avatar,
  Checkbox,
  Popover,
  Badge,
  AssigneeBadge,
} from '@/components/common'
import AssigneeMenu from '@/components/common/AssigneeMenu.vue'

const props = defineProps<{
  ticket: Ticket
  checked?: boolean
}>()

const emit = defineEmits<{ 'on-check-change': [value: number] }>()

const { ticket } = toRefs(props)

const store = useInboxStore()

const handleCheck = () => {
  emit('on-check-change', ticket.value.id)
}
</script>
<template>
  <router-link
    v-slot="{ navigate }"
    custom
    :to="{
      name: 'HDThread',
      params: { id: ticket.workspaceTicketId },
    }"
  >
    <tr
      :class="[
        $style.row,
        (ticket.status === 1 || (ticket.unreadCount || 0) > 0) && $style.new,
      ]"
      @click="navigate"
    >
      <td @click.prevent="handleCheck">
        <div :class="$style.checkbox">
          <Checkbox :model-value="$props.checked || false" />
        </div>
      </td>
      <td>
        <div :class="$style.nameSection">
          <avatar
            :class="$style.avatar"
            :size="24"
            :title="ticket.contact.name || ticket.contact.email"
          />
          {{ ticket.contact.name || ticket.contact.email }}
          <span v-if="!!ticket.unreadCount" :class="$style.unread">{{
            ticket.unreadCount
          }}</span>
          <Badge v-if="ticket.channel === 'chat'" :class="$style.badge" size="1"
            >Live</Badge
          >
        </div>
      </td>
      <td>
        <div :class="$style.title">
          {{ ticket.title }}
        </div>
      </td>
      <td>
        <TicketStatus :oval="true" :ticket="ticket" />
      </td>
      <td>
        {{
          ISODate(ticket.updatedAt, {
            format: 'LLL dd',
            time: false,
          })
        }}
      </td>
      <td>
        <Popover @click.prevent>
          <template #content
            ><AssigneeMenu
              :assignees="ticket.operators"
              @update="(data) => store.updateAssignee(ticket, data)"
          /></template>
          <AssigneeBadge
            :users="ticket.operators"
            :avatar-only="true"
            @click.prevent
          />
        </Popover>
      </td>
    </tr>
  </router-link>
</template>
<style module lang="scss">
.row {
  @extend .regular-3;
  cursor: pointer;

  &.new {
    @extend .semibold-3;
  }

  td {
    padding: 16px 0px;
    vertical-align: middle;
    transition: background-color 0.1s;
    max-width: 0;
    &:first-child {
      padding-left: 20px;
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 16px;
    }
  }

  &:hover {
    td {
      background-color: var(--neutral-alpha-3);
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nameSection {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.avatar {
  margin-right: 8px;
}

.name {
  flex-grow: 1;
}

.unread {
  @extend .medium-2;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  background: var(--danger-9);
  color: var(--basic-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.badge {
  color: var(--success-alpha-11);
  background-color: var(--success-alpha-3);
}
</style>
