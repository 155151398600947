<script setup lang="ts">
import { toRefs } from 'vue'

interface Tab {
  title: string
  badge?: number
}

const props = defineProps<{
  modelValue: number
  tabs: Tab[]
}>()
const { modelValue } = toRefs(props)

const emit = defineEmits<{
  (e: 'update:modelValue', index: number): void
}>()

const handleSelect = (index: number) => {
  emit('update:modelValue', index)
}
</script>
<template>
  <ul :class="$style.tabs">
    <li
      v-for="(tab, index) in tabs"
      :key="tab.title"
      :class="[$style.tab, index === modelValue && $style.active]"
      @click="handleSelect(index)"
    >
      {{ tab.title }}
      <span v-if="tab.badge" :class="$style.badge">{{ tab.badge }}</span>
    </li>
  </ul>
</template>
<style lang="scss" module>
.tabs {
  @extend .medium-3;
  display: flex;
  list-style: none;
  :not(:last-child) {
    margin-right: 4px;
  }
}

.tab {
  cursor: pointer;
  color: var(--neutral-11);
  padding: 8px 8px;
  border-radius: 6px;
  transition:
    color 0.2s,
    background-color 0.2s;

  &:hover {
    color: var(--neutral-12);
    background-color: var(--neutral-alpha-3);
  }
}

.active {
  color: var(--neutral-12);
  background-color: var(--neutral-alpha-4);
}
.badge {
  margin-left: 6px;
}
</style>
