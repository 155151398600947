<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import Ticket from '@modules/inbox/models/ticket.ts'
import { Task } from '@modules/tasks/models/task.ts'
import Message from '@modules/inbox/models/message.ts'
import type Notification from '@/models/notification.ts'
import { TaskComment } from '@modules/tasks/models/taskComment.ts'
import { NotificationTypeEnum } from '@/models/notificationSetting.ts'

import { Icon, Avatar, Button } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace.ts'
import { ISODate } from '@/utils/helpers.ts'

const emit = defineEmits(['remove']);

interface NotificationItem {
  message: string
  route: {
    name: string
    params: {
      [key: string]: string | number | undefined
    }
  }
}

const router = useRouter()
const { api } = useWorkspaceStore()
const props = defineProps<{ notification: Notification }>()

const notification = ref(props.notification)

const NotificationTypes: {
  [key in NotificationTypeEnum]: () => NotificationItem
} = {
  [NotificationTypeEnum.TICKET_ASSIGNED]: () => {
    const ticket: Ticket = notification.value.subject as Ticket
    const { workspaceTicketId } = ticket

    return {
      message: `${notification.value.causer?.name} has assigned ticket #${workspaceTicketId} to you.`,
      route: {
        name: 'HDThread',
        params: { id: workspaceTicketId },
      },
    }
  },
  [NotificationTypeEnum.TICKET_MENTIONED]: () => {
    const message: Message = notification.value.subject as Message

    return {
      message: `${notification.value.causer?.name} has tagged you in ticket #${message.ticket?.workspaceTicketId}.`,
      route: {
        name: 'HDThread',
        params: { id: message.ticket?.workspaceTicketId },
      },
    }
  },
  [NotificationTypeEnum.TICKET_ASSIGNED_NEW_RESPONSE]: () => {
    const message: Message = notification.value.subject as Message

    return {
      message: `New response in ticket #${message.ticket?.workspaceTicketId}.`,
      route: {
        name: 'HDThread',
        params: { id: message.ticket?.workspaceTicketId },
      },
    }
  },
  [NotificationTypeEnum.TICKET_CREATED]: () => {
    const ticket: Ticket = notification.value.subject as Ticket
    const { workspaceTicketId } = ticket

    return {
      message: `New ticket #${workspaceTicketId} in inbox.`,
      route: {
        name: 'HDThread',
        params: { id: workspaceTicketId },
      },
    }
  },
  [NotificationTypeEnum.TASK_ASSIGNED]: () => {
    const task: Task = notification.value.subject as Task
    const taskNumber = `${task?.team?.code}-${task?.number}`

    return {
      message: `${notification.value.causer?.name} has assigned task ${taskNumber} to you.`,
      route: {
        name: 'TasksTask',
        params: { taskNumber: task.number, teamId: task.teamId },
      },
    }
  },
  [NotificationTypeEnum.TASK_COMMENT_MENTIONED]: () => {
    const comment: TaskComment = notification.value.subject as TaskComment
    const taskNumber = `${comment.task?.team?.code}-${comment.task?.number}`

    return {
      route: {
        name: 'TasksTask',
        params: { taskNumber: comment.task?.number, teamId: comment.task?.teamId },
      },
      message: `${notification.value.causer?.name} has tagged you in task ${taskNumber}`,
    }
  },
}

const item = computed<NotificationItem>(() =>
  NotificationTypes[notification.value.typeId](),
)

const fromNow = computed<string>(() => {
  return ISODate(notification.value.createdAt.toString(), { format: 'LLL dd' })
})

const onClick = () => {
  if (!notification.value.readAt) {
    api.workspace.readNotification(notification.value.id).then(() => router.push(item.value.route))
  } else {
    router.push(item.value.route)
  }
}

const markAsRead = () => {
  api.workspace.readNotification(notification.value.id)
  notification.value.readAt = new Date()
}
</script>

<template>
  <div :class="$style.container" @click="onClick">
    <div :class="$style.info">
      <div>
        <div :class="$style.iconPlaceholder">
          <Icon icon="box2AltFill" :size="20" />
          <Avatar
            :title="notification.causer?.name"
            :size="20"
            :class="$style.avatar"
          />
        </div>
      </div>

      <div :class="$style.content">
        <div :class="$style.dot" v-if="!notification.readAt" />
        <div :class="$style.messageContent">
          <p>{{ item.message }}</p>
          <p :class="$style.messageType">
            {{ notification.app?.name }} . {{ fromNow }}
          </p>
        </div>
      </div>
    </div>

    <div :class="$style.actions">
      <Button icon="trashCan2Outline" variant="ghost" theme="neutral" @click.stop="emit('remove', notification.id)"/>
      <Button v-if="!notification.readAt" icon="circleCheckOutline" variant="ghost" theme="neutral" @click.stop="markAsRead"/>
    </div>
  </div>
</template>

<style module lang="scss">
.container {
  display: flex;
  gap: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;

  .info {
    display: flex;
    gap: 12px;
  }

  .iconPlaceholder {
    padding: 6px;
    background-color: var(--neutral-alpha-3);
    border-radius: 8px;
    margin: 0 12px 0 0;
    width: fit-content;
    position: relative;
    .avatar {
      position: absolute;
      top: 17px;
      right: -8px;
    }
  }

  .content {
    display: flex;
    gap: 6px;
    .dot {
      height: 8px;
      width: 8px;
      margin-top: 6px;
      background-color: var(--danger-9);
      border-radius: 9999px;
    }

    .messageContent {
      @extend .medium-3;
      color: var(--neutral-alpha-12);
      .link {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }
    }
    .messageType {
      @extend .regular-2;
      color: var(--neutral-11);
    }
  }

  .actions {
    display: flex;
    gap: 4px;
    align-items: flex-start;
  }
}
</style>
