<script lang="ts" setup>
import { computed, ref } from 'vue'
import { UploadedFile } from '@/models/file.ts'
import { Button, Icon } from '@common/index.ts'
import { Popup } from '@/components/common'
import MediaPopup from './MediaPopup.vue'

const videoPopup = ref<InstanceType<typeof Popup> | null>(null)

const props = defineProps<{ file: UploadedFile | File; removable?: boolean }>()

const emit = defineEmits<{ (e: 'remove'): void }>()

const isLocalFile = computed<boolean>(() => {
  return props.file instanceof File
})

const isVideo = computed(() => {
  const file = props.file as UploadedFile
  return file.mime.includes('video')
})
const handleOpen = () => {
  videoPopup.value?.show()
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.icon">
      <Icon icon="fileBend" :size="16" />
    </div>
    <div :class="$style.content">
      <p :class="$style.title">{{ file.name }}</p>
    </div>

    <template v-if="!isVideo">
      <a v-if="!isLocalFile" :href="(file as UploadedFile).url" target="_blank">
        <Button
          size="1"
          icon="arrowInbox"
          variant="ghost"
          theme="neutral"
        ></Button>
      </a>
    </template>
    <Button
      v-else
      size="1"
      icon="playCircle"
      variant="ghost"
      theme="neutral"
      @click="handleOpen"
    />

    <Button
      v-if="removable"
      size="1"
      icon="crossSmall"
      variant="ghost"
      theme="neutral"
      @click="emit('remove')"
    ></Button>

    <Popup ref="videoPopup" title="Video">
      <MediaPopup :media="file as UploadedFile" />
    </Popup>
  </div>
</template>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  background-color: var(--neutral-alpha-3);
  border-radius: 4px;
  padding: 4px;
}
.icon {
  color: var(--neutral-11);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}
.content {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 12px;

  .title {
    @extend .medium-3;
  }
}
</style>
