<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { email as emailValidator, required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'

import { Button, Label, Popup, TextField } from '@/components/common'
import { useApplicationStore } from '@/store'
import { showToast } from '@/utils'

const appStore = useApplicationStore()

const email = ref(appStore.user?.email)

const popup = ref<InstanceType<typeof Popup> | null>(null)

const emailForm = reactive({
  email: '',
  password: '',
})

const emailRules = {
  email: { required, emailValidator },
  password: { required },
}

const emailForm$ = useVuelidate(emailRules, emailForm, { $scope: false })

const handleOpen = () => {
  popup.value?.show()
}

const clear = () => {
  emailForm.email = ''
  emailForm.password = ''
}

const handleClose = () => {
  popup.value?.hide()

  clear()
}

const handleSubmit = async () => {
  appStore.updateEmail(emailForm.email, emailForm.password).then(() => {
    showToast({
      text: `A verification email has been sent to ${emailForm.email}`,
    })
    handleClose()
  })
}
</script>

<template>
  <div :class="$style.wrapper" @click.prevent="handleOpen">
    <TextField :model-value="email" />
  </div>

  <Popup ref="popup" title="Change email" @hide="clear">
    <form @submit.prevent="handleSubmit">
      <div :class="$style.content">
        <div :class="$style.description">
          If you&apos;d like to change the email address for your account, we
          will send a verification link to your new email address. This change
          will apply across all your workspaces that you are a member of.
        </div>

        <Label>
          Enter new email address
          <TextField
            v-model="emailForm$.email.$model"
            placeholder="New email address"
          />
        </Label>

        <Label>
          Enter your password
          <TextField
            v-model="emailForm$.password.$model"
            type="password"
            placeholder="Your password"
          />
        </Label>
      </div>

      <div :class="$style.actions">
        <Button
          type="submit"
          size="3"
          variant="solid"
          theme="neutral"
          :disabled="emailForm$.$invalid"
          :loading="appStore.isStatus('posting')"
        >
          Change email
        </Button>
      </div>
    </form>
  </Popup>
</template>

<style module lang="scss">
.wrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    inset: 0;
    cursor: pointer;
  }
}

.content {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 52px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  .description {
    @extend .regular-3;

    color: var(--neutral-11);
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
}
</style>
