import Base from './index.vue'
import Contacts from './Contacts.vue'
import Pipeline from './Pipeline.vue'
import Pipelines from './Pipelines.vue'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    name: 'CRM',
    path: 'crm',
    meta: { title: 'CRM' },
    components: { default: Base },
    redirect: { name: 'CRMContacts' },
    children: [
      {
        name: 'CRMContacts',
        meta: { title: 'CRM Contacts' },
        path: '',
        component: Contacts,
      },
      {
        name: 'CRMPipelines',
        meta: { title: 'CRM Pipelines' },
        path: 'pipelines',
        component: Pipelines,
      },
      {
        name: 'CRMPipeline',
        meta: { title: 'CRM Pipeline' },
        path: 'pipelines/:pipelineId',
        component: Pipeline,
      },
    ],
  },
]

export default routes
