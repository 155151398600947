<script setup lang="ts">
import { Page } from '@/components/common/layout'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { SettingsItem, type SettingsGroup } from './models/settings'
import { Icon } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'

const route = useRoute()
const store = useWorkspaceStore()

const activeRoute = ref(route.name)
const activeAppKeys = computed(() => store.apps.map((app) => app.key))

const handleActiveRoute = () => {
  activeRoute.value = route.name
}

handleActiveRoute()
watch(() => route.name, handleActiveRoute)

const groups: SettingsGroup[] = [
  {
    items: [
      {
        name: 'My account',
        icon: 'peopleCircle',
        to: { name: 'AccountSettings' },
      },
      {
        name: 'Notifications',
        icon: 'bell2',
        to: { name: 'NotificationsSettings' },
      },
    ],
  },
  {
    groupName: 'Workspace Settings',
    items: [
      {
        name: 'Members',
        icon: 'group2',
        to: { name: 'TeamSettings' },
      },
      {
        name: 'Billing',
        icon: 'creditCard2',
        to: { name: 'BillingSettings' },
      },
      {
        name: 'Edit Workspace',
        icon: 'editSmall2',
        to: { name: 'WorkspaceSettings' },
      },
    ],
  },
  {
    groupName: 'App Settings',
    items: [
      ...(activeAppKeys.value.includes('inbox')
        ? [
            {
              name: 'Inbox',
              icon: 'box2AltFill',
              to: { name: 'InboxSettings' },
            } as SettingsItem,
          ]
        : []),
      ...(activeAppKeys.value.includes('crm')
        ? [
            {
              name: 'CRM',
              icon: 'crm',
              to: { name: 'CRMSettings' },
            } as SettingsItem,
          ]
        : []),
      ...(activeAppKeys.value.includes('projectManagement')
        ? [
            {
              name: 'Tasks',
              icon: 'projectManagement',
              to: { name: 'TaskSettings' },
            } as SettingsItem,
          ]
        : []),
    ],
  },
]
</script>

<template>
  <Page :full-screen="true">
    <template #topBar> Settings </template>
    <div :class="$style.container">
      <div :class="$style.navigation">
        <div
          v-for="{ groupName, items } in groups"
          :key="groupName"
          :class="$style.group"
        >
          <div v-if="groupName" :class="$style.groupName">{{ groupName }}</div>

          <ul :class="$style.group">
            <li v-for="{ name, to, icon } in items" :key="name">
              <router-link
                :to="to"
                :class="[
                  $style.route,
                  to.name === activeRoute && $style.active,
                ]"
              >
                <Icon :icon="icon" :size="18" />
                {{ name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div :class="$style.content">
        <div :class="$style.inner">
          <router-view />
        </div>
      </div>
    </div>
  </Page>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 32px;
  height: 100%;
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 196px;
  padding-top: 32px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .groupName {
      @extend .medium-2;
      color: var(--neutral-alpha-10);
      padding: 4px 0px;
    }

    .route {
      @extend .medium-3;
      padding: 10px 8px;
      transition: all 0.1s;
      display: flex;
      align-items: center;
      column-gap: 6px;
      border-radius: 6px;
      line-height: unset;

      i {
        color: var(--neutral-11);
      }

      &:hover {
        background-color: var(--neutral-alpha-3);
      }

      &.active {
        color: var(--neutral-900);
        background-color: var(--neutral-alpha-3);
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  align-items: center;
  padding-top: 32px;

  .inner {
    width: 556px;
  }
}
</style>
