import { renderGrouped } from 'prosemirror-menu'
import type { EditorState } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'

class FlyingMenu {
  private tooltip: HTMLDivElement
  private contentUpdate: (state: EditorState) => boolean

  constructor(view: EditorView, options: any) {
    this.tooltip = document.createElement('div')
    this.tooltip.className = 'ProseMirror-menubar flying'

    const { dom, update } = renderGrouped(view, options.menu)

    this.contentUpdate = update
    this.tooltip.appendChild(dom)
    document.getElementById('page')?.appendChild(this.tooltip)

    this.update(view, null)
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Node
    if (
      !this.tooltip.contains(target) &&
      !document.querySelector('.popup-content')?.contains(target)
    ) {
      this.tooltip.style.display = 'none'
    }
  }

  update(view: EditorView, lastState: EditorState | null) {
    const state = view.state
    if (
      lastState &&
      lastState.doc.eq(state.doc) &&
      lastState.selection.eq(state.selection)
    )
      return

    if (state.selection.empty) {
      this.tooltip.style.display = 'none'
      return
    }

    this.tooltip.style.display = ''
    this.contentUpdate(view.state)
    const { from, to } = state.selection
    const start = view.coordsAtPos(from),
      end = view.coordsAtPos(to)

    const box = this.tooltip.getBoundingClientRect()

    const selectionCenter = start.left + (end.left - start.left) / 2
    const boxCenter = box.width / 2

    this.tooltip.style.left = selectionCenter - boxCenter + 'px'
    this.tooltip.style.top = start.top - (box.height + 8) + 'px'
  }

  destroy() {
    this.tooltip.remove()
  }
}

export default FlyingMenu
