<script setup lang="ts">
import { Button } from '@/components/common'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import AppCard from '@/components/marketplace/AppCard.vue'
import { useApplicationStore } from '@/store'

const store = useApplicationStore()
store.getAllApps()
</script>
<template>
  <auth-layout
    title="Add your first app for free!"
    subtitle="Browse all Vanilla apps available for you"
    :full-width="true"
  >
    <template #logo> Marketplace </template>
    <template #extra>
      <div :class="$style.extra">
        <div :class="$style.email">{{ store.user?.email }}</div>

        <Button
          size="2"
          variant="outline"
          theme="neutral"
          @click="store.signOut"
        >
          Logout
        </Button>
      </div>
    </template>
    <div :class="$style['apps-grid']">
      <AppCard
        v-for="app in store.apps"
        :key="app.id"
        :app="app"
        @click="
          $router.push({
            name: 'OnboardingMarketplaceApp',
            params: { appId: app.id },
          })
        "
      />
    </div>
  </auth-layout>
</template>
<style module lang="scss">
.extra {
  display: flex;
  align-items: center;
  gap: 12px;

  .email {
    @extend .medium-3;
  }
}
.apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
  margin: 32px 0;
}
</style>
