import { ref } from 'vue'
import {
  AxiosError,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios'
import router from '@/routes'
import Api from '@/utils/api'
import { checkIsGatedRoute, showToast } from '@/utils'
import { useRoute } from 'vue-router'

export type API_STATUS =
  | 'default'
  | 'loading'
  | 'loaded'
  | 'posting'
  | 'deleting'

export interface LaravelError {
  message: string
  errors: { [key: string]: string[] }
}

export type ApiError = LaravelError | AxiosError | Error | undefined

export function useWorkspaceApi() {
  const route = router.currentRoute.value
  return useApi(`${import.meta.env.VITE_API_HOST}${route.params.workspaceId}`)
}

export default function useApi(
  baseURL: string = import.meta.env.VITE_API_HOST,
) {
  const route = useRoute()
  const loadStatus = ref<API_STATUS>('default')
  const postStatus = ref<API_STATUS>('default')
  const error = ref<string | undefined>()

  const api = new Api(baseURL)

  const requestInterceptor = (config: InternalAxiosRequestConfig) => {
    error.value = undefined
    if (config.method === 'get') loadStatus.value = 'loading'
    if (
      config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'patch'
    )
      postStatus.value = 'posting'
    if (config.method === 'delete') postStatus.value = 'deleting'

    return { ...config }
  }

  const responseInterceptor = (response: AxiosResponse) => {
    const { config } = response
    if (config.method === 'get') loadStatus.value = 'loaded'
    if (
      config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'patch'
    )
      postStatus.value = 'default'
    if (config.method === 'delete') postStatus.value = 'default'
    return { ...response }
  }

  const errorInterceptor = (e: AxiosError<ApiError>) => {
    const isGatedRoute = checkIsGatedRoute(route.name as string)
    const statusCode = e.response?.status

    if (statusCode === 401 && isGatedRoute) {
      return (window.location.href = '/login')
    }

    const errorMessages = e.response?.data?.message
    if (errorMessages) {
      // API error
      const message = Array.isArray(errorMessages)
        ? errorMessages[0]
        : errorMessages

      error.value = message

      // suppress unauthorized on public facing pages
      statusCode !== 401 && showToast({ type: 'error', text: message })
    } else {
      // Axios/Network errors
      const message = e.message

      error.value = message

      // suppress unauthorized on public facing pages
      statusCode !== 401 && showToast({ type: 'error', text: message })
    }
    e.response?.config.method === 'get'
      ? (loadStatus.value = 'default')
      : (postStatus.value = 'default')
    return Promise.reject(error)
  }

  api.client.interceptors.request.use(requestInterceptor)
  api.client.interceptors.response.use(responseInterceptor, errorInterceptor)

  const isStatus = (s: API_STATUS) =>
    s === loadStatus.value || s === postStatus.value

  return { api, isStatus, error }
}
