<script lang="ts" setup>
import type { Task } from '@modules/tasks/models/task.ts'
import type { TaskStatus } from '@modules/tasks/models/taskStatus.ts'
import { computed, toRefs } from 'vue'
import draggable from 'vuedraggable'
import { useTasksTeamStore } from '@modules/tasks/teamStore.ts'
import StatusHeader from '@modules/tasks/components/StatusHeader.vue'
import { LexoRank } from 'lexorank'
import TaskItem from '@modules/tasks/components/ListView/TaskItem.vue'
import { SortableEvent } from '@modules/tasks/components/BoardView/types.ts'
import { findSiblings, getNewLexorank } from '@/utils/board.ts'

const props = defineProps<{ status: TaskStatus, tasks: Task[] }>()

const store = useTasksTeamStore()
const { status, tasks } = toRefs(props)

const sortedTasks = computed<Task[]>(() =>
  [...tasks.value].sort((a, b) =>
    LexoRank.parse(a.rank).compareTo(LexoRank.parse(b.rank)),
  )
)

const handleTaskMove = (event: SortableEvent<Task>, status: TaskStatus) => {
  const {
    item: { _underlying_vm_ },
    newIndex,
    oldIndex,
  } = event
  const task = { ..._underlying_vm_ }
  const payload = findSiblings(newIndex, oldIndex, sortedTasks.value)
  const rank = getNewLexorank(payload)
  store.moveTaskToStage(task, status, rank.toString(), newIndex)
}

</script>

<template>
  <div :class="$style.statusGroup">
    <div :class="$style.header">
      <StatusHeader enable-add-task :status="status" :count="tasks.length" :allowed-actions="['rename', 'delete']"/>
    </div>

    <draggable
      :list="sortedTasks"
      animation="200"
      group="sortedTasks"
      item-key="id"
      ghost-class="ghost"
      @update="(event: SortableEvent<Task>) => handleTaskMove(event, status)"
    >
      <template #item="{ element: task }: { element: Task }">
       <TaskItem :task="task"/>
      </template>
    </draggable>
  </div>
</template>

<style module lang="scss">
.statusGroup {
  margin-bottom: 8px;

  .header {
    border-radius: 12px;
    border: 1px solid var(--panel-3);
    background-color: var(--panel-3);
  }
}
</style>
