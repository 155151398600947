import type User from '@/models/user'
import type { AxiosInstance } from 'axios'
import type Ticket from '../models/ticket'
import type { AssignBulkResponse, AssignResponse } from './types'
import type Shortcut from '../models/shortcut'
import type { Pagination } from '@/utils/api/types'
import type { TicketStatus } from '../models/ticket'
import type { DNS } from '@/models/DNS'
import type { WidgetSettings } from '../types'

export class InboxService {
  constructor(private instance: AxiosInstance) {}

  sendMessage = (ticket: Ticket, data: FormData) =>
    this.instance
      .post(`/inbox/tickets/${ticket.id}`, data)
      .then((response) => response.data)

  loadTickets = (query: Array<string>) =>
    this.instance
      .get<Pagination<Ticket>>(`/inbox/tickets?${query.join('&')}`)
      .then((response) => response.data)

  updateAssignee = (ticket: Ticket, users: User[]) =>
    this.instance
      .post<AssignResponse>(`/inbox/tickets/${ticket.id}/assign`, {
        users: users.map((u) => u.id),
      })
      .then((response) => response.data)

  updateTicketStatus = (ticket: Ticket, status: TicketStatus) =>
    this.instance
      .post<Ticket>(`/inbox/tickets/${ticket.id}/status`, { status })
      .then((response) => response.data)

  loadMessages = (ticketId: number) =>
    this.instance
      .get<Ticket>(`/inbox/tickets/${ticketId}`)
      .then((response) => response.data)

  markAsRead = (ticketId: number, ids: number[]) =>
    this.instance
      .post(`/inbox/tickets/${ticketId}/read`, { ids })
      .then((response) => response.data)

  loadShortcuts = () =>
    this.instance
      .get<Shortcut[]>('/inbox/shortcuts')
      .then((response) => response.data)

  addShortcut = (shortcut: Shortcut) =>
    this.instance
      .post<Shortcut>('/inbox/shortcuts', shortcut)
      .then((response) => response.data)

  editShortcut = (shortcut: Shortcut) =>
    this.instance
      .put<Shortcut>(`/inbox/shortcuts/${shortcut.id}`, shortcut)
      .then((response) => response.data)

  deleteShortcut = (shortcut: Shortcut) =>
    this.instance
      .delete(`/inbox/shortcuts/${shortcut.id}`)
      .then((response) => response.data)

  sendTestEmail = (email: string) =>
    this.instance
      .post(`/inbox/send-test`, { email })
      .then((response) => response.data)

  addDomain = (email: string) =>
    this.instance
      .post<{
        domain: string
        dns: DNS[]
        isVerified: boolean
      }>('/inbox/domains', { email })
      .then((response) => response.data)

  getDomain = () =>
    this.instance
      .get<{
        domain: string
        dns: DNS[]
        isVerified: boolean
      }>(`/inbox/domains`)
      .then((response) => response.data)

  checkDomain = () =>
    this.instance
      .get<{ status: boolean }>('inbox/domains/check')
      .then((response) => response.data)

  updateWidget = (data: FormData) =>
    this.instance
      .post('inbox/widget?_method=PUT', data)
      .then((response) => response.data)

  updateTicketsStatusBulk = (ids: number[], status: TicketStatus) =>
    this.instance
      .post('/inbox/tickets/status/bulk', { ids, status })
      .then((res) => res.data)

  updateTicketsAssigneeBulk = (ids: number[], users: number[]) =>
    this.instance
      .post<AssignBulkResponse>('/inbox/tickets/assignee/bulk', { ids, users })
      .then((res) => res.data)

  deleteTicketsBulk = (ids: number[]) =>
    this.instance
      .delete(`/inbox/tickets/bulk?${ids.map((id) => `id[]=${id}`).join('&')}`)
      .then((res) => res.data)

  getWidget = () =>
    this.instance
      .get<WidgetSettings | null>('inbox/widget')
      .then((response) => response.data)

  getUnreadMessagesCount = () =>
    this.instance
      .get<number>('inbox/unread-messages-count')
      .then((response) => response.data)
}
