<script setup lang="ts">
import { useApplicationStore } from '@/store'
import { Avatar, Icon, Menu, MenuItem, MenuTitle } from '../common'
import { useWorkspaceStore } from '@/store/workspace'
import { computed } from 'vue'

const appStore = useApplicationStore()
const workspaceStore = useWorkspaceStore()

const otherWorkspaces = computed(() =>
  workspaceStore.workspaces.filter(
    (w) => w.id !== workspaceStore.workspace?.id,
  ),
)
</script>
<template>
  <Menu :width="272">
    <MenuTitle>Logged in as</MenuTitle>
    <router-link v-slot="{ navigate }" custom :to="{ name: 'AccountSettings' }">
      <MenuItem
        :hide-on-click="true"
        :title="appStore.user?.name || ''"
        :subtitle="appStore.user?.email || ''"
        @click="navigate"
      >
        <template #icon>
          <Avatar
            :size="32"
            :src="appStore.user?.avatar"
            :title="appStore.user?.name"
          />
        </template>
        <template #action>
          <Icon :size="20" icon="settingsGear2" :class="$style.icon" />
        </template>
      </MenuItem>
    </router-link>
    <hr />
    <MenuTitle>Current Workspace</MenuTitle>
    <router-link
      v-slot="{ navigate }"
      custom
      :to="{ name: 'WorkspaceSettings' }"
    >
      <MenuItem
        :hide-on-click="true"
        :title="workspaceStore.workspace?.title || ''"
        @click="navigate"
      >
        <template #icon>
          <Avatar
            v-if="workspaceStore.workspace?.logo"
            :src="workspaceStore.workspace?.logo"
            :size="24"
          />
          <div v-else :class="$style.workspaceLogo">
            <Icon icon="block" :size="16" />
          </div>
        </template>
        <template #action>
          <Icon :size="20" icon="settingsGear2" :class="$style.icon" />
        </template>
      </MenuItem>
    </router-link>
    <template v-if="otherWorkspaces.length > 0">
      <hr />
      <MenuTitle>Other Workspaces</MenuTitle>
      <router-link
        v-for="workspace in otherWorkspaces"
        :key="workspace.id"
        v-slot="{ href }"
        custom
        :to="{ name: 'Dashboard', params: { workspaceId: workspace.id } }"
      >
        <a :href="href">
          <MenuItem :title="workspace.title || ''">
            <template #icon>
              <Avatar v-if="workspace.logo" :src="workspace.logo" :size="24" />
              <div v-else :class="$style.workspaceLogo">
                <Icon icon="block" :size="16" />
              </div>
            </template>
          </MenuItem>
        </a>
      </router-link>
    </template>
    <hr />
    <a href="mailto:hello@vanillaapps.com">
      <MenuItem :hide-on-click="true" title="Found a bug?" />
    </a>

    <a href="https://vanillaapps.com/terms" target="_blank">
      <MenuItem :hide-on-click="true" title="Terms & Policies" />
    </a>
    <hr />
    <MenuItem title="Logout" @click="appStore.signOut()"></MenuItem>
  </Menu>
</template>
<style module lang="scss">
.icon {
  color: var(--neutral-11);
}
.workspaceLogo {
  width: 24px;
  height: 24px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-3);
  color: var(--neutral-8);
}
</style>
