<script setup lang="ts">
import { Button, Label, TextField } from '@/components/common'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { reactive, toRefs } from 'vue'
import type Shortcut from '../../models/shortcut'
import { useInboxStore } from '../../store'
import { showToast } from '@/utils'

const props = defineProps<{
  shortcut?: Shortcut
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { shortcut } = toRefs(props)

const store = useInboxStore()
const form = reactive({
  id: shortcut?.value?.id,
  command: shortcut?.value?.command || '',
  description: shortcut?.value?.description || '',
})

const formRules = {
  command: { required },
  description: {
    required,
  },
}

const form$ = useVuelidate(formRules, form)

const handleSubmit = () => {
  if (shortcut?.value) {
    return store.editShortcut(form).then(() => {
      emit('close')
      showToast({ text: 'Shortcut has been updated', type: 'success' })
    })
  }
  return store.addShortcut(form).then(() => {
    emit('close')
    showToast({ text: 'New shortcut has been created', type: 'success' })
  })
}
</script>
<template>
  <form :class="[$style.content]" @submit.prevent="handleSubmit">
    <div class="popup-content">
      <p :class="$style.intro">
        Shortcuts is a great way to speedup communication with your customers!
        Simply type / and shortcut name to send a saved message.
      </p>
      <Label>
        Shortcut name
        <TextField v-model="form$.command.$model" placeholder="My shortcut">
          <template #pre> / </template>
        </TextField>
      </Label>
      <Label>
        Shortcut Text
        <TextField
          v-model="form$.description.$model"
          placeholder="Enter your shortcut message text"
          :multi-line="true"
          rows="4"
        />
      </Label>
    </div>
    <div :class="[$style.shortcutActions, 'popup-content']">
      <slot v-if="$slots['custom-action']" name="custom-action" />
      <Button
        type="submit"
        theme="neutral"
        size="3"
        variant="solid"
        :disabled="form$.$invalid"
      >
        {{ shortcut?.id ? 'Update shortcut' : 'Create shortcut' }}
      </Button>
    </div>
  </form>
</template>
<style module lang="scss">
.intro {
  @extend .regular-3;
  color: var(--neutral-11);
  margin-bottom: 24px;
}

.shortcutActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid var(--neutral-alpha-4);
}

.content {
  width: 380px;

  label {
    margin-bottom: 24px;
  }

  button {
    width: max-content;
  }
}
</style>
