<script setup lang="ts">
import draggable from 'vuedraggable'
import { Icon } from '@/components/common'
import type { PipelineStage } from '../../models/pipelineStage'
import { toRefs } from 'vue'
import { useCrmPipelineStore } from '../../pipelineStore'
import Column from './Column.vue'
import type { SortableEvent } from './types'
import type { Pipeline } from '../../models/pipeline'

const store = useCrmPipelineStore()
const { stages, stageContactMap } = toRefs(store)

const handleStageMove = (event: SortableEvent<Pipeline>) => {
  const { newIndex, oldIndex } = event
  store.moveStage(oldIndex, newIndex)
}
</script>
<template>
  <draggable
    :class="$style.board"
    :model-value="stages"
    animation="200"
    group="stages"
    item-key="id"
    @update="handleStageMove"
  >
    <template #item="{ element }: { element: PipelineStage }">
      <Column :stage="element" :contacts="stageContactMap[element.id] || []" />
    </template>
    <template #footer>
      <div :class="[$style.column, $style.new]" @click="store.addStage">
        <Icon icon="plusSmall" :size="24" />
        Create new column
      </div>
    </template>
  </draggable>
</template>
<style module lang="scss">
.board {
  flex-grow: 1;
  display: flex;
  column-gap: 8px;
  padding-bottom: 16px;
  overflow: auto;

  .new {
    @extend .medium-3;
    display: flex;
    width: 340px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    row-gap: 8px;
    color: var(--neutral-11);
    border: 1px dashed var(--neutral-alpha-4);
    background-color: transparent;
    transition:
      background-color 0.3s,
      color 0.3s;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-alpha-2);
      color: var(--neutral-12);
    }
  }
}
</style>
