import { useWorkspaceApi } from '@/composables/api'
import type { Pipeline } from './models/pipeline'
import type { PipelineContact } from './models/pipelineContact'
import type { PipelineStage } from './models/pipelineStage'
import { defineStore } from 'pinia'
import type User from '@/models/user'
import type { Company } from '@/models/company'
import type { Tag } from '@/models/tag'
import type { ContactSearch } from './store'
import type { Pagination } from '@/utils/api/types'

interface CRMStore extends ReturnType<typeof useWorkspaceApi> {
  id?: number
  pipeline?: Pipeline
  stages: PipelineStage[]
  contacts: PipelineContact[]
  pagination?: Pagination<PipelineContact>
  isAddContactOpen: boolean
}

export const useCrmPipelineStore = defineStore('crmPipeline', {
  state: (): CRMStore => ({
    id: undefined,
    pagination: undefined,
    pipeline: undefined,
    contacts: [],
    stages: [],
    isAddContactOpen: false,
    ...useWorkspaceApi(),
  }),
  getters: {
    stageContactMap() {
      const map: Record<number, PipelineContact[]> = {}
      this.contacts.forEach((contact) => {
        if (!map[contact.stageId]) {
          map[contact.stageId] = []
        }
        map[contact.stageId].push(contact)
      })
      return map
    },
  },
  actions: {
    addContact(contact: PipelineContact) {
      this.isAddContactOpen = false
      this.contacts = [contact, ...this.contacts]
      Object.assign(this.pagination || {}, {
        totalItems: (this.pagination?.totalItems || 0) + 1,
      })
      // if no stage is defined, load that untitled stage
      if (this.stages.length === 0 && this.id) {
        this.loadPipeline(this.id)
      }
    },
    loadPipeline(id: number) {
      this.id = id
      this.api.crm.loadPipeline(id).then((data) => {
        this.pipeline = data
        this.stages = data.stages as PipelineStage[]
      })
    },
    loadContacts(search: ContactSearch) {
      this.api.crm
        .loadContacts(
          {
            ...(search.company
              ? { companies: search.company.map((c) => c.id) }
              : {}),
            ...(search.tags ? { tags: search.tags.map((c) => c.id) } : {}),
            ...(search.assignee
              ? { pipelineAssignees: search.assignee.map((c) => c.id) }
              : {}),
            ...(search.status
              ? { status: search.status.map((c) => c.id) }
              : {}),
          },
          this.id,
        )
        .then((data) => {
          this.pagination = data
          this.contacts = data.data
        })
    },
    addStage() {
      this.pipeline?.id &&
        this.api.crm
          .createPipelineStage(this.pipeline?.id, `Untitled Column`)
          .then((data) => {
            this.stages.push(data)
          })
    },
    deleteStage(id: number) {
      const updated = [...this.stages].filter((c) => c.id !== id)

      this.stages = updated.map((stage, index) => ({
        ...stage,
        order: index,
      }))

      this.api.crm.deletePipelineStage(id)
    },
    updateStage(stage: PipelineStage, title: string) {
      const index = this.stages.findIndex((s) => s.id === stage.id)
      Object.assign(this.stages[index], {
        title,
      })
      this.api.crm.updatePipelineStage(stage.id, title)
    },
    moveStage(oldIndex: number, newIndex: number) {
      const newStages = [...this.stages]
      const item = newStages[oldIndex]
      newStages.splice(oldIndex, 1)
      newStages.splice(newIndex, 0, item)
      this.stages = newStages.map((stage, index) => ({
        ...stage,
        order: index,
      }))
      this.pipeline &&
        this.api.crm.updatePipelineStageOrder(
          this.pipeline.id,
          this.stages.map((s) => s.id),
        )
    },
    moveContactToStage(
      contact: PipelineContact,
      stage: PipelineStage,
      rank?: string,
      order?: number,
    ) {
      const index = this.contacts.findIndex((c) => c.id === contact.id)
      Object.assign(this.contacts[index], {
        stageId: stage.id,
        order: order || 0,
        rank,
      })

      this.pipeline &&
        this.api.crm.updateContactStage(this.pipeline.id, {
          contactId: contact.id,
          stageId: stage.id,
          order,
          rank,
        })
    },
    async bulkMoveContactToStage(ids: number[], stage: PipelineStage) {
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].stageId = stage.id
        }
      })
      return this.api.crm.updateBulk(
        {
          ids,
          stageId: stage.id,
        },
        this.pipeline?.id,
      )
    },
    assignContact(contact: PipelineContact, user: User) {
      const index = this.contacts.findIndex((c) => c.id === contact.id)
      Object.assign(this.contacts[index], {
        pipelineAssignee: user,
      })

      this.pipeline &&
        this.api.crm.updateContactStage(this.pipeline.id, {
          contactId: contact.id,
          assigneeId: user.id,
        })
    },
    async bulkAssignContact(ids: number[], user: User) {
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].pipelineAssignee = user
        }
      })
      return this.api.crm.updateBulk(
        {
          ids,
          pipelineAssigneeId: user.id,
        },
        this.pipeline?.id,
      )
    },
    async bulkAssignCompany(ids: number[], company: Company) {
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].company = company
        }
      })
      return this.api.crm.updateBulk(
        {
          ids,
          companyId: company.id,
        },
        this.pipeline?.id,
      )
    },
    addContactTags(contact: PipelineContact, tags: Tag[]) {
      const index = this.contacts.findIndex((c) => c.id === contact.id)
      Object.assign(this.contacts[index], {
        tags: tags,
      })

      this.api.crm.updateContactTags(
        contact.id,
        tags.map((t) => t.id),
      )
    },
    async bulkAddContactTags(ids: number[], added: Tag[], removed: Tag[]) {
      const removedIds = removed.map((c) => c.id)
      const addedIds = added.map((c) => c.id)
      this.contacts.forEach((c, index) => {
        if (ids.includes(c.id)) {
          this.contacts[index].tags = [
            ...(c.tags || []).filter(
              (d) => !removedIds.includes(d.id) && !addedIds.includes(d.id),
            ),
            ...added,
          ]
        }
      })
      return this.api.crm.updateBulk(
        {
          ids,
          tagsAdded: addedIds,
          tagsRemoved: removedIds,
        },
        this.pipeline?.id,
      )
    },
    removeContacts(ids: number[]) {
      const updated = [...this.contacts].filter((c) => !ids.includes(c.id))
      this.contacts = updated
      Object.assign(this.pagination || {}, {
        totalItems: (this.pagination?.totalItems || 0) - ids.length,
      })
      return (
        this.pipeline &&
        this.api.crm.deleteBulk(
          {
            ids,
          },
          this.pipeline.id,
        )
      )
    },
  },
})
