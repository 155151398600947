import type { AxiosInstance } from 'axios'
import type { Pipeline } from '../models/pipeline'
import type { PipelineStage } from '../models/pipelineStage'
import type { Pagination } from '@/utils/api/types'
import type {
  ContactAddRequest,
  ContactBulkRequest,
  ContactUpdateRequest,
  LoadContactsRequest,
  UpdatePipelineContactRequest,
} from './types'
import type { PipelineContact } from '../models/pipelineContact'

export class CRMService {
  constructor(private instance: AxiosInstance) {}

  loadContacts = (request: LoadContactsRequest, pipelineId?: number) =>
    this.instance
      .get<
        Pagination<PipelineContact>
      >(pipelineId ? `/crm/pipelines/${pipelineId}/contacts` : `/crm/contacts`, { params: request })
      .then((response) => response.data)

  createPipeline = (name: string) =>
    this.instance
      .post<Pipeline>(`/crm/pipelines`, { name })
      .then((response) => response.data)

  updatePipeline = (id: number, name: string) =>
    this.instance
      .patch<Pipeline>(`/crm/pipelines/${id}`, { name })
      .then((response) => response.data)

  duplicatePipeline = (id: number) =>
    this.instance
      .post<Pipeline>(`/crm/pipelines/${id}/duplicate`)
      .then((response) => response.data)

  deletePipeline = (id: number) =>
    this.instance
      .delete(`/crm/pipelines/${id}`)
      .then((response) => response.data)

  loadPipelines = () =>
    this.instance
      .get<Pipeline[]>(`/crm/pipelines`)
      .then((response) => response.data)

  loadPipeline = (id: number) =>
    this.instance
      .get<Pipeline>(`/crm/pipelines/${id}`)
      .then((response) => response.data)

  createPipelineStage = (pipelineId: number, title: string) =>
    this.instance
      .post<PipelineStage>(`/crm/pipelines/${pipelineId}/stages`, { title })
      .then((response) => response.data)

  updatePipelineStage = (stageId: number, title: string) =>
    this.instance
      .put<PipelineStage>(`/crm/stages/${stageId}`, { title })
      .then((response) => response.data)

  updatePipelineStageOrder = (pipelineId: number, order: number[]) =>
    this.instance
      .put<PipelineStage>(`/crm/pipelines/${pipelineId}/stages/reorder`, {
        ids: order,
      })
      .then((response) => response.data)

  deletePipelineStage = (stageId: number) =>
    this.instance
      .delete(`/crm/stages/${stageId}`)
      .then((response) => response.data)

  loadPipelineStages = (pipelineId: number) =>
    this.instance
      .get<PipelineStage[]>(`/crm/pipelines/${pipelineId}/stages`)
      .then((response) => response.data)

  contacts = () =>
    this.instance.get<[]>(`/crm/contacts`).then((response) => response.data)

  addContact = (payload: ContactAddRequest) =>
    this.instance
      .post<PipelineContact>(`/crm/contacts`, payload)
      .then((response) => response.data)

  updateContact = (id: number, payload: ContactUpdateRequest) =>
    this.instance
      .patch<PipelineContact>(`/crm/contacts/${id}`, payload)
      .then((response) => response.data)

  updateContactStage = (
    pipelineId: number,
    payload: UpdatePipelineContactRequest,
  ) =>
    this.instance
      .post<PipelineContact>(
        `/crm/pipelines/${pipelineId}/update-contact`,
        payload,
      )
      .then((response) => response.data)

  updateContactTags = (contactId: number, ids: number[]) =>
    this.instance
      .post<PipelineContact>(`/crm/contacts/${contactId}/tags`, { ids })
      .then((response) => response.data)

  updateBulk = (payload: ContactBulkRequest, pipelineId?: number) =>
    this.instance
      .put(
        pipelineId ? `/crm/pipelines/${pipelineId}/bulk` : `/crm/contacts/bulk`,
        payload,
      )
      .then((response) => response.data)

  deleteBulk = (
    payload: Pick<ContactBulkRequest, 'ids'>,
    pipelineId?: number,
  ) =>
    this.instance
      .delete(
        pipelineId ? `/crm/pipelines/${pipelineId}/bulk` : `/crm/contacts/bulk`,
        {
          params: payload,
        },
      )
      .then((response) => response.data)
}
