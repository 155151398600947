<script setup lang="ts">
import { toRefs } from 'vue'
import { type Email } from '../../models/message'

const props = defineProps<{
  email: Email
}>()
const { email } = toRefs(props)
</script>
<template>
  <div class="rich-text">
    <b :class="$style.subject">{{ email.subject }}</b>
    <p v-html="email.body"></p>
  </div>
</template>
<style module lang="scss">
.subject {
  @extend .semibold-3;
  display: block;
}
</style>
