<script setup lang="ts">
import Icons from '@/assets/icons'

import { toRefs } from 'vue'

const props = defineProps<{
  icon: keyof typeof Icons
  size?: number
  width?: number
  height?: number
}>()
const { icon, size, width, height } = toRefs(props)
</script>
<template>
  <i
    :class="[
      $style.icon,
      (icon === 'vanillaIcon' || icon === 'vanillaLogo') && $style.logo,
    ]"
    :style="{ width: `${width || size}px`, height: `${height || size}px` }"
  >
    <component :is="Icons[icon]"></component>
  </i>
</template>
<style module lang="scss">
.icon {
  display: inline-block;
  svg {
    width: 100%;
    height: 100%;
  }

  &.logo {
    color: var(--neutral-12) !important;
  }
}
</style>
