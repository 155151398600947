<script
  setup
  lang="ts"
  generic="
    T extends Record<string, any>,
    S extends 'single' | 'multi' = 'single'
  "
>
import { Menu, TextField, Icon, MenuItem, Checkbox } from '@/components/common'
import { clone } from '@/utils'
import { isArray } from 'lodash'
import { computed, ref } from 'vue'

type SelectedType = S extends 'multi' ? T[] : T

const props = defineProps<{
  items: T[]
  selected?: SelectedType
  selectedIds?: number[]
  itemKey: keyof T
  titleKey: keyof T
  selection?: S
  resetLabel?: string
}>()

const emits = defineEmits<{
  (e: 'update', items: SelectedType, added: T[], removed: T[]): void
}>()

defineSlots<{
  icon(props: { reset: boolean; item?: T }): void
  empty(props: { search: string }): void
}>()

const selection = computed<T[]>(() => {
  if (props.selected) {
    return isArray(props.selected) ? props.selected : [props.selected]
  }
  return props.items.filter((i) =>
    props.selectedIds?.includes(i[props.itemKey]),
  )
})
const selectionIds = computed(() =>
  selection.value.map((item) => item[props.itemKey]),
)

const search = ref<string>('')
const displayedItems = computed(() => {
  const p = props.items.filter((item) =>
    item[props.titleKey].toLowerCase().includes(search.value.toLowerCase()),
  )

  return p
})

const handleCheckbox = (item: T) => {
  if (props.selection === 'single') {
    return emits('update', item as SelectedType, [], [])
  }

  const index = selectionIds.value.indexOf(item[props.itemKey])
  const newSelection = clone(selection.value)

  if (index === -1) {
    newSelection.push(item)
    emits('update', newSelection as SelectedType, [item], [])
  } else {
    newSelection.splice(index, 1)
    emits('update', newSelection as SelectedType, [], [item])
  }
}

const handleReset = () => {
  emits('update', [] as T[] as SelectedType, [], selection.value as T[])
}
</script>
<template>
  <Menu :width="242">
    <TextField v-model="search" placeholder="Search">
      <template #pre>
        <Icon icon="magnifyingGlass" :size="18" />
      </template>
    </TextField>
    <hr />
    <MenuItem
      v-if="props.selection && resetLabel"
      :title="resetLabel"
      @click="handleReset"
    >
      <template #icon>
        <Checkbox
          size="1"
          :is-radio="props.selection === 'single'"
          :model-value="selectionIds.length === 0"
          @input="handleReset"
        />
        <slot name="icon" :reset="true" />
      </template>
    </MenuItem>
    <MenuItem
      v-for="item in displayedItems"
      :key="item[itemKey as string]"
      :title="item[titleKey as string]"
      @click="handleCheckbox(item)"
    >
      <template #icon>
        <Checkbox
          v-if="props.selection"
          size="1"
          :is-radio="props.selection === 'single'"
          :model-value="selectionIds.includes(item[itemKey as string])"
          @update:model-value="handleCheckbox(item)"
        />
        <slot name="icon" :item="item" :reset="false" />
      </template>
    </MenuItem>

    <slot v-if="!displayedItems?.length" name="empty" :search="search" />
  </Menu>
</template>
<style module lang="scss"></style>
