import type User from '@/models/user'
import events from './events.json'
import posthog from 'posthog-js'
import type { SignupRequest } from '../api/types'

type Primitive = string | number | boolean | null

const isDevelopment = import.meta.env.MODE === 'development'

class Tracking {
  get currentIdentityId() {
    if (typeof window === 'undefined') return

    return posthog?.get_distinct_id?.()
  }

  trackIdentity = (user: User | SignupRequest) => {
    if (typeof window === 'undefined') return

    const payload = {
      name: [user?.firstName, user?.lastName].filter(Boolean).join(' '),
      email: user.email,
    }

    const identityId = (user as User)?.id || this.currentIdentityId || ''

    if (isDevelopment) {
      return console.log('🎯 Tracking Identify ', identityId, payload)
    }

    return posthog.identify(`${identityId}`, { email: user.email })
  }

  trackEvent = (
    key: keyof typeof events,
    payload?: Record<string, Primitive | Primitive[]>,
  ) => {
    if (typeof window === 'undefined') return

    if (isDevelopment) {
      return console.log(
        '🎯 Tracking event',
        key,
        this.currentIdentityId,
        payload,
      )
    }

    return posthog && posthog.capture(key, payload)
  }

  resetIdentity = () => {
    if (typeof window === 'undefined') return

    if (isDevelopment) {
      return console.log('🎯 Identity reset')
    }

    return posthog && posthog.reset()
  }
}

export const tracker = new Tracking()
