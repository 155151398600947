<script setup lang="ts">
import {
  Button,
  TextField,
  Icon,
  Checkbox,
  Skeleton,
} from '@/components/common'
import { Row, Column } from '@/components/common/layout'

type ButtonInstance = InstanceType<typeof Button>
type ButtonSize = ButtonInstance['$props']['size']

const themes: ButtonInstance['$props']['theme'][] = [
  'primary',
  'accent',
  'neutral',
  'danger',
]

const variants: ButtonInstance['$props']['variant'][] = [
  'solid',
  'soft',
  'outline',
  'ghost',
  'alternative',
]
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.section">
      <div :class="$style.header">Buttons</div>
      <div :class="$style.content">
        <div v-for="theme in themes" :key="theme">
          <Row v-for="variant in variants" :key="variant" :style="$style.row">
            <Column
              v-for="index in 5"
              :key="index"
              :span="index > 3 ? 3 : 2"
              :class="$style.column"
            >
              <Button
                :theme="theme"
                :variant="variant"
                :size="`${index}` as ButtonSize"
                icon="bell2"
                icon-right="bell2"
                >Button</Button
              >
              <Button
                :theme="theme"
                :variant="variant"
                :size="`${index}` as ButtonSize"
                icon="bell2"
              ></Button>
            </Column>
          </Row>
        </div>
      </div>
    </div>
    <div :class="$style.section">
      <div :class="$style.header">TextFields</div>
      <div :class="$style.content">
        <Row>
          <Column :span="3">
            <TextField size="1" placeholder="Input text">
              <template #pre><Icon icon="bell2" /></template>
              <template #post><Icon icon="bell2" /></template>
            </TextField>
          </Column>
          <Column :span="3">
            <TextField size="2" placeholder="Input text" />
          </Column>
          <Column :span="3">
            <TextField size="3" placeholder="Input text" />
          </Column>
          <Column :span="3">
            <TextField size="4" placeholder="Input text" />
          </Column>
        </Row>
        <Row>
          <Column :span="3">
            <TextField variant="soft" size="1" placeholder="Input text" />
          </Column>
          <Column :span="3">
            <TextField variant="soft" size="2" placeholder="Input text" />
          </Column>
          <Column :span="3">
            <TextField variant="soft" size="3" placeholder="Input text" />
          </Column>
          <Column :span="3">
            <TextField
              variant="soft"
              size="4"
              placeholder="Input text"
              hint="This is the hint text"
            />
          </Column>
        </Row>
        <Row>
          <Column :span="2">
            <Checkbox :model-value="false" />
          </Column>
          <Column :span="2">
            <Checkbox :model-value="true" />
          </Column>
          <Column :span="2">
            <Checkbox :is-radio="true" :model-value="true" />
          </Column>
        </Row>
      </div>
    </div>
    <div :class="$style.section">
      <div :class="$style.header">Loading Animation</div>
      <div :class="$style.content">
        <Skeleton :height="32" />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.container {
  width: 1120px;
  margin: 0px auto;
}

.section {
  box-shadow: 0px 0px 3px #ccc;
  margin: 40px 0px;
  .header {
    background-color: black;
    padding: 12px 20px;
    color: white;
    font-weight: bold;
    font-size: 20px;
  }

  .content {
    padding: 12px 20px;
  }
  .row {
    padding-top: 12px;
  }
  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
  }
}
</style>
