<script setup lang="ts">
import {
  AssigneeMenu,
  Button,
  DropDown,
  FileField,
  Popover,
  Popup,
  RichEditor,
} from '@/components/common'
import EmojiPicker from '@/components/common/EmojiPicker.vue'
import { useWorkspaceApi } from '@/composables/api'
import type User from '@/models/user'
import { tracker } from '@/utils'
import { brevo } from '@/utils/brevo'
import {
  CommandMenu,
  MatchType,
  type NewState,
} from '@/utils/prosemirror/CommandMenu'
import { computed, inject, reactive, ref, unref } from 'vue'
import type Message from '../models/message'
import type Shortcut from '../models/shortcut'
import type Ticket from '../models/ticket'
import ImageFile from './Composer/ImageFile.vue'
import RawFile from './Composer/RawFile.vue'
import ShortcutsMenu from './menus/ShortcutsMenu.vue'
import ManageShortcuts from './shortcuts/Manage.vue'

interface ShortcutState {
  isOpen: boolean
  range: NewState['range']
}

interface TaggingState {
  isOpen: boolean
  range: NewState['range']
}

const emit = defineEmits<{
  (e: 'send', data: Message): void
}>()

const message = ref('')
const mode = ref<'message' | 'note'>('message')
const editorRef = ref<typeof RichEditor>()
const shortcutsRef = ref<typeof DropDown>()
const taggingRef = ref<typeof DropDown>()
const manageShortcutRef = ref<typeof ShortcutsMenu>()
const editorFocused = ref<boolean>(false)

const { api, isStatus } = useWorkspaceApi()
const ticket = unref(inject<Ticket>('ticket'))

const shortcut = reactive<ShortcutState>({
  isOpen: false,
  range: null,
})

const tagging = reactive<TaggingState>({
  isOpen: false,
  range: null,
})

const attachments = reactive<{
  files: File[]
  images: File[]
}>({
  files: [],
  images: [],
})

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']

const acceptableFiles = [
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'video/mp4',
  'image/*',
]

const plugins = () => {
  return [
    CommandMenu(
      MatchType.Shortcut,
      (state) => {
        shortcut.isOpen = state.active
        shortcut.range = state.range
        shortcutsRef.value && shortcutsRef.value.performSearch(state.text)
      },
      ({ up, down, esc, enter, tab }) => {
        if (!shortcutsRef.value) return
        if (up) return shortcutsRef.value.handleUpKey()
        if (down) return shortcutsRef.value.handleDownKey()
        if (esc) return (shortcut.isOpen = false)
        if (enter) {
          shortcutsRef.value.handleEnter()
          shortcut.isOpen = false
        }
        if (tab) {
          shortcutsRef.value.handleTab()
          shortcut.isOpen = false
        }
      },
    ),
    CommandMenu(
      MatchType.Tagging,
      (state) => {
        const isNote = mode.value === 'note'
        if (!isNote && state.active) {
          return (mode.value = 'note')
        }

        tagging.isOpen = isNote && state.active
        tagging.range = isNote ? state.range : null

        if (isNote && taggingRef.value) {
          taggingRef.value.performSearch(state.text)
        }
      },
      ({ up, down, esc, enter }) => {
        if (!taggingRef.value) {
          return
        }

        if (up) return taggingRef.value.handleUpKey()
        if (down) return taggingRef.value.handleDownKey()
        if (esc) return (shortcut.isOpen = false)

        if (enter) {
          taggingRef.value.handleEnter()
          shortcut.isOpen = false
        }
      },
    ),
  ]
}
const handleShortcutSelect = (s: Shortcut) => {
  editorRef.value && editorRef.value.insertText(s.description, shortcut.range)
  shortcut.isOpen = false
  shortcutsRef.value && shortcutsRef.value.performSearch('')
}

const handleManageShortcut = () => {
  manageShortcutRef.value?.show()
  shortcut.isOpen = false
}

const handleEmoji = (emoji: any) => {
  editorRef.value && editorRef.value.insertText(emoji.native)
}

const handleFiles = (data: File | File[]) => {
  const files = data as File[]
  attachments.files = files.filter((f) => !imageTypes.includes(f.type))
  attachments.images = files.filter((f) => imageTypes.includes(f.type))
}

const handleSend = () => {
  const formData = new FormData()
  formData.append('message', message.value)
  formData.append('mode', mode.value)
  attachments.images.forEach((image) => {
    formData.append('images[]', image)
  })
  attachments.files.forEach((file) => formData.append('files[]', file))

  ticket &&
    api.inbox.sendMessage(ticket, formData).then((data) => {
      attachments.images = []
      attachments.files = []
      editorRef.value && editorRef.value.clear()
      emit('send', data as Message)
      tracker.trackEvent('inbox_activated')
      brevo.updateAttributes({ INBOX_ACTIVATED: true })
    })
}
const deleteImage = (index: number) => {
  attachments.images.splice(index, 1)
}
const deleteFile = (index: number) => {
  attachments.files.splice(index, 1)
}

const isValidMessage = computed(
  () =>
    message.value.length > 0 ||
    attachments.files.length > 0 ||
    attachments.images.length > 0,
)

const switchToNote = () => {
  mode.value = 'note'
}

const switchMode = () => {
  mode.value = mode.value === 'note' ? 'message' : 'note'
}

const handleUserTagged = (selected: User[]) => {
  const user = selected?.[0]

  if (!editorRef.value || !user?.id) {
    return
  }

  editorRef.value.insertTaggingUser(user, tagging.range)
  tagging.isOpen = false
  taggingRef.value && taggingRef.value.performSearch('')
}

defineExpose({ switchToNote })
</script>
<template>
  <div
    :class="[
      $style.composer,
      editorFocused && $style.focus,
      mode === 'note' && $style.note,
    ]"
  >
    <Popover
      placement="top-start"
      :show="tagging.isOpen"
      :class="$style.empty"
      @close-outside="tagging.isOpen = false"
    >
      <template #content>
        <AssigneeMenu ref="taggingRef" only-menu @update="handleUserTagged">
          <template #empty>
            <div :class="$style.emptyList">
              No results found

              <Button
                size="2"
                icon="crossSmall"
                variant="ghost"
                theme="neutral"
                @click.prevent="tagging.isOpen = false"
              />
            </div>
          </template>
        </AssigneeMenu>
      </template>
    </Popover>

    <RichEditor
      ref="editorRef"
      v-model="message"
      :class="$style.editor"
      :placeholder="
        mode === 'note'
          ? 'Write a note'
          : `Message ${ticket?.contact.name || ticket?.contact.email}`
      "
      :plugins="plugins()"
      @focus="editorFocused = true"
      @blur="editorFocused = false"
    />

    <div v-if="attachments.images.length > 0" :class="$style.attachments">
      <image-file
        v-for="(image, index) in attachments.images"
        :key="index"
        :file="image"
        @delete="deleteImage(index)"
      />
    </div>

    <div v-if="attachments.files.length > 0" :class="$style.attachments">
      <raw-file
        v-for="(file, index) in attachments.files"
        :key="index"
        :file="file"
        @delete="deleteFile(index)"
      />
    </div>

    <div :class="$style.controls">
      <div :class="$style.actions">
        <Popover
          placement="top-start"
          offset="60"
          :show="shortcut.isOpen"
          @close-outside="shortcut.isOpen = false"
        >
          <template #content>
            <ShortcutsMenu
              ref="shortcutsRef"
              @select="handleShortcutSelect"
              @manage="handleManageShortcut"
            />
          </template>

          <Button
            variant="ghost"
            theme="neutral"
            icon="slash"
            size="2"
            @click="shortcut.isOpen = !shortcut.isOpen"
          />
        </Popover>

        <Popover placement="top-end">
          <Button icon="emojiSmile" variant="ghost" theme="neutral" size="2" />
          <template #content="{ close }">
            <emoji-picker
              @select="
                (data) => {
                  handleEmoji(data)
                  close()
                }
              "
            />
          </template>
        </Popover>

        <file-field
          :multiple="true"
          :accept="acceptableFiles.join(',')"
          @update:model-value="handleFiles"
        >
          <Button
            as="i"
            icon="paperclip2"
            variant="ghost"
            theme="neutral"
            size="2"
          />
        </file-field>

        <Button
          as="i"
          icon="note1"
          variant="ghost"
          theme="neutral"
          size="2"
          @click="switchMode"
        />

        <Button
          v-if="mode === 'note'"
          variant="ghost"
          theme="neutral"
          icon="at"
          size="2"
          @click="tagging.isOpen = !tagging.isOpen"
        />
      </div>

      <Button
        :class="$style.send"
        :loading="isStatus('posting')"
        size="2"
        theme="neutral"
        variant="ghost"
        icon="sendMessage"
        :disabled="!isValidMessage"
        @click="handleSend"
      />
    </div>

    <Popup ref="manageShortcutRef" title="Shortcuts">
      <ManageShortcuts />
    </Popup>
  </div>
</template>
<style module lang="scss">
.composer {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-alpha-2);
  border-radius: 12px;
  padding: 16px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 32px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--neutral-alpha-3);
  }

  &.note {
    background: var(--warning-alpha-2);
  }

  .editor {
    margin-bottom: 8px;
    z-index: 1;

    :global(.rich-text) {
      padding-top: 4px;
      min-height: 60px;
    }
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 4px;
    margin-bottom: -4px;
  }
  .send {
    border-radius: 999px;
  }

  .attachments {
    z-index: 1;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
}

.emptyList {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @extend .medium-3;
}
</style>
