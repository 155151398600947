<script setup lang="ts">
import { ref } from 'vue'
import SettingsHeader from './SettingsHeader.vue'

import { useWorkspaceStore } from '@/store/workspace'
import { NotificationTypeEnum } from '@/models/notificationSetting'
import type NotificationSetting from '@/models/notificationSetting'
import { Toggle, Button } from '@/components/common'

const store = useWorkspaceStore()
import { showToast } from '@/utils'

const settings = ref([
  {
    app: 'Inbox',
    items: [
      {
        title: 'Mentions',
        subTitle: 'Get notifications when you are mentioned in chat',
        value: NotificationTypeEnum.TICKET_MENTIONED,
        email: false,
        inApp: false,
      },
      {
        title: 'Assigned to me',
        subTitle: 'Get notifications when you have new tickets assigned',
        value: NotificationTypeEnum.TICKET_ASSIGNED,
        email: false,
        inApp: false,
      },
      {
        title: 'New messages in assigned ticket',
        subTitle:
          "Get notifications when there's a new message in your assigned ticket",
        value: NotificationTypeEnum.TICKET_ASSIGNED_NEW_RESPONSE,
        email: false,
        inApp: false,
      },
      {
        title: 'New ticket',
        subTitle: "Get notifications when there's a new ticket",
        value: NotificationTypeEnum.TICKET_CREATED,
        email: false,
        inApp: false,
      },
    ],
  },
  {
    app: 'Project Management',
    items: [
      {
        title: 'Assigned to me',
        subTitle: 'Get notifications when you have new task assigned',
        value: NotificationTypeEnum.TASK_ASSIGNED,
        email: false,
        inApp: false,
      },
      {
        title: 'Mentions',
        subTitle: 'Get notifications when you are mentioned in task',
        value: NotificationTypeEnum.TASK_COMMENT_MENTIONED,
        email: false,
        inApp: false,
      },
    ],
  },
])

store.getNotificationSettings().then((response: NotificationSetting[]) => {
  settings.value = [...settings.value].map((group) => ({
    ...group,
    items: group.items.map((item) => {
      return {
        ...item,
        email: response.find((j) => item.value === j.type)?.email ?? false,
        inApp: response.find((j) => item.value === j.type)?.inApp ?? false,
      }
    }),
  }))
})

const handleSave = async () => {
  const data: object[] = []

  settings.value.forEach((group) => {
    group.items.forEach((item) => {
      data.push({
        type: item.value,
        in_app: item.inApp,
        email: item.email,
      })
    })
  })

  await store
    .saveNotificationSettings({ settings: data })
    .then(() => showToast({ text: 'Notification Settings updated!' }))
}
</script>

<template>
  <SettingsHeader
    title="Notifications"
    subtitle="Select where and when you'll be notified"
  >
    <div :class="$style.header">
      <p :class="$style.inAppLabel">In App</p>
      <p>Email</p>
    </div>
  </SettingsHeader>
  <hr :class="$style.separator" />

  <div v-for="group in settings" :key="group.app" :class="$style.group">
    <h3 class="semibold-4">{{ group.app }}</h3>

    <ul :class="$style['notification-settings']">
      <li
        v-for="item in group.items"
        :key="item.value"
        :class="$style['notification-settings__item']"
      >
        <div>
          <p class="medium-3">{{ item.title }}</p>
          <p class="regular-2">{{ item.subTitle }}</p>
        </div>

        <div :class="$style.toggle">
          <div :class="$style.inAppToggle">
            <Toggle v-model="item.inApp" size="2" />
          </div>
          <div>
            <Toggle v-model="item.email" size="2" />
          </div>
        </div>
      </li>
    </ul>
  </div>

  <Button variant="soft" theme="neutral" size="2" @click="handleSave">
    Save changes
  </Button>
</template>
<style module lang="scss">
@import url('./styles.scss');
.header {
  @extend .medium-3;
  display: flex;

  .inAppLabel {
    margin-right: 1rem;
  }
}

.group {
  margin-bottom: 2rem;

  .notification-settings {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &__item {
      padding-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .toggle {
        display: flex;

        .inAppToggle {
          margin-right: 1rem;
        }
      }
    }
  }
}
</style>
