<script setup lang="ts">
import { Checkbox, Icon, Menu, MenuItem } from '@/components/common'
import { clone } from '@/utils'
import { TicketStatus } from '../../models/ticket'

const emits = defineEmits<{
  (e: 'update', status: TicketStatus[]): void
}>()

const props = defineProps<{
  statuses: number[]
  singleSelection?: boolean
}>()

const handleCheckbox = (status: number) => {
  const selection = clone(props.statuses)
  console.log(status, selection)
  const index = selection.indexOf(status)

  if (index === -1) {
    selection.push(status)
  } else {
    selection.splice(index, 1)
  }
  emits('update', selection)
}
</script>
<template>
  <Menu :width="164">
    <MenuItem title="New" @click="handleCheckbox(TicketStatus.new)">
      <template #icon>
        <Checkbox
          :is-radio="$props.singleSelection"
          :model-value="statuses.includes(TicketStatus.new)"
        />
        <Icon icon="ticketStatusNew" :size="16" :class="$style.new" />
      </template>
    </MenuItem>
    <MenuItem title="Active" @click="handleCheckbox(TicketStatus.active)">
      <template #icon>
        <Checkbox
          :is-radio="$props.singleSelection"
          :model-value="statuses.includes(TicketStatus.active)"
        />
        <Icon icon="ticketStatusActive" :size="16" :class="$style.active" />
      </template>
    </MenuItem>
    <!-- <MenuItem title="Live">
      <template #icon>
        <Checkbox />
        <Icon icon="ticketStatusLive" :size="16" :class="$style.live" />
      </template>
    </MenuItem> -->
    <MenuItem title="Closed" @click="handleCheckbox(TicketStatus.closed)">
      <template #icon>
        <Checkbox
          :is-radio="$props.singleSelection"
          :model-value="statuses.includes(TicketStatus.closed)"
        />
        <Icon icon="ticketStatusComplete" :size="16" :class="$style.complete" />
      </template>
    </MenuItem>
  </Menu>
</template>
<style module lang="scss">
.new {
  color: var(--accent-11);
}

.active {
  color: var(--warning-alpha-9);
}

.live {
  color: var(--success-11);
}

.complete {
  color: var(--neutral-8);
}
</style>
