<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'

import { Button, Label, Popup, TextField } from '@/components/common'
import useApi from '@/composables/api'
import { showToast } from '@/utils'

const { api, isStatus } = useApi()
const popup = ref<InstanceType<typeof Popup> | null>(null)

const isCurrentVisible = ref(false)
const isNewVisible = ref(false)

const passwordForm = reactive({
  current: '',
  newPass: '',
})

const passwordFormRules = {
  current: { required },
  newPass: { required },
}

const passwordForm$ = useVuelidate(passwordFormRules, passwordForm, {
  $scope: false,
})

const handleOpen = () => {
  popup.value?.show()
}

const clear = () => {
  passwordForm.current = ''
  passwordForm.newPass = ''
}

const handleClose = () => {
  popup.value?.hide()

  clear()
}

const handleChange = async () => {
  api.account
    .updatePassword(passwordForm.current, passwordForm.newPass)
    .then(() => {
      showToast({ text: 'Password updated!' })
      handleClose()
    })
}
</script>

<template>
  <div :class="$style.wrapper" @click.prevent="handleOpen">
    <TextField value="**************" />
  </div>

  <Popup ref="popup" title="Change password" @hide="clear">
    <form @submit.prevent="handleChange">
      <div :class="$style.content">
        <div :class="$style.description">
          In order to change your password, confirm your current password and
          add a new one.
        </div>

        <Label>
          Enter your current password
          <div :class="$style.input_wrapper">
            <TextField
              v-model="passwordForm$.current.$model"
              placeholder="Current password"
              :type="isCurrentVisible ? 'text' : 'password'"
            />

            <Button
              type="button"
              variant="ghost"
              theme="neutral"
              size="1"
              :icon="isCurrentVisible ? 'eyeClosed' : 'eyeOpen'"
              @click.prevent="isCurrentVisible = !isCurrentVisible"
            />
          </div>
        </Label>

        <Label>
          Create new password
          <div :class="$style.input_wrapper">
            <TextField
              v-model="passwordForm$.newPass.$model"
              placeholder="New password"
              :type="isNewVisible ? 'text' : 'password'"
            />

            <Button
              type="button"
              variant="ghost"
              theme="neutral"
              size="1"
              :icon="isNewVisible ? 'eyeClosed' : 'eyeOpen'"
              @click.prevent="isNewVisible = !isNewVisible"
            />
          </div>
        </Label>
      </div>

      <div :class="$style.actions">
        <Button
          type="submit"
          size="3"
          variant="solid"
          theme="neutral"
          :loading="isStatus('posting')"
          :disabled="passwordForm$.$invalid"
        >
          Change password
        </Button>
      </div>
    </form>
  </Popup>
</template>

<style module lang="scss">
.wrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    inset: 0;
    cursor: pointer;
  }
}

.input_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  div {
    width: 100%;
  }

  button {
    position: absolute;
    right: 8px;

    i {
      width: 24px;
      height: 24px;
      color: var(--neutral-alpha-11);
    }
  }
}

.content {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 52px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  .description {
    @extend .regular-3;

    color: var(--neutral-11);
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
}
</style>
