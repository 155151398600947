import type { AxiosInstance } from 'axios'
import type { AppKey } from '@/models/app'
import type { Tag } from '@/models/tag'

export class TagService {
  constructor(private instance: AxiosInstance) {}

  createTag = (name: string, namespace: AppKey) =>
    this.instance
      .post<Tag>(`/tags`, { name, namespace })
      .then((response) => response.data)

  loadTags = (namespace: AppKey) =>
    this.instance
      .get<Tag[]>(`/tags`, { params: { namespace } })
      .then((response) => response.data)
}
