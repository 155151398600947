<script setup lang="ts">
import { toRefs } from 'vue'
import type Message from '../../models/message'
import { MessageType } from '../../models/message'
import { useWorkspaceStore } from '@/store/workspace'

const props = defineProps<{
  message: Message
}>()
const { message } = toRefs(props)
const store = useWorkspaceStore()

const { messageType, attachments, userId } = message.value
const text = () => {
  if (messageType === MessageType.operatorAssigned) {
    return `assigned ${findReferenceMember(attachments.userId || 0, userId || 0)}`
  } else if (messageType === MessageType.operatorRemoved) {
    return `unassigned ${findReferenceMember(attachments.userId || 0, userId || 0)}`
  } else if (messageType === MessageType.chatEnded) {
    return `closed this ticket`
  } else if (messageType === MessageType.chatReopened) {
    return `reopened this ticket`
  }
}

const findReferenceMember = (id: number, actorId: number) => {
  if (id === actorId) return 'themself'
  const member = store.members.find((member) => member.id === id)
  return member ? member.name : 'someone'
}
</script>
<template>
  <p :class="$style.content">{{ text() }}</p>
</template>
<style module lang="scss">
.content {
  color: var(--neutral-11);
  @extend .regular-3;
}
</style>
