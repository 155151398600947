<script setup lang="ts">
import {
  Checkbox,
  Code,
  TextField,
  Toggle,
  Button,
  FileField,
  Popover,
  Avatar,
} from '@/components/common'
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { useWorkspaceStore } from '@/store/workspace'
import { computed, reactive, ref, watch } from 'vue'
import WidgetPreview from './components/WidgetPreview.vue'
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker'
import type { WidgetSettings } from './types'

const workspaceStore = useWorkspaceStore()
const state = reactive<WidgetSettings>({
  enabled: false,
  title: '',
  description: '',
  position: 'right',
  color: '#000',
  message: '',
})

const file = ref<File>()
const filePreview = ref<string>()

workspaceStore.api.inbox.getWidget().then((data) => {
  data && Object.assign(state, data)
})

const handleUpdate = () => {
  const data = new FormData()
  Object.keys(state).forEach((key) => {
    const value = state[key as keyof WidgetSettings]
    value && data.append(key, `${value}`)
  })
  file.value && data.append('logoFile', file.value)
  workspaceStore.api.inbox.updateWidget(data)
}

const handleLogo = async (selected: File | File[]) => {
  file.value = selected as File
  const reader = new FileReader()
  reader.readAsDataURL(selected as File)
  reader.onloadend = function () {
    filePreview.value = `${this.result}`
  }
}

watch(() => state.enabled, handleUpdate)

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspaceStore.workspace?.title} Inbox`,
    url: { name: 'HDInbox' },
  },
  {
    title: 'Widget',
  },
])
</script>
<template>
  <Page :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
    </template>

    <div :class="$style.container">
      <div :class="$style.controls">
        <h2 :class="$style.title">Inbox widget</h2>
        <p :class="$style.subtitle">
          Interactive widget on your website to interact with your customers
          with ease!
        </p>
        <hr />
        <label :class="$style.control">
          <div :class="$style.label">
            Enable widget
            <p>Make widget active</p>
          </div>
          <Toggle v-model="state.enabled" size="2" />
        </label>
        <div :class="[!state.enabled && $style.disabled]">
          <hr />
          <div :class="$style.section">
            <h3>Customize your widget</h3>
            <label :class="$style.control">
              <div :class="$style.label">Title</div>
              <TextField
                v-model="state.title"
                size="2"
                :class="$style.textField"
                placeholder="Add title"
              />
            </label>
            <label :class="$style.control">
              <div :class="$style.label">Description</div>
              <TextField
                v-model="state.description"
                size="2"
                :class="$style.textField"
                placeholder="Add description"
              />
            </label>
            <label :class="$style.control">
              <div :class="$style.label">Custom chat logo</div>
              <div :class="$style.logoSection">
                <Avatar
                  v-if="filePreview || state.logoUrl"
                  :src="filePreview || state.logoUrl"
                  :size="40"
                />
                <FileField
                  :multiple="false"
                  accept="image/*"
                  @update:model-value="handleLogo"
                >
                  <Button as="span" size="2" variant="soft" theme="neutral"
                    >Upload new</Button
                  >
                </FileField>
              </div>
            </label>
            <div :class="$style.control">
              <div :class="$style.label">Widget position</div>
              <div :class="$style.radio">
                <Checkbox
                  :model-value="state.position === 'left'"
                  :is-radio="true"
                  size="2"
                  @update:model-value="state.position = 'left'"
                  >Left</Checkbox
                >
                <Checkbox
                  :model-value="state.position === 'right'"
                  :is-radio="true"
                  size="2"
                  @update:model-value="state.position = 'right'"
                  >Right</Checkbox
                >
              </div>
            </div>
            <label :class="$style.control">
              <div :class="$style.label">Primary color</div>
              <TextField
                v-model="state.color"
                size="2"
                :class="$style.textField"
                maxlength="7"
              >
                <template #post>
                  <Popover>
                    <template #content>
                      <Vue3ColorPicker
                        v-model="state.color"
                        mode="solid"
                        type="HEX"
                        :show-color-list="false"
                        :show-eye-drop="false"
                        :show-input-menu="false"
                        :show-alpha="false"
                      />
                    </template>
                    <div :class="$style.colorPreview">
                      <span />
                    </div>
                  </Popover>
                </template>
              </TextField>
            </label>
            <Button
              :class="$style.save"
              :loading="workspaceStore.isStatus('posting')"
              size="2"
              variant="soft"
              theme="neutral"
              @click="handleUpdate"
              >Save Changes</Button
            >
          </div>
          <hr />
          <div :class="$style.section">
            <h3>Welcome message</h3>
            <label :class="[$style.control, $style.vertical]">
              <div :class="$style.label">Welcome Message</div>
              <TextField
                v-model="state.message"
                size="2"
                placeholder="Add welcome message"
              />
            </label>
            <Button
              :class="$style.save"
              :loading="workspaceStore.isStatus('posting')"
              size="2"
              variant="soft"
              theme="neutral"
              @click="handleUpdate"
              >Save Changes</Button
            >
          </div>
          <hr />
          <div :class="$style.section">
            <h3>Installation</h3>
            <p :class="$style.description">
              To make Web Widget visible to customers, update the HTML of every
              page you want it on. Just paste this code snippet in before the
              closing body tag.
            </p>
            <Code :code="workspaceStore.embedCode" />
          </div>
        </div>
      </div>
      <div :class="$style.preview">
        <WidgetPreview :config="state" :logo-preview="filePreview" />
      </div>
    </div>
  </Page>
</template>
<style module lang="scss">
.container {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  height: 100%;

  hr {
    margin: 24px 0px;
  }
}
.controls {
  width: 556px;
  height: 100%;
  overflow: auto;
  min-height: 0;
  padding: 32px 0px;

  .title {
    @extend .semibold-6;
  }
  .subtitle {
    @extend .regular-3;
    color: var(--neutral-11);
    margin-top: 2px;
  }
  .save {
    align-self: flex-start;
  }
  .section {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    h3 {
      @extend .semibold-4;
    }

    .description {
      @extend .regular-3;
    }
  }
  .control {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .label {
      flex-grow: 1;
      @extend .medium-3;

      p {
        @extend .regular-2;
        margin-top: 4px;
        color: var(--neutral-11);
      }
    }

    .textField {
      width: 50%;
    }

    .radio {
      @extend .medium-3;
      display: flex;
      column-gap: 32px;
    }

    .logoSection {
      display: flex;
      align-items: center;
      column-gap: 12px;
      height: 40px;
    }

    &.vertical {
      flex-direction: column;
      align-items: stretch;
      row-gap: 8px;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.colorPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--neutral-5);
  padding-left: 8px;
  cursor: pointer;

  span {
    width: 20px;
    height: 20px;
    background-color: v-bind('state.color');
    display: inline-block;
    border-radius: 2px;
  }
}
.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 556px;
  border-radius: 12px;
  background: var(--panel-3);
  margin: 32px 0px;
}
</style>
