<script setup lang="ts">
import { inject } from 'vue'
import { PopoverKey } from '../types'

const emit = defineEmits<{
  (e: 'click'): void
}>()

const props = defineProps<{
  title: string
  subtitle?: string
  separator?: boolean
  highlighted?: boolean
  hideOnClick?: boolean
}>()
const hide = inject(PopoverKey.hide)

const handleClick = () => {
  props.hideOnClick && hide && hide()
  emit('click')
}
</script>
<template>
  <li
    :class="[$style.container, highlighted && $style.highlighted]"
    @click="handleClick"
  >
    <slot name="icon" />
    <div :class="$style.content">
      <p>{{ title }}</p>
      <p v-if="subtitle" :class="$style.subtitle">{{ subtitle }}</p>
    </div>
    <slot name="action" />
  </li>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 6px 8px;
  border-radius: 8px;
  transition: 0.15s background-color;
  cursor: pointer;

  &:hover {
    background-color: var(--neutral-alpha-3);
  }

  &.highlighted {
    background-color: var(--neutral-alpha-3);
  }
}

.content {
  @extend .medium-3;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 2px;
  min-width: 0;
}

.subtitle {
  @extend .regular-2;
  color: var(--neutral-11);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
