<script setup lang="ts">
import { inject, type Ref } from 'vue'
import type Ticket from '../models/ticket'
import { ISODate } from '@/utils/helpers'
import { Avatar, Button, Popover, AssigneeBadge } from '@/components/common'
import TicketStatus from './TicketStatus.vue'
import type User from '@/models/user'
import { useInboxStore } from '../store'
import AssigneeMenu from '@/components/common/AssigneeMenu.vue'
import { renderMarkdown } from '@/utils/inbox'
import { showToast } from '@/utils'

defineEmits<{ 'create-note': [] }>()

const ticket = inject<Ref<Ticket>>('ticket')

const store = useInboxStore()

const handleAssigneeUpdate = (users: User[]) => {
  ticket && store.updateAssignee(ticket.value, users)
}

const handleSelectNote = (id: number) => {
  const noteId = `thread-note-${id}`

  const el = document?.getElementById(noteId)

  if (!el) {
    return
  }

  el.focus({ preventScroll: true })
  el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
}

const handleCopy = () => {
  navigator.clipboard.writeText(ticket?.value.contact.email ?? '').then(() => {
    showToast({ text: 'Email copied to clipboard', type: 'success' })
  })
}
</script>
<template>
  <div :class="$style.wrapper">
    <div v-if="ticket" :class="[$style.actions, $style.container]">
      <TicketStatus :ticket="ticket" />
      <Popover>
        <template #content
          ><AssigneeMenu
            :assignees="ticket.operators"
            @update="handleAssigneeUpdate"
        /></template>
        <AssigneeBadge :users="ticket.operators" />
      </Popover>
    </div>
    <div v-if="ticket" :class="[$style.container, $style.meta]">
      <label>
        Ticket from
        <div :class="$style.value">
          <Avatar
            :size="16"
            :title="ticket.contact.name || ticket.contact.email"
          />
          {{ ticket.contact.name || ticket.contact.email }}
        </div>
      </label>
      <label :class="$style.withAction">
        Email
        <div :class="$style.value">
          <span>{{ ticket.contact.email }}</span>

          <Button
            size="1"
            variant="ghost"
            theme="neutral"
            icon="squareBehindSquare1"
            @click="handleCopy"
          />
        </div>
      </label>
      <label>
        Date Issued
        <div :class="$style.value">
          {{
            ISODate(ticket.createdAt, {
              format: 'LLL dd',
            })
          }}
        </div>
      </label>
    </div>
    <div v-if="ticket" :class="[$style.container, $style.notes]">
      <div :class="$style.head">
        <div :class="$style.label">Notes</div>

        <Button
          variant="ghost"
          theme="neutral"
          size="1"
          icon="plusSmall"
          @click="() => $emit('create-note')"
        />
      </div>

      <template v-if="ticket?.notes?.length">
        <div
          v-for="(note, index) of ticket.notes"
          :key="note.id"
          :class="$style.note"
          @click="() => handleSelectNote(note.id)"
        >
          <div :class="$style.index">{{ index + 1 }}</div>

          <div
            :class="$style.message"
            v-html="renderMarkdown(note.message, false)"
          />
        </div>
      </template>

      <div v-else :class="$style.empty">There&apos;s no notes yet</div>
    </div>
  </div>
</template>
<style module lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 56px);
}
.actions {
  padding: 16px 12px;
  border-bottom: 1px solid var(--neutral-alpha-4);
  row-gap: 12px;
}
.meta {
  padding: 16px;
  row-gap: 16px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  label {
    @extend .medium-2;
    color: var(--neutral-11);

    .value {
      @extend .medium-3;
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin-top: 8px;
      color: var(--neutral-12);
    }

    &.withAction {
      width: 100%;

      .value {
        justify-content: space-between;

        span {
          flex: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        button {
          color: var(--neutral-12);
        }
      }
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notes {
  gap: 12px;
  padding: 16px;
  overflow: auto;

  .head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--neutral-11);

    @extend .medium-3;
  }

  .empty {
    @extend .medium-3;
    color: var(--neutral-8);
  }

  .note {
    display: flex;
    gap: 12px;
    cursor: pointer;

    .index {
      @extend .medium-2;

      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: var(--neutral-alpha-3);
      color: var(--neutral-alpha-11);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .message {
      @extend .medium-3;
    }
  }
}
</style>
