<script setup lang="ts"></script>
<template>
  <li :class="$style.container">
    <div :class="$style.line" />
    <span :class="$style.date"><slot></slot></span>
  </li>
</template>
<style module lang="scss">
.container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 24px;
}

.date {
  @extend .medium-2;
  color: var(--neutral-alpha-10);
  padding: 4px 12px;
  background-color: var(--background-1);
  z-index: 1;
}

.line {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 0.5px);
  background: var(--neutral-alpha-4);
  height: 1px;
}
</style>
