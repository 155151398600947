<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  Icon,
  Avatar,
  Button,
  Popover,
  AssigneeMenu,
  ResourceSelector,
} from '@common/index.ts'

import { useWorkspaceApi } from '@/composables/api'

import User from '@/models/user.ts'
import { Task } from '@modules/tasks/models/task.ts'
import { TaskUpdateRequest } from '@modules/tasks/api/types.ts'
import StageIndicator from '@modules/tasks/components/StageIndicator.vue'
import { TaskPriorityMenu } from '@modules/tasks/components/constants.ts'
import PriorityIndicator from '@modules/tasks/components/PriorityIndicator.vue'
import router from '@/routes.ts'

const props = defineProps<{ task: Task }>()

const task = ref<Task>(props.task)
const { api } = useWorkspaceApi()

const taskNumber = computed(() => {
  return `${task.value.team?.code}-${task.value.number}`
})

const taskPriority = computed(() => {
  return TaskPriorityMenu.find((p) => p.id === task?.value?.priority)
})

const handleUpdate = (data: Partial<TaskUpdateRequest>) => {
  api.task
    .updateTask(data, task.value.id)
    .then((data: Task) => (task.value = data))
}

const onClick = () => {
  router.push({
    name: 'TasksTask',
    params: { taskNumber: task.value.id, teamId: task.value.teamId },
  })
}

</script>

<template>
    <li :class="$style.item">
      <div :class="$style.info">
        <Popover>
          <template #content>
            <ResourceSelector
              :items="task.team?.statuses ?? []"
              :selected="task.status"
              item-key="id"
              title-key="name"
              selection="single"
              @update="(data) => handleUpdate({ statusId: data.id })"
            />
          </template>
          <Button size="2" variant="ghost" theme="neutral">
            <template #icon>
              <StageIndicator
                :size="16"
                :stage="task.status?.order"
                :total-stages="task.team?.statuses?.length ?? 1"
              />
            </template>
          </Button>
        </Popover>

        <Popover>
          <template #content>
            <ResourceSelector
              :items="TaskPriorityMenu"
              :selected="taskPriority"
              item-key="id"
              title-key="name"
              selection="single"
              @update="(data) => handleUpdate({ priority: data.id })"
            >
              <template #icon="{ item }">
                <PriorityIndicator v-if="item" :priority="item.id" />
              </template>
            </ResourceSelector>
          </template>
          <Button
            icon="unassignedIcon"
            size="2"
            variant="ghost"
            theme="neutral"
            :class="$style.button"
          >
            <template #icon>
              <PriorityIndicator :priority="taskPriority?.id || 0" :size="16" />
            </template>
          </Button>
        </Popover>

        <p id="subtask-number" :class="$style.number" @click="onClick">
          {{ taskNumber }}
        </p>
        <p id="subtask-title" :class="$style.title" @click="onClick">
          {{ task.title }}
        </p>
      </div>

      <Popover>
        <template #content>
          <AssigneeMenu
            :assignees="
              task?.assignees?.length ? [task?.assignees[0]] : []
            "
            :single-selection="true"
            @update="
              (data: User[]) =>
                handleUpdate({ assignees: data.map((user) => user.id) })
            "
          />
        </template>
        <Button size="2" variant="ghost" theme="neutral" :class="$style.button">
          <template #icon>
            <Avatar
              v-if="task?.assignees?.length"
              :title="task.assignees[0].name"
              :src="task.assignees[0].avatar"
              :size="16"
            />
            <Icon v-else icon="unassignedIcon" :size="16" />
          </template>
        </Button>
      </Popover>
    </li>
</template>

<style module lang="scss">
.item {
  display: flex;
  padding: 8px 0;
  cursor: grab;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--neutral-alpha-3);
  }

  .info {
    display: flex;
    align-items: center;
    width: 100%;

    .number {
      @extend .regular-3;
      margin-left: 0.5rem;
    }

    .title {
      @extend .medium-3;
      margin-left: 0.5rem;
      display: flex;
      flex: 1 1 0;
    }
  }
}
</style>
