<script setup lang="ts">
import { toRefs } from 'vue'
import TextField from './TextField.vue'
import Popover from './Popover/index.vue'
import Menu from './Menu/Menu.vue'
import MenuItem from './Menu/MenuItem.vue'
import Button from './Button.vue'
import type { SelectItem } from './types'

const emit = defineEmits<{
  (e: 'update:modelValue', data: unknown): void
}>()

const props = defineProps<{
  options: SelectItem[]
  size: '1' | '2' | '3' | '4'
  modelValue?: SelectItem
  itemKey?: string
  valueKey?: string
  searchable?: boolean
  placeholder?: string
}>()
const { size, options, modelValue, itemKey, valueKey, searchable } =
  toRefs(props)

const handleSelect = (value: SelectItem) => {
  emit('update:modelValue', value)
}
</script>
<template>
  <Popover class="select-menu" placement="bottom-start">
    <template #content="{ close }">
      <Menu>
        <MenuItem
          v-for="(option, index) in options"
          :key="index"
          :title="(itemKey ? option[itemKey] : option.label) as string"
          :highlighted="
            (valueKey ? option[valueKey] : option.value) ===
            (valueKey ? modelValue?.[valueKey] : modelValue?.value)
          "
          @click="
            () => {
              handleSelect(option)
              close()
            }
          "
        />
      </Menu>
    </template>
    <TextField
      :value="(itemKey ? modelValue?.[itemKey] : modelValue?.label) as string"
      :class="$style.field"
      :placeholder="placeholder"
      :size="size"
      :readonly="!searchable"
    >
      <template #post>
        <Button
          size="1"
          variant="ghost"
          theme="neutral"
          icon="chevronDownSmall"
        />
      </template>
    </TextField>
  </Popover>
</template>
<style lang="scss">
.select-menu {
  width: 100%;
  position: relative;

  .popper {
    width: 100%;
  }
}
</style>
<style module lang="scss">
.field {
  cursor: pointer;
}
</style>
