<script setup lang="ts">
import { ref, toRefs, watch } from 'vue'

import { Button, Popover } from '@/components/common'

import { TicketStatus } from '../../models/ticket'
import StatusMenu from '../menus/StatusMenu.vue'

const props = defineProps<{ selectedIds: number[] }>()
const emit = defineEmits<{ 'status-selected': [value: TicketStatus] }>()

const { selectedIds } = toRefs(props)
const selectedValue = ref<TicketStatus[]>([])

const handleStatus = (selected: TicketStatus[], close: () => void) => {
  const target = selected[selected.length - 1]
  const value = target !== undefined ? [target] : []

  selectedValue.value = value

  close?.()
  emit('status-selected', target)
}

watch(
  selectedIds,
  () => {
    selectedValue.value = []
  },
  { deep: true },
)
</script>

<template>
  <Popover placement="bottom-end" @click.prevent>
    <template #content="{ close }">
      <div :class="$style.popout">
        <div :class="$style.label">
          Change status for ({{ selectedIds.length }}) tickets
        </div>

        <StatusMenu
          single-selection
          :statuses="selectedValue"
          :class="$style.menu"
          @update="(selected) => handleStatus(selected, close)"
        />
      </div>
    </template>

    <Button
      size="2"
      variant="ghost"
      theme="neutral"
      :class="$style.link"
      icon="circleHalfFill"
    >
      Status
    </Button>
  </Popover>
</template>

<style module lang="scss">
.popout {
  padding: 8px;
}

.link {
  padding: 0;
  color: var(--neutral-alpha-11);

  &:hover {
    color: var(--neutral-alpha-12);
    background-color: transparent;
  }
}

.label {
  @extend .medium-2;

  color: var(--neutral-alpha-10);
  padding: 4px 8px;
  text-align: left;
}

.menu {
  text-align: left;
  padding: 8px 0 0;
}
</style>
