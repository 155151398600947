<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import { Button, Label, TextField } from '@/components/common'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import { useApplicationStore } from '@/store'
import { useWorkspaceStore } from '@/store/workspace'
import { tracker } from '@/utils'

import SuccessIcon from '../auth/SuccessIcon.vue'
import { brevo } from '@/utils/brevo'

const router = useRouter()

const store = useApplicationStore()
const { isStatus, createWorkspace, setCurrentWorkspace } = useWorkspaceStore()

const success = ref(false)

const email = store.user?.email

const form = reactive({
  title: '',
})

const formRules = {
  title: { required },
}

const form$ = useVuelidate(formRules, form)

const handleSubmit = async () => {
  success.value = true
  const res = await createWorkspace({ title: form.title })

  if (!res?.id) {
    success.value = false
    return
  }

  tracker.trackEvent('successful_signup')
  brevo.updateAttributes({ SUCCESSFUL_SIGNUP: true })

  setCurrentWorkspace(res.id)
  router.push({
    name: 'OnboardingMarketplace',
    params: { workspaceId: res.id },
  })
}
</script>

<template>
  <auth-layout
    v-if="!success"
    title="Create a new workspace"
    subtitle="Workspaces are a shared environment where teams can work together."
  >
    <template #extra>
      <div :class="$style.extra">
        <div :class="$style.email">{{ email }}</div>

        <Button
          size="2"
          variant="outline"
          theme="neutral"
          @click="store.signOut"
        >
          Logout
        </Button>
      </div>
    </template>

    <form @submit.prevent="handleSubmit">
      <Label :class="$style.label">
        Workspace name
        <TextField
          v-model="form$.title.$model"
          size="2"
          placeholder="My workspace"
        />
      </Label>

      <div :class="$style.actions">
        <Button
          size="3"
          variant="solid"
          theme="neutral"
          type="submit"
          :disabled="form$.$invalid || isStatus('posting')"
        >
          Create workspace
        </Button>
      </div>
    </form>

    <hr :class="$style.divider" />

    <div :class="$style.footer">
      If you already have someone from your team with workspace, you can ask for
      an invite link to join existing workspace.
    </div>
  </auth-layout>

  <auth-layout v-else>
    <div :class="$style.success">
      <SuccessIcon icon="stopwatch" />

      <div :class="$style.labels">
        <div :class="$style.head">Almost there</div>
        <div :class="$style.subtitle">
          Please stand by while we're building your personal workspace.
        </div>
      </div>
    </div>
  </auth-layout>
</template>

<style module lang="scss">
@import '../auth/styles.scss';

.divider {
  margin-top: 24px;
}

.extra {
  display: flex;
  align-items: center;
  gap: 12px;

  .email {
    @extend .medium-3;
  }
}

.footer {
  @extend .regular-2;

  text-align: center;
  color: var(--neutral-11);
  margin-top: 32px;
}

.head {
  @extend .semibold-4;
}

.subtitle {
  @extend .regular-4;

  color: var(--neutral-11);
}
</style>
