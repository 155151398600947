<script setup lang="ts">
import { Badge, Button, Icon, Popup, TextField } from '@/components/common'
import ConnectSteps from '@/modules/inbox/components/ConnectSteps.vue'
import { showToast, tracker } from '@/utils'
import { computed, ref } from 'vue'
import CustomDomain from './CustomDomain.vue'
import { useInboxStore } from '@/modules/inbox/store'
import { brevo } from '@/utils/brevo'

const store = useInboxStore()

const popup = ref<typeof Popup>()
const domainPopup = ref<typeof Popup>()

const handleStatus = () => {
  store.checkDomain()?.then((data) => {
    if (!data.status) {
      showToast({ type: 'error', text: 'DNS records not found' })
      return
    }

    tracker.trackEvent('inbox_custom_domain')
    brevo.updateAttributes({ INBOX_CUSTOM_DOMAIN: true })
  })
}

const isForwardingActive = computed(() => store.tickets.length > 0)

const handleCopy = () => {
  navigator.clipboard.writeText(store.forwardEmail).then(() => {
    showToast({ text: 'Email copied to clipboard', type: 'success' })
  })
}
</script>

<template>
  <div :class="$style.section">
    <div :class="$style.title">Mailbox forwarding</div>

    <div :class="$style.row">
      <div :class="$style.label">
        Forward address
        <Badge v-if="isForwardingActive" size="2" :class="$style.badge"
          >Active</Badge
        >
      </div>
      <TextField
        v-if="!isForwardingActive"
        readonly="true"
        size="2"
        :class="$style.forward"
        :value="store.forwardEmail"
      >
        <template #post>
          <Button
            icon="squareBehindSquare1"
            size="3"
            variant="ghost"
            theme="neutral"
            @click.prevent="handleCopy"
          />
        </template>
      </TextField>

      <Button
        v-else
        size="2"
        variant="soft"
        theme="neutral"
        @click="popup?.show()"
      >
        Set up email forwarding
      </Button>
    </div>

    <div v-if="!isForwardingActive" :class="$style.row">
      <div :class="$style.label">Need help with set up?</div>

      <Button size="2" variant="soft" theme="neutral" @click="popup?.show()">
        Set up email forwarding
      </Button>
    </div>

    <div v-if="isForwardingActive" :class="$style.row">
      <div :class="$style.label">
        Custom domain <Badge size="2" :class="$style.badge">Active</Badge>
      </div>

      <Button
        size="2"
        variant="soft"
        theme="neutral"
        @click="domainPopup?.show()"
      >
        View DNS Records
      </Button>
    </div>
    <div v-else :class="$style.alert">
      <Icon icon="sparklesTwo2" :size="20" />
      <p>Increase your customers trust by sending email from your own domain</p>
      <Button
        size="2"
        variant="solid"
        theme="neutral"
        @click="domainPopup?.show()"
        >Set-up custom domain</Button
      >
    </div>
  </div>

  <Popup ref="popup" title="Set up email forwarding">
    <ConnectSteps />
  </Popup>
  <Popup ref="domainPopup" :persistent="true" title="Custom domain">
    <div :class="['popup-content', $style.customDomainModal]">
      <CustomDomain />
    </div>
    <div :class="['popup-content', $style.actions]">
      <div :class="$style.btns">
        <Button
          size="3"
          variant="soft"
          theme="neutral"
          :loading="store.isStatus('loading')"
          @click="handleStatus"
          >Check Status</Button
        >
        <Button
          size="3"
          variant="solid"
          theme="neutral"
          @click="domainPopup?.hide()"
          >Save Changes</Button
        >
      </div>
    </div>
  </Popup>
</template>

<style module lang="scss">
@import './base.scss';
.actions {
  border-top: 1px solid var(--neutral-alpha-4);
  .btns {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
  }
}

.customDomainModal {
  width: 860px;
}

.forward {
  padding-right: 0;
}

.alert {
  @extend .medium-3;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 12px 12px 12px 18px;
  border-radius: 12px;
  color: var(--accent-12);
  background-color: var(--accent-alpha-3);

  p {
    flex: 1;
  }

  i {
    flex-shrink: 0;
    color: var(--accent-11);
  }
}

.badge {
  background-color: var(--success-alpha-3);
  color: var(--success-alpha-12);
}
</style>
