<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  mobile?: number // number of columns to span on mobile
  tablet?: number // number of columns to span on tablet
  span: number // number of columns to span large screens
}>()
const { span } = toRefs(props)
</script>
<template>
  <div :class="$style[`col-${span}`]">
    <slot />
  </div>
</template>
<style module lang="scss">
$singleColumn: calc(100% / 12);
@for $i from 1 through 12 {
  .col-#{$i} {
    width: ($i * $singleColumn);
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
