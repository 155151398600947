<script setup lang="ts">
import {
  AssigneeMenu,
  Icon,
  Menu,
  MenuItem,
  ResourceSelector,
} from '@/components/common'
import { ref, watch, inject, type Ref, toRefs } from 'vue'
import type User from '@/models/user'
import type { Tag } from '@/models/tag'
import { DueDateItem, useTasksTeamStore } from '../teamStore'
import { useTasksStore } from '../store'
import { TaskStatus } from '../models/taskStatus'
import { DueDateMenu, TaskPriorityMenu } from './constants'
import PriorityIndicator from './PriorityIndicator.vue'
import StageIndicator from './StageIndicator.vue'
import { TaskPriorityItem, TaskSearch } from '../types'
import { Team } from '../models/team'

const { search } = defineProps<{
  search: TaskSearch
  mode: 'team' | 'mine'
}>()
const emit = defineEmits<{
  (e: 'update', data: TaskSearch): void
}>()

const taskStore = useTasksStore()
const { tags, teams } = toRefs(taskStore)
tags.value.length === 0 && taskStore.loadTags()
teams.value.length === 0 && taskStore.loadTeams()

const store = useTasksTeamStore()
const { statuses } = toRefs(store)

const menu = ref<
  'assignee' | 'priority' | 'dueDate' | 'status' | 'tags' | 'teams' | undefined
>(undefined)
const isOpen = inject<Ref<boolean>>('isOpen')

watch(
  () => isOpen?.value,
  () => {
    menu.value = undefined
  },
)

const handleAssignee = (data: User[]) => {
  emit('update', {
    ...search,
    assignee: data.length ? data : undefined,
  })
}

const handleStatus = (data: TaskStatus[]) => {
  emit('update', {
    ...search,
    status: data.length ? data : undefined,
  })
}

const handlePriority = (data: TaskPriorityItem[]) => {
  emit('update', {
    ...search,
    priority: data.length ? data : undefined,
  })
}

const handleDueDate = (data: DueDateItem) => {
  emit('update', {
    ...search,
    dueDate: data,
  })
}

const handleTeam = (data: Team) => {
  emit('update', {
    ...search,
    team: data,
  })
}

const handleTag = (data: Tag[]) => {
  emit('update', {
    ...search,
    tags: data.length ? data : undefined,
  })
}
</script>

<template>
  <AssigneeMenu
    v-if="menu === 'assignee' && mode === 'team'"
    :assignees="search.assignee"
    @update="handleAssignee"
  />
  <ResourceSelector
    v-else-if="menu === 'teams' && mode === 'mine'"
    :items="teams"
    :selected="search.team"
    item-key="id"
    title-key="name"
    selection="single"
    @update="handleTeam"
  />
  <ResourceSelector
    v-else-if="menu === 'priority'"
    :items="TaskPriorityMenu"
    :selected="search.priority"
    item-key="id"
    title-key="name"
    selection="multi"
    @update="handlePriority"
  >
    <template #icon="{ item }">
      <PriorityIndicator v-if="item" :priority="item.id" />
    </template>
  </ResourceSelector>
  <ResourceSelector
    v-else-if="menu === 'dueDate'"
    :items="DueDateMenu"
    :selected="search.dueDate"
    item-key="date"
    title-key="label"
    selection="single"
    @update="handleDueDate"
  />
  <ResourceSelector
    v-else-if="menu === 'status'"
    :items="statuses"
    :selected="search.status"
    item-key="id"
    title-key="name"
    selection="multi"
    @update="handleStatus"
  >
    <template #icon="{ item }">
      <StageIndicator
        :size="16"
        :stage="item?.order || 0"
        :total-stages="statuses.length"
      />
    </template>
  </ResourceSelector>
  <ResourceSelector
    v-else-if="menu === 'tags'"
    :items="tags"
    :selected="search.tags"
    item-key="id"
    title-key="name"
    selection="multi"
    @update="handleTag"
  />
  <Menu v-else :width="164">
    <MenuItem
      v-if="mode === 'team'"
      title="Assignee"
      @click="menu = 'assignee'"
    >
      <template #icon>
        <Icon icon="peopleCircle" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Priority" @click="menu = 'priority'">
      <template #icon>
        <Icon icon="priority3" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem v-if="mode === 'mine'" title="Team" @click="menu = 'teams'">
      <template #icon>
        <Icon icon="arCube1" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem v-if="mode === 'team'" title="Status" @click="menu = 'status'">
      <template #icon>
        <Icon icon="circleHalfFill" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Due Date" @click="menu = 'dueDate'">
      <template #icon>
        <Icon icon="calendar2" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Tags" @click="menu = 'tags'">
      <template #icon>
        <Icon icon="hashtag" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
  </Menu>
</template>

<style module lang="scss">
.icon {
  color: var(--neutral-11);
}
</style>
