<script setup lang="ts">
import { type LaravelError } from '@/composables/api'
import { AxiosError } from 'axios'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  success?: string
  error?: string
}>()
const { success, error } = toRefs(props)

const message = computed(() => {
  if (error?.value) {
    if (typeof error.value === 'string') return error.value
    const { response } = error.value as AxiosError<LaravelError>
    if (response) {
      const { message, errors } = response.data
      if (Object.keys(errors).length > 0) {
        return Object.values(errors)[0][0]
      }
      return message
    }
  }
  return success?.value
})
</script>
<template>
  <div
    v-if="success || error"
    :class="[$style.base, success ? $style.success : $style.error]"
  >
    <slot name="icon" />
    {{ message }}
  </div>
</template>
<style module lang="scss">
.base {
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.success {
  background-color: #f0fdf4;
  color: #166534;
}
.error {
  background-color: #fef2f2;
  color: #f87171;
}
</style>
