import type { SelectItem } from '@/components/common'

export const providers: SelectItem[] = [
  {
    label: 'Gmail',
    value: 'gmail',
    tutorial:
      'https://www.youtube.com/watch?v=zx_B_N__fcA&ab_channel=How-ToAuthority',
  },
  {
    label: 'Outlook',
    value: 'outlook',
    tutorial:
      'https://www.youtube.com/watch?v=qqk4Zxxo_VY&ab_channel=GuideRealm',
  },
  {
    label: 'Yahoo',
    value: 'yahoo',
    tutorial:
      'https://www.youtube.com/watch?v=Psf-M5lPTqY&ab_channel=TrueAmerican',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
