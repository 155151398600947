<script setup lang="ts">
import { toRefs } from 'vue'
import { type File } from '../../models/message'
import Files from './Files.vue'
import Images from './Images.vue'

interface Attachment {
  files: File[]
}

const props = defineProps<{
  attachment?: Attachment
}>()
const { attachment } = toRefs(props)
</script>
<template>
  <Images v-if="attachment && attachment.files" :files="attachment.files" />
  <Files v-if="attachment && attachment.files" :files="attachment.files" />
</template>
