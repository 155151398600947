<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { Alert, Button, Label, TextField } from '@/components/common'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import useApi from '@/composables/api'
import type { ResetPasswordRequest } from '@/utils/api/types'
import { Icon } from '@/components/common'

import SuccessIcon from './SuccessIcon.vue'

const sent = ref(false)
const isPassVisible = ref(false)
const isConfirmVisible = ref(false)

const router = useRouter()
const route = useRoute()

const email = route.query.email

const form = reactive({
  password: '',
  confirm: '',
})

const formRules = {
  password: { required, min: minLength(6) },
  confirm: { required, sameAs: (value: string) => value === form.password },
}

const form$ = useVuelidate(formRules, form)

const { api, error } = useApi(import.meta.env.VITE_API_HOST)
const handleSubmit = () => {
  const route = router.currentRoute.value
  const payload: ResetPasswordRequest = {
    password: form.password,
    token: route.params.token as string,
    email: route.query.email as string,
  }

  api.auth.resetPassword(payload).then(() => {
    sent.value = true
  })
}
</script>

<template>
  <auth-layout
    v-if="!sent"
    title="Create a new password"
    subtitle="Enter a new password for your Vanilla account."
  >
    <form @submit.prevent="handleSubmit">
      <Label :class="$style.label">
        Password
        <div :class="$style.password_wrapper">
          <TextField
            v-model="form$.password.$model"
            placeholder="*********"
            size="2"
            :type="isPassVisible ? 'text' : 'password'"
            :error="error"
          />

          <Button
            type="button"
            variant="ghost"
            theme="neutral"
            size="1"
            :icon="isPassVisible ? 'eyeClosed' : 'eyeOpen'"
            @click.prevent="isPassVisible = !isPassVisible"
          />
        </div>
      </Label>

      <Label :class="$style.label">
        Confirm password
        <div :class="$style.password_wrapper">
          <TextField
            v-model="form$.confirm.$model"
            size="2"
            :type="isConfirmVisible ? 'text' : 'password'"
            :error="error"
          />

          <Button
            type="button"
            variant="ghost"
            theme="neutral"
            size="1"
            :icon="isConfirmVisible ? 'eyeClosed' : 'eyeOpen'"
            @click.prevent="isConfirmVisible = !isConfirmVisible"
          />
        </div>
      </Label>

      <div :class="$style.actions">
        <Button
          size="3"
          variant="solid"
          theme="neutral"
          :disabled="form$.$invalid"
        >
          Update password
        </Button>
      </div>
    </form>

    <Alert v-if="error" :error="error" :class="$style.alert">
      <template #icon>
        <Icon icon="circleExclamation" :size="20" />
      </template>
    </Alert>
  </auth-layout>

  <auth-layout v-else>
    <div :class="$style.success">
      <SuccessIcon />

      <div :class="$style.labels">
        <div :class="$style.title">Password has been updated for</div>
        <div :class="$style.value">{{ email }}</div>
      </div>

      <Button
        size="3"
        variant="solid"
        theme="neutral"
        as="router-link"
        :to="{ name: 'login' }"
      >
        Back to login
      </Button>
    </div>
  </auth-layout>
</template>

<style module lang="scss">
@import './styles.scss';
</style>
