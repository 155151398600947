<script setup lang="ts">
import { Avatar, Button, Icon } from '@/components/common'
import TextField from '@/components/common/TextField.vue'
import type { WidgetSettings } from '../types'
import { computed, toRefs } from 'vue'
import detectLuminosity from '@/utils/color'

const props = defineProps<{
  config: WidgetSettings
  logoPreview?: string
}>()
const { config } = toRefs(props)

const textColor = computed(() =>
  detectLuminosity(config.value.color || '#000') === 'dark' ? '#fff' : '#000',
)

const buttonPosition = computed(() =>
  config.value.position === 'left' ? 'flex-start' : 'flex-end',
)
</script>
<template>
  <div :class="$style.container">
    <div :class="[$style.window, 'theme-light']">
      <div :class="$style.header">
        <Avatar
          v-if="logoPreview || config.logoUrl"
          :src="logoPreview || config.logoUrl"
          :size="32"
        />
        <div :class="$style.title">
          <b>{{ config.title || 'Add title' }}</b>
          <p>{{ config.description || 'Add description' }}</p>
        </div>
      </div>
      <div :class="$style.messages">
        <div :class="$style.message">
          {{ config.message || 'Welcome message!' }}
        </div>
      </div>
      <div :class="$style.composer">
        <Button size="3" variant="ghost" theme="neutral" icon="paperclip2" />
        <TextField
          placeholder="Type a message"
          size="2"
          variant="soft"
          :class="$style.textField"
        />
      </div>
      <div :class="$style.footer">
        powered by <Icon icon="vanillaLogo" :width="53" :height="16" />
      </div>
    </div>
    <Button
      :class="$style.button"
      size="5"
      variant="solid"
      theme="primary"
      icon="bubble3"
    />
  </div>
</template>
<style module lang="scss">
.container {
  width: 352px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: v-bind('buttonPosition');
}
.window {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 520px;
  border-radius: 12px;
  border: 1px solid var(--neutral-alpha-4);
  background-color: var(--panel-1);
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background-color: v-bind('config.color');
    color: v-bind('textColor');
    column-gap: 12px;
  }

  .title {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    b {
      @extend .semibold-3;
    }

    p {
      @extend .regular-2;
      opacity: 0.72;
    }
  }

  .messages {
    display: flex;
    flex-grow: 1;
    padding: 16px 20px;
    align-items: flex-start;

    .message {
      @extend .regular-3;
      border-radius: 8px;
      background-color: var(--neutral-alpha-3);
      color: var(--neutral-12);
      padding: 8px 10px;
    }
  }

  .composer {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    padding: 20px;

    .textField {
      flex-grow: 1;
    }
  }

  .footer {
    @extend .regular-2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral-10);
    margin-bottom: 20px;
    column-gap: 4px;
  }
}

.button {
  background-color: v-bind('config.color');
  color: v-bind('textColor');
  pointer-events: none;
  transition: none;
}
</style>
