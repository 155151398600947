import type Contact from '@/models/contact'
import type User from '@/models/user'
import type Message from './message'

export enum TicketStatus {
  'new' = 1,
  'active' = 2,
  'closed' = 0,
  'spam' = 3,
}

export const statusLabels: Record<TicketStatus, string> = {
  [TicketStatus.new]: 'New',
  [TicketStatus.active]: 'Active',
  [TicketStatus.closed]: 'Closed',
  [TicketStatus.spam]: 'Spam',
}

export type TicketInterval = 'day' | 'week' | 'month' | 'year' | 'all'

export default interface Ticket {
  id: number
  channel: 'email' | 'chat'
  title: string
  status: TicketStatus
  workspaceTicketId: number
  messagesCount?: number
  unreadCount?: number
  createdAt: string
  updatedAt: string
  operators?: User[]
  contact: Contact
  firstMessage?: Message
  messages?: Message[]
  notes: Message[]
}
