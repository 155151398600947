export enum NotificationTypeEnum {
  TICKET_ASSIGNED = 1,
  TICKET_ASSIGNED_NEW_RESPONSE = 2,
  TICKET_MENTIONED = 3,
  TICKET_CREATED = 4,

  TASK_ASSIGNED = 5,
  TASK_COMMENT_MENTIONED = 6,
}

export default interface NotificationSetting {
  id: number
  type: NotificationTypeEnum
  inApp: boolean
  email: boolean
}
