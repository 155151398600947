<script setup lang="ts"></script>
<template>
  <table :class="$style.table">
    <slot></slot>
  </table>
</template>
<style module lang="scss">
.table {
  border-radius: 12px;
  border: 1px solid var(--neutral-alpha-4);
  background-color: var(--panel-1);
  border-collapse: separate;
  overflow: hidden;
  table-layout: fixed;

  thead {
    background: var(--panel-3);

    th {
      @extend .medium-3;
      padding: 16px;
      text-align: left;
      vertical-align: middle;
      border-bottom: 1px solid var(--neutral-alpha-4);
      transition: 0.3s background-color;

      &:hover {
        background-color: var(--neutral-alpha-3);
      }
    }
  }

  td:not(:last-child),
  th:not(:last-child) {
    border-right: 1px solid var(--neutral-alpha-4);
  }

  tbody {
    td {
      @extend .regular-3;
      padding: 0px 16px;
      height: 64px;
      vertical-align: middle;
      transition: background-color 0.1s;
    }

    tr {
      &:hover {
        td {
          background-color: var(--neutral-alpha-3);
        }
      }
    }

    tr:not(:last-child) {
      td {
        border-bottom: 1px solid var(--neutral-alpha-4);
      }
    }
  }

  :global(.selection) {
    display: flex;
    justify-content: center;
  }
}
</style>
