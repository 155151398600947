<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import { Button, EmptyView, Icon, TextField } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'

import SettingsHeader from './SettingsHeader.vue'
import InviteMember from './team/InviteMember.vue'
import MemberRow from './team/MemberRow.vue'

const store = useWorkspaceStore()
const { members, invitations } = storeToRefs(store)

const search = ref('')

store.getMembers()
store.getInvitations()

const displayMembers = computed(() => {
  if (!search.value) {
    return members.value
  }

  return members.value.filter((member) => {
    const [lowerName, lowerEmail] = [
      member.name?.toLowerCase() ?? '',
      member.email?.toLowerCase() ?? '',
    ]

    return (
      lowerName.includes(search.value?.toLowerCase()) ||
      lowerEmail.includes(search.value?.toLowerCase())
    )
  })
})

const displayInvitations = computed(() => {
  if (!search.value) {
    return invitations.value
  }

  return invitations.value.filter((member) => {
    const lowerEmail = member.email?.toLowerCase() ?? ''

    return lowerEmail.includes(search.value?.toLowerCase())
  })
})

const membersExists = computed(
  () => [...members.value, ...invitations.value]?.length > 0,
)

const displayAmount = computed(
  () => [...displayMembers.value, ...displayInvitations.value]?.length,
)
</script>

<template>
  <SettingsHeader
    title="Members"
    subtitle="Manage people with access to this workspace"
  />

  <hr :class="$style.separator" />
  <div :class="$style.container">
    <div :class="$style.head">
      <TextField
        v-model="search"
        size="1"
        variant="outline"
        placeholder="Search by name or email"
        :class="$style.input"
      >
        <template #pre>
          <Icon icon="magnifyingGlass" :class="$style.icon" :size="20" />
        </template>

        <template v-if="search?.length" #post>
          <Button
            variant="ghost"
            theme="neutral"
            size="1"
            icon="crossSmall"
            @click.prevent="search = ''"
          />
        </template>
      </TextField>

      <InviteMember />
    </div>

    <template v-if="membersExists">
      <div v-if="displayAmount > 0">
        <div :class="$style.total">
          {{ displayAmount }} member{{ displayAmount === 1 ? '' : 's' }}
        </div>

        <table :class="$style.table">
          <tbody>
            <slot v-for="member in displayMembers" :key="member.id">
              <MemberRow :member="member" />
            </slot>
            <slot v-for="invitation in displayInvitations" :key="invitation.id">
              <MemberRow :invitation="invitation" />
            </slot>
          </tbody>
        </table>
      </div>

      <EmptyView
        v-else
        icon="peopleRemove"
        title="No members found."
        description="heck spelling or try to search for someone else. You can always
            invite new members to your workspace"
        :class="$style.empty"
      />
    </template>
  </div>
</template>

<style module lang="scss">
@import url('./styles.scss');

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .input {
    width: 256px;
  }

  .icon {
    color: var(--neutral-alpha-9);
    min-width: 20px;
  }
}

.total {
  @extend .medium-2;

  margin-bottom: 24px;
}

.empty {
  margin-top: 64px;
}

.table {
  width: 100%;

  tr:not(:last-of-type) {
    border-bottom: 1px solid var(--neutral-alpha-4);
  }
}
</style>
