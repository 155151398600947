<script setup lang="ts">
import { Page } from '@/components/common/layout'
import { Button, EmptyView, Icon, Popover, Tabview } from '@/components/common'
import { computed, inject, onUnmounted, reactive, ref, watch } from 'vue'
import { useInboxStore, type Search } from './store'
import { initialFilters, tabs, type InitialFilterType } from './constants'
import Echo from 'laravel-echo'
import type Ticket from './models/ticket'
import InboxRow from './components/InboxRow.vue'
import { useInfiniteScroll } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import { useWorkspaceStore } from '@/store/workspace'
import FilterMenu from './components/menus/FilterMenu.vue'
import FiltersSummary from './components/FiltersSummary.vue'
import { isEqual } from 'lodash'
import SearchField from '@/components/Dashboard/SearchField.vue'
import InboxBulkActions from './components/actions/InboxBulkActions.vue'
import InboxRowSkeleton from './components/InboxRowSkeleton.vue'

const route = useRoute()
const router = useRouter()

const workspaceStore = useWorkspaceStore()
const store = useInboxStore()
store.getShortcuts()
store.getDomain()

const socket = inject<Echo>('socket')
const workspaceId = inject<string>('workspaceId')
const listRef = ref<HTMLElement | null>(null)
const showCustomDomainAlert = ref<boolean>(true)

const checkedIds = ref<number[]>([])

const searchState = reactive<Search>({
  ...initialFilters,
  type: 0,
  search: route.query.search as string,
})

const hasFilters = computed(() =>
  Object.keys(initialFilters).some(
    (k) =>
      !isEqual(
        initialFilters[k as keyof InitialFilterType],
        searchState[k as keyof InitialFilterType],
      ),
  ),
)

useInfiniteScroll(
  listRef,
  () => {
    store.loadNextPage()
  },
  {
    distance: 1,
    canLoadMore: () => !store.isStatus('loading'),
  },
)

const loadTickets = () => store.loadTickets(searchState)
loadTickets()

watch(
  () => [searchState.type, searchState.search],
  () => loadTickets(),
)

watch(
  () => route.query.search,
  () => (searchState.search = route.query.search as string),
)

const channel =
  socket &&
  socket
    .private(`inbox.${workspaceId}`)
    .listen('.TicketCreated', (ticket: Ticket) => {
      store.addNewTicket(ticket)
    })

onUnmounted(() => {
  channel && channel.stopListening('.TicketCreated')
})

const handleCheckChange = (id: number) => {
  if (checkedIds.value.includes(id)) {
    checkedIds.value = [...checkedIds.value.filter((item) => item !== id)]
    return
  }

  checkedIds.value.push(id)
}

const handleCheckAll = (checkAll: boolean) => {
  if (checkAll) {
    checkedIds.value = store.tickets.map((t) => t.id)
    return
  }

  checkedIds.value = []
}

const isInboxEmpty = computed(() => store.pagination?.totalItems === 0)

watch(isInboxEmpty, (newVal) => {
  if (newVal) router.replace({ name: 'InboxSetup' })
})
</script>
<template>
  <Page>
    <template #topBar>
      {{ `${workspaceStore.workspace?.title} Inbox` }}
      <SearchField />
    </template>

    <div
      v-if="!store.domain?.isVerified && showCustomDomainAlert"
      :class="$style.alert"
    >
      <Icon icon="sparklesTwo2" :size="20" />
      <p>Increase your customers trust by sending email from your own domain</p>
      <Button
        size="2"
        variant="ghost"
        theme="neutral"
        @click="showCustomDomainAlert = false"
        >Not Now</Button
      >
      <router-link :to="{ name: 'InboxSettings' }">
        <Button size="2" variant="solid" theme="neutral"
          >Set-up custom domain</Button
        >
      </router-link>
    </div>
    <div :class="$style.topbar">
      <Tabview v-model="searchState.type" :tabs="tabs" />
      <div :class="$style.actions">
        <Popover placement="bottom-end">
          <template #content>
            <FilterMenu
              :search="searchState"
              @update="
                (data) => {
                  Object.assign(searchState, data)
                  loadTickets()
                }
              "
            />
          </template>
          <Button variant="soft" theme="neutral" size="2" icon="filter1"
            >Filters</Button
          >
        </Popover>
      </div>
    </div>
    <FiltersSummary
      v-if="hasFilters"
      :search="searchState"
      @update="
        (data) => {
          Object.assign(searchState, data)
          loadTickets()
        }
      "
    />
    <table :class="$style.table" cellspacing="8">
      <thead :class="$style.thead">
        <tr>
          <th :class="$style.checkbox"></th>
          <th :class="$style.user"></th>
          <th></th>
          <th :class="$style.status"></th>
          <th :class="$style.time"></th>
          <th :class="$style.assignee"></th>
        </tr>

        <InboxBulkActions
          v-if="checkedIds.length"
          :selected-ids="checkedIds"
          :total="store.tickets.length"
          @check-all="handleCheckAll"
        />
      </thead>
      <tbody>
        <InboxRow
          v-for="ticket in [...store.tickets]"
          :key="ticket.id"
          :ticket="ticket"
          :checked="checkedIds.includes(ticket.id)"
          @on-check-change="handleCheckChange"
        />
        <InboxRowSkeleton v-if="store.isStatus('loading')" />
      </tbody>
    </table>
    <EmptyView
      v-if="store.tickets.length === 0 && store.isStatus('loaded')"
      :class="$style.empty"
      icon="box2AltFill"
      :title="
        hasFilters ? 'No tickets found.' : 'Your inbox is empty right now.'
      "
      :description="
        hasFilters
          ? `Nothing found with your filters. Try to adjust your filters preferences or clear all filters.`
          : `Once someone reach you in live chat or via email - you'll see a new
          message here. If you have any questions you can contact our support
          team anytime!`
      "
    />
    <div ref="listRef"></div>
  </Page>
</template>
<style module lang="scss">
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--page-margin);
}

.checkbox {
  width: 70px;
}

.user {
  width: 300px;
}

.message {
  width: 508px;
}

.status {
  width: 120px;
}

.time {
  width: 120px;
}

.assignee {
  width: 120px;
}

.actions {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 24px;
  }
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
}

.head-action {
  padding: 5px 8px;
  margin-left: -8px;
  cursor: pointer;

  &.active {
    color: var(--neutral-900);
    background-color: var(--neutral-200);
    border-radius: 8px;
  }
}

.empty {
  padding: 100px 0px;
}

.table {
  border-radius: 12px;
  border: 1px solid var(--neutral-alpha-4);
  border-collapse: separate;
  table-layout: fixed;
  margin: 32px 0px;
  overflow: hidden;

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid var(--neutral-alpha-3);
    }
  }

  tr td:first-of-type {
    padding-right: 16px;
  }
}

.alert {
  @extend .medium-3;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 12px 12px 12px 18px;
  border-radius: 12px;
  color: var(--accent-12);
  background-color: var(--accent-alpha-3);
  margin-top: var(--page-margin);

  p {
    flex-grow: 1;
  }

  i {
    color: var(--accent-11);
  }
}
</style>
