<script setup lang="ts">
import { Icon } from '@/components/common'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  stage: number
  totalStages: number
  size: number
}>()
const { stage, totalStages, size } = toRefs(props)

const percentage = computed(() =>
  totalStages.value <= 3
    ? 0
    : ((stage.value - 1) / (totalStages.value - 2)) * 100,
)

const strokeWidth = computed(() => 0.2 * size.value)
const halfSize = computed(() => `${size.value / 2}px`)
const radius = computed(() => (size.value - strokeWidth.value) / 2)
const circumference = computed(() => radius.value * Math.PI * 2)
const dash = computed(() => (percentage.value * circumference.value) / 100)
</script>
<template>
  <Icon v-if="stage === 0" icon="stateStart" :size="size" />
  <svg
    v-if="stage > 0 && stage < totalStages - 1"
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    :class="$style.progress"
  >
    <circle :class="[$style.circle, $style.background]"></circle>
    <circle
      v-if="percentage > 0"
      :class="[$style.circle, $style.foreground]"
    ></circle>
  </svg>
  <Icon
    v-if="totalStages > 1 && stage === totalStages - 1"
    icon="circleCheck"
    :size="size"
  />
</template>
<style module lang="scss">
.progress {
  .circle {
    cx: v-bind('halfSize');
    cy: v-bind('halfSize');
    r: v-bind('radius');
    stroke-width: v-bind('strokeWidth');
    fill: none;
    stroke-linecap: round;
  }
  .background {
    stroke: var(--neutral-alpha-6);
  }
  .foreground {
    transform: rotate(-90deg);
    transform-origin: v-bind('halfSize') v-bind('halfSize');
    stroke-dasharray: v-bind('dash')
      calc(v-bind('circumference') - v-bind('dash'));
    stroke: var(--neutral-12);
    transition: 0.3s stroke-dasharray;
  }
}
</style>
