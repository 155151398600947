<script setup lang="ts">
import { SetupIntent } from '@stripe/stripe-js'
import { Icon } from '../common'
import PaymentForm from './Form.vue'
import { useWorkspaceApi } from '@/composables/api'
import { useWorkspaceStore } from '@/store/workspace'
import { ref } from 'vue'
import { PlanDetails } from '@/utils/api/workspace'

defineProps<{
  title: string
  description: string
}>()

const { api } = useWorkspaceApi()
const store = useWorkspaceStore()
const plan = ref<PlanDetails>()

api.workspace.planDetails().then((data) => (plan.value = data))

const handleComplete = (intent: SetupIntent) => {
  api.workspace.upgradePremium(intent.payment_method as string).then(() => {
    window.location.href = `${new URL(location.pathname, location.href).href}`
  })
}
</script>
<template>
  <div :class="$style.container">
    <div :class="$style.left">
      <div :class="$style.title">
        <span :class="$style.icon">
          <Icon icon="crown" :width="16" :height="16" />
        </span>
        Vanilla Premium
      </div>
      <div v-if="plan" :class="$style.meta">
        <b>Unlock all of Vanilla's apps!</b>
        <p v-if="plan.trialDays > 0">
          Start your {{ plan.trialDays }}-day free trial now and enjoy access to
          more than one app. Enter your credit card details to begin your free
          trial.
        </p>
        <p v-else>
          Upgrade to premium now and enjoy access to more than one app. Enter
          your credit card details to begin your subscription.
        </p>
      </div>
      <div v-if="plan" :class="$style.details">
        <b :class="$style.title">Plan Details</b>
        <div :class="$style.row">
          <span :class="$style.item">Members</span>
          <span :class="$style.value">{{ store.members.length }} members</span>
        </div>
        <div :class="$style.row">
          <span :class="$style.item">Price</span>
          <span :class="$style.value"
            ><span :class="$style.striked">${{ plan.previous }}</span> ${{
              plan.price
            }}/user/month</span
          >
        </div>
        <div :class="$style.row">
          <span :class="$style.item">Total</span>
          <span :class="$style.value"
            >${{ store.members.length * plan.price }}/month</span
          >
        </div>
        <hr />
        <div v-if="plan.trialDays > 0" :class="$style.row">
          <span :class="$style.item">Due now</span>
          <span :class="[$style.value, $style.bold]">$0</span>
        </div>
        <div v-else :class="$style.row">
          <span :class="$style.item">Due now</span>
          <span :class="[$style.value, $style.bold]"
            >${{ store.members.length * plan.price }}/month</span
          >
        </div>
        <div v-if="plan.trialDays > 0" :class="$style.row">
          <span :class="$style.item">Due after trial</span>
          <span :class="$style.value"
            >${{ store.members.length * plan.price }}/month</span
          >
        </div>
      </div>
      <div :class="$style.footer">
        <p>
          By continuing, you agree to the<br /><a
            href="https://vanillaapps.com/terms"
            target="_blank"
            >Terms Of Service</a
          >
          and <a href="https://vanillaapps.com/privacy">Privacy Policy</a>
        </p>
      </div>
    </div>
    <div :class="$style.right">
      <div :class="$style.title">Payment details</div>
      <p :class="$style.subtitle">
        Complete your purchase by providing your payment details.
      </p>
      <PaymentForm
        v-if="plan"
        :cta="plan?.trialDays > 0 ? 'Start Trial' : 'Pay Now'"
        @complete="handleComplete"
      />
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 720px;
  height: 556px;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: ' ';
    position: absolute;
    top: -169px;
    left: -169px;
    width: 338px;
    height: 338px;
    border-radius: 338px;
    opacity: 0.2;
    background-color: var(--accent-9);
    filter: blur(200px);
    pointer-events: none;
  }

  &::after {
    content: ' ';
    position: absolute;
    bottom: -196px;
    left: -196px;
    width: 392px;
    height: 392px;
    border-radius: 392px;
    opacity: 0.3;
    background-color: var(--warning-9);
    filter: blur(200px);
    pointer-events: none;
  }
}
.left {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  width: 332px;
  border-right: 1px solid var(--neutral-alpha-4);

  .icon {
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background-color: var(--neutral-12);
    color: var(--neutral-1);
  }

  .meta {
    b {
      @extend .semibold-3;
    }
    p {
      @extend .regular-3;
      color: var(--neutral-11);
      margin-top: 2px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: stretch;
    justify-content: flex-end;
    color: var(--neutral-11);

    p {
      @extend .regular-2;
      a {
        @extend .medium-2;
        text-decoration: underline;
      }
    }
  }
}
.right {
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex-grow: 1;
  overflow: auto;
}
.title {
  @extend .semibold-6;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}
.subtitle {
  @extend .regular-3;
  width: 60%;
  color: var(--neutral-11);
  margin: 2px 0px 24px;
}

.details {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px;
  border-radius: 12px;
  background: var(--panel-translucent);
  box-shadow:
    0px 12px 32px -16px var(--support-alternative-shadow-blur-1),
    0px 12px 60px 0px var(--support-alternative-shadow-blur-2);
  backdrop-filter: blur(24px);
  position: relative;
  z-index: 1;

  .title {
    @extend .semibold-3;
    margin-bottom: 16px;
  }

  .row {
    @extend .regular-3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      color: var(--neutral-11);
    }
    .value {
      color: var(--neutral-12);

      &.bold {
        @extend .semibold-3;
      }

      .striked {
        color: var(--neutral-9);
        text-decoration: line-through;
      }
    }
  }
}
</style>
