<script setup lang="ts">
import { ref, toRefs, watch } from 'vue'

import { AssigneeMenu, Button, Popover } from '@/components/common'
import type User from '@/models/user'

const props = defineProps<{ selectedIds: number[] }>()
const emit = defineEmits<{ 'assignee-selected': [value: number | null] }>()

const { selectedIds } = toRefs(props)
const selectedValue = ref<User[]>([])

const handleAssignee = (selected: User[], close: () => void) => {
  const target = selected[selected.length - 1]
  const value = target?.id ? [target] : []

  selectedValue.value = value

  close?.()
  emit('assignee-selected', target?.id || null)
}

watch(
  selectedIds,
  () => {
    selectedValue.value = []
  },
  { deep: true },
)
</script>

<template>
  <Popover placement="bottom-end" @click.prevent>
    <template #content="{ close }">
      <div :class="$style.popout">
        <div :class="$style.label">
          Assign ({{ selectedIds.length }}) tickets to
        </div>

        <AssigneeMenu
          single-selection
          :assignees="selectedValue"
          :class="$style.menu"
          @update="(selected) => handleAssignee(selected, close)"
        />
      </div>
    </template>

    <Button
      size="2"
      variant="ghost"
      theme="neutral"
      icon="peopleCircle"
      :class="$style.link"
      @click.prevent
    >
      Assign
    </Button>
  </Popover>
</template>

<style module lang="scss">
.popout {
  padding: 8px;
}

.link {
  padding: 0;
  color: var(--neutral-alpha-11);

  &:hover {
    color: var(--neutral-alpha-12);
    background-color: transparent;
  }
}

.label {
  @extend .medium-2;

  color: var(--neutral-alpha-10);
  padding: 4px 8px;
  text-align: left;
}

.menu {
  text-align: left;
  padding: 8px 0 0;
}
</style>
