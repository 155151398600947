<script setup lang="ts">
import { Button } from '@/components/common'
import { fileToPreview } from '@/utils/helpers'
import { UploadedFile } from '@/models/file.ts'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  image: File | UploadedFile
  removable?: boolean
}>()

const emit = defineEmits<{ (e: 'remove'): void }>()

const { image, removable } = toRefs(props)

const imageUrl = computed<string>(() => {
  return image.value instanceof File
    ? (fileToPreview(image.value) as string)
    : image.value.url
})

const onClick = () => {
  if (!(image.value instanceof File)) {
    window.open(image.value.url, '_blank')
  }
}
</script>
<template>
  <div
    :class="$style.image"
    :style="{
      backgroundImage: `url(${imageUrl})`,
    }"
    @click="onClick"
  >
    <div v-if="removable" :class="$style.overlay">
      <Button
        size="1"
        theme="neutral"
        variant="ghost"
        icon="crossSmall"
        @click.stop="emit('remove')"
      ></Button>
    </div>
  </div>
</template>

<style module lang="scss">
.image {
  display: block;
  width: 180px;
  height: 180px;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  border: 1px solid var(--neutral-alpha-4);
  position: relative;

  .overlay {
    position: absolute;
    right: 0;
  }
}
</style>
