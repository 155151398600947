import type { ResolvedPos } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'

export interface NewState {
  active: boolean
  range: null | { from: number; to: number }
  text: string
}

export enum MatchType {
  Shortcut,
  Tagging,
}

const MatchMap = {
  [MatchType.Shortcut]: '(^|\\s)/([\\w-\\+]+)?$',
  [MatchType.Tagging]: '(^|\\s)@([\\w-\\+]+)?$',
}

interface KeyState {
  enter: boolean
  down: boolean
  up: boolean
  esc: boolean
  tab: boolean
}

type Callback = (state: NewState) => void

type KeyCallback = (state: KeyState) => void

const getNewState = (): NewState => ({
  active: false,
  range: null,
  text: '',
})

export function getMatch(
  $position: ResolvedPos,
  matchType = MatchType.Shortcut,
) {
  // take current para text content upto cursor start.
  // this makes the regex simpler and parsing the matches easier.
  const paraStart = $position.before()
  const text = $position.doc.textBetween(paraStart, $position.pos, '\n', '\0')

  const regex = new RegExp(MatchMap[matchType] || MatchMap[MatchType.Shortcut])
  const match = text.match(regex)

  // if match found, return match with useful information.
  if (match) {
    // adjust match.index to remove the matched extra space
    match.index = match[0].startsWith(' ')
      ? (match.index || 0) + 1
      : match.index
    match[0] = match[0].startsWith(' ')
      ? match[0].substring(1, match[0].length)
      : match[0]

    // The absolute position of the match in the document
    const from = $position.start() + (match.index || 0)
    const to = from + match[0].length

    const queryText = match[2]

    return {
      range: { from: from, to: to },
      queryText: queryText,
    }
  }
  // else if no match don't return anything.
}

export function CommandMenu(
  matchType: MatchType,
  callback: Callback,
  keyCallback: KeyCallback,
) {
  return new Plugin({
    key: new PluginKey('command_menu'),
    state: {
      init() {
        return getNewState()
      },
      apply(tr) {
        // compute state.active for current transaction and return
        const newState = getNewState()
        const selection = tr.selection
        if (selection.from !== selection.to) {
          return newState
        }

        const $position = selection.$from
        const match = getMatch($position, matchType)

        // if match found update state
        if (match) {
          newState.active = true
          newState.range = match.range
          newState.text = match.queryText
        }
        callback(newState)
        return newState
      },
    },
    props: {
      handleKeyDown(view, e) {
        const state = this.getState(view.state)

        const enter = e.key === 'Enter'
        const down = e.key === 'ArrowDown'
        const up = e.key === 'ArrowUp'
        const esc = e.key === 'Escape'
        const tab = e.key === 'Tab'

        const shouldHandle =
          state?.active && (enter || down || up || esc || tab)

        if (shouldHandle) {
          e.preventDefault()
          keyCallback({ enter, down, up, esc, tab })
        }

        return shouldHandle
      },
    },
  })
}
