<script setup lang="ts">
import {
  DropDown,
  Button,
  type DropDownItem,
  TextField,
  Confirm,
  EmptyView,
} from '@/components/common'
import draggable from 'vuedraggable'
import type { PipelineStage } from '../../models/pipelineStage'
import { useCrmPipelineStore } from '../../pipelineStore'
import type { SortableEvent } from './types'
import type { PipelineContact } from '../../models/pipelineContact'
import { computed, reactive, ref, toRefs } from 'vue'
import Card from './Card.vue'
import StageIndicator from '../StageIndicator.vue'
import { findSiblings, getNewLexorank } from '@/utils/board'
import { LexoRank } from 'lexorank'
import { showToast } from '@/utils'

const props = defineProps<{
  stage: PipelineStage
  contacts: PipelineContact[]
}>()
const { stage, contacts } = toRefs(props)

const store = useCrmPipelineStore()
const { stages } = toRefs(store)

const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)

const state = reactive({
  renameValue: '',
  renaming: false,
})

const sortedContacts = computed(() =>
  [...contacts.value].sort((a, b) =>
    LexoRank.parse(a.rank).compareTo(LexoRank.parse(b.rank)),
  ),
)

const handleContactAdd = (
  event: SortableEvent<PipelineContact>,
  stage: PipelineStage,
) => {
  const {
    item: { _underlying_vm_ },
    newIndex,
  } = event
  const contact = { ..._underlying_vm_ }
  const payload = findSiblings(newIndex, null, sortedContacts.value)
  const rank = getNewLexorank(payload)
  store.moveContactToStage(contact, stage, rank.toString(), newIndex)
}

const handleContactMove = (
  event: SortableEvent<PipelineContact>,
  stage: PipelineStage,
) => {
  const {
    item: { _underlying_vm_ },
    newIndex,
    oldIndex,
  } = event
  const contact = { ..._underlying_vm_ }
  const payload = findSiblings(newIndex, oldIndex, sortedContacts.value)
  const rank = getNewLexorank(payload)
  store.moveContactToStage(contact, stage, rank.toString(), newIndex)
}

const handleNameUpdate = () => {
  store.updateStage(stage.value, state.renameValue)
  state.renaming = false
}

const handleNameCancel = () => {
  state.renaming = false
}

const columnMenu: DropDownItem[] = [
  {
    id: 'rename',
    title: 'Rename',
    handler: () => {
      state.renameValue = stage.value.title
      state.renaming = true
    },
  },
  {
    id: 'delete',
    type: 'destructive',
    title: 'Delete',
    handler: () => {
      if (contacts.value.length > 0) {
        return showToast({
          type: 'error',
          text: `Can't delete the column "${stage.value.title}". There are still contacts within this column. Please remove them from the column before deleting it`,
          duration: 3000,
        })
      }
      confirmPopup.value?.show({
        title: 'Delete column',
        message: `You're about to delete column. Are you sure you want to proceed?`,
        actions: [
          {
            title: 'Cancel',
            theme: 'neutral',
            variant: 'ghost',
          },
          {
            title: `Delete Column`,
            theme: 'danger',
            handler: () => store.deleteStage(stage.value.id),
          },
        ],
      })
    },
  },
]
</script>
<template>
  <div :class="$style.column">
    <div :class="$style.header">
      <div v-if="state.renaming" :class="$style.title">
        <TextField
          v-model="state.renameValue"
          :class="$style.textField"
          variant="soft"
          @keyup.enter="handleNameUpdate"
          @keyup.escape="handleNameCancel"
        >
          <template #post>
            <Button
              icon="check"
              variant="ghost"
              theme="neutral"
              size="1"
              @click="handleNameUpdate"
            />
          </template>
        </TextField>
      </div>
      <div v-else :class="$style.title">
        <StageIndicator
          :size="16"
          :stage="stage.order"
          :total-stages="stages.length"
        />
        {{ stage.title }}
      </div>
      <div :class="$style.actions">
        <DropDown :width="160" :menu="columnMenu" :close-on-select="true">
          <Button
            size="2"
            theme="neutral"
            variant="ghost"
            icon="dotGrid1x3Horizontal"
          />
        </DropDown>
        <Button
          size="2"
          theme="neutral"
          variant="ghost"
          icon="plusSmall"
          @click="store.isAddContactOpen = true"
        />
      </div>
    </div>
    <EmptyView
      v-if="contacts.length === 0"
      title=""
      description="No contacts in this column."
    >
      <Button
        size="2"
        variant="ghost"
        theme="neutral"
        @click="store.isAddContactOpen = true"
        >Add Contact</Button
      >
    </EmptyView>
    <draggable
      :class="$style.cards"
      :model-value="sortedContacts"
      animation="200"
      group="cards"
      item-key="id"
      @add="(event) => handleContactAdd(event, stage)"
      @update="(event) => handleContactMove(event, stage)"
    >
      <template #item="{ element: card }">
        <Card :contact="card" />
      </template>
    </draggable>
    <Confirm ref="confirmPopup" />
  </div>
</template>
<style module lang="scss">
.column {
  display: flex;
  flex-direction: column;
  width: 340px;
  border-radius: 12px;
  flex-shrink: 0;
  border: 1px solid var(--panel-3);
  background-color: var(--panel-3);
  padding: 0px 8px;

  .header {
    @extend .medium-3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    color: var(--neutral-alpha-12);
    column-gap: 8px;

    .title {
      flex-grow: 1;
    }

    .title,
    .actions {
      display: flex;
      align-items: center;
      column-gap: 6px;
    }

    .textField {
      width: 100%;
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 4px;
  }
}
</style>
