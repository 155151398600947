import type { AxiosInstance } from 'axios'
import type {
  InvitationDataPayload,
  InvitationResponse,
  LoginRequest,
  ResetPasswordRequest,
  SignupRequest,
} from './types'

export class AuthService {
  constructor(private instance: AxiosInstance) {}

  login = (payload: LoginRequest) =>
    this.instance.post(`/login`, payload).then((response) => response.data)

  signup = (payload: SignupRequest) =>
    this.instance.post(`/register`, payload).then((response) => response.data)

  forgot = (payload: { email: string }) =>
    this.instance
      .post('/forgot-password', payload)
      .then((response) => response.data)

  resetPassword = (payload: ResetPasswordRequest) =>
    this.instance
      .post('/reset-password', payload)
      .then((response) => response.data)

  signOut = () =>
    this.instance.post('/logout').then((response) => response.data)

  invitation = ({ token }: InvitationDataPayload) =>
    this.instance
      .get<InvitationResponse>(`/invitation/${token}`)
      .then((res) => res.data)

  socialVerify = (token: string) =>
    this.instance.post(`/social/verify`, { token }).then((res) => res.data)
}
