<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { watchDebounced } from '@vueuse/core'
import { Icon, TextField } from '../common'

const route = useRoute()
const router = useRouter()

const searchExpanded = ref(false)
const search = ref((route.query.search as string) || '')

watchDebounced(
  search,
  () => {
    router.replace({ query: { ...route.query, search: search.value } })
  },
  { debounce: 500, maxWait: 1000 },
)

watch(
  () => route.query.search,
  () => (search.value = route.query.search as string),
)
</script>
<template>
  <TextField
    v-model="search"
    placeholder="Search something..."
    :class="[$style.search, searchExpanded && $style.expanded]"
    @focus="() => (searchExpanded = true)"
    @blur="() => (searchExpanded = false)"
  >
    <template #pre>
      <Icon icon="magnifyingGlass" :size="20" />
    </template>
  </TextField>
</template>
<style module lang="scss">
.search {
  width: 300px;
  transition: width 0.2s;

  &.expanded {
    width: 90%;
  }
}
</style>
