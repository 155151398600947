<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { email, minLength, required } from '@vuelidate/validators'
import { reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'

import { Alert, Button, Icon, Label, TextField } from '@/components/common'
import useApi from '@/composables/api'
import { tracker } from '@/utils'
import type { InvitationResponse } from '@/utils/api/types'
import { brevo } from '@/utils/brevo'

import SuccessIcon from './SuccessIcon.vue'

const props = defineProps<{ invitation?: InvitationResponse; token?: string }>()
const emit = defineEmits<{ 'handle-success': [] }>()

const { invitation, token } = toRefs(props)

const router = useRouter()

const { api, isStatus, error } = useApi()
const googleAuth = `${import.meta.env.VITE_API_HOST}social/connect/google`

const isPassVisible = ref(false)
const success = ref(false)
const resent = ref(false)

const form = reactive({
  firstName: '',
  lastName: '',
  email: invitation.value?.email || '',
  password: '',
})

const formRules = {
  firstName: {
    required,
  },
  lastName: {
    required,
  },
  email: {
    required,
    email,
  },
  password: {
    required,
    min: minLength(6),
  },
}
const form$ = useVuelidate(formRules, form)

const handleSubmit = () => {
  api.auth.signup({ ...form, invitationToken: token.value }).then(() => {
    tracker.trackIdentity(form)
    tracker.trackEvent('signup_started')

    brevo.initializeUser(form)

    if (invitation.value?.id) {
      router.push({ name: 'Splash' })
      return
    }

    if (!success.value) {
      success.value = true
      emit('handle-success')
      return
    } else if (success.value && !resent.value) {
      resent.value = true
    }
  })
}
</script>

<template>
  <template v-if="!success">
    <form @submit.prevent="handleSubmit">
      <Label :class="$style.label">
        First name
        <TextField
          v-model="form$.firstName.$model"
          required
          size="2"
          placeholder="John"
        />
      </Label>

      <Label :class="$style.label">
        Last name
        <TextField
          v-model="form$.lastName.$model"
          required
          size="2"
          placeholder="Doe"
        />
      </Label>

      <Label :class="$style.label">
        Email
        <TextField
          v-model="form$.email.$model"
          type="email"
          size="2"
          placeholder="johndoe@gmail.com"
          :disabled="!!invitation?.id"
        />
      </Label>

      <Label :class="$style.label">
        Password
        <div :class="$style.password_wrapper">
          <TextField
            v-model="form$.password.$model"
            placeholder="*********"
            size="2"
            :type="isPassVisible ? 'text' : 'password'"
          />

          <Button
            type="button"
            variant="ghost"
            theme="neutral"
            size="1"
            :icon="isPassVisible ? 'eyeClosed' : 'eyeOpen'"
            @click.prevent="isPassVisible = !isPassVisible"
          />
        </div>
      </Label>

      <div :class="$style.actions">
        <Button
          type="submit"
          variant="solid"
          theme="neutral"
          size="3"
          :disabled="form$.$invalid || isStatus('posting')"
          :loading="isStatus('posting')"
        >
          Continue with email
        </Button>

        <Button
          as="a"
          icon="google"
          theme="neutral"
          variant="outline"
          size="3"
          type="button"
          :href="googleAuth"
          :class="$style.secondary"
        >
          Continue with Google
        </Button>
      </div>
    </form>

    <div :class="$style.footerActions">
      <div>Already a member?</div>

      <router-link :to="{ name: 'login' }" :class="$style.link">
        Log in
      </router-link>
    </div>

    <Alert v-if="error" :error="error" :class="$style.alert">
      <template #icon>
        <Icon icon="circleExclamation" :size="20" />
      </template>
    </Alert>

    <hr :class="$style.divider" />

    <div :class="$style.termsContainer">
      <div>By creating a Vanilla account you agree to the</div>

      <div :class="$style.legal">
        <a
          href="https://vanillaapps.com/terms"
          target="_blank"
          :class="$style.link"
        >
          Terms Of Service
        </a>

        <div>and</div>

        <a
          href="https://vanillaapps.com/privacy"
          target="_blank"
          :class="$style.link"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </template>

  <template v-else>
    <div :class="$style.success">
      <SuccessIcon />
      <div :class="$style.labels">
        <div :class="$style.title">
          We&apos;ve just sent a verification link to
        </div>
        <div :class="$style.value">{{ form.email }}</div>
      </div>

      <div :class="$style.resend">
        <Button
          v-if="!resent"
          size="3"
          variant="soft"
          theme="neutral"
          @click.prevent="handleSubmit"
        >
          Resend link
        </Button>

        <Alert
          v-else
          success="Link sent! Check your email"
          :class="$style.successAlert"
        />
      </div>
    </div>

    <Alert v-if="error" :error="error" :class="$style.alert">
      <template #icon>
        <Icon icon="circleExclamation" :size="20" />
      </template>
    </Alert>
  </template>
</template>

<style module lang="scss">
@import './styles.scss';

.divider {
  margin-top: 24px;
}

.termsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  color: var(--neutral-11);
  margin-top: 32px;

  @extend .regular-2;

  a {
    @extend .medium-2;
    color: var(--neutral-11);
  }

  * {
    text-align: center;
  }

  .legal {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
