<script setup lang="ts">
import { Button, Icon, Popover } from '@/components/common'
import { TaskSearch } from '../types'
import FilterMenu from './FilterMenu.vue'

const emit = defineEmits<{
  (e: 'update', data: TaskSearch): void
}>()
const { search } = defineProps<{
  search: TaskSearch
  mode: 'team' | 'mine'
}>()

const handleRemove = (k: keyof TaskSearch) => {
  emit('update', { ...search, [k]: undefined })
}
</script>
<template>
  <hr :class="$style.hr" />
  <div :class="$style.container">
    <div v-if="search.assignee?.length" :class="$style.tag">
      {{ search.assignee?.length }} assignee
      <Icon icon="crossSmall" :size="16" @click="handleRemove('assignee')" />
    </div>
    <div v-if="search.team" :class="$style.tag">
      {{ search.team?.name }}
      <Icon icon="crossSmall" :size="16" @click="handleRemove('team')" />
    </div>
    <div v-if="search.priority?.length" :class="$style.tag">
      {{ search.priority?.length }} priorities
      <Icon icon="crossSmall" :size="16" @click="handleRemove('priority')" />
    </div>
    <div v-if="search.status?.length" :class="$style.tag">
      {{ search.status?.length }} statuses
      <Icon icon="crossSmall" :size="16" @click="handleRemove('status')" />
    </div>
    <div v-if="search.dueDate" :class="$style.tag">
      Due {{ search.dueDate.label }}
      <Icon icon="crossSmall" :size="16" @click="handleRemove('dueDate')" />
    </div>
    <div v-if="search.tags?.length" :class="$style.tag">
      {{ search.tags?.length }} tags
      <Icon icon="crossSmall" :size="16" @click="handleRemove('tags')" />
    </div>
    <Popover>
      <template #content>
        <FilterMenu
          :mode="mode"
          :search="search"
          @update="(data) => $emit('update', data)"
        />
      </template>
      <Button size="2" variant="ghost" theme="neutral" icon="circlePlus" />
    </Popover>
  </div>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 32px;
}
.hr {
  margin-top: -16px;
  margin-bottom: 16px;
}
.tag {
  @extend .medium-3;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--neutral-alpha-3);
  padding: 0px 12px;
  height: 32px;
  column-gap: 4px;

  i {
    cursor: pointer;
  }
}
</style>
