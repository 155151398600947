<script setup lang="ts">
import Paywall from '@/components/Dashboard/Paywall.vue'
import { useCrmStore } from './store'

const store = useCrmStore()
store.loadPipelines()
</script>

<template>
  <Paywall>
    <router-view />
  </Paywall>
</template>
