<script setup lang="ts">
import Icons from '@/assets/icons'
import { Icon, Button, TextField } from '@/components/common'
import { computed, ref } from 'vue'
import { ColorsMenu } from './constants'
import { Team } from '../models/team'
import { UpdateTeamRequest } from '../store'

const props = defineProps<{
  team: Team
}>()

const emits = defineEmits<{
  (e: 'update', data: UpdateTeamRequest): void
}>()

const search = ref<string>('')
const teamColor = ref<string | undefined>(props.team.color)
const teamIcon = ref<keyof typeof Icons | undefined>(props.team.icon)

const handleIcon = (icon: keyof typeof Icons) => {
  teamIcon.value = icon
  emits('update', { icon })
}

const handleColor = (color: string) => {
  teamColor.value = color
  emits('update', { color })
}

const icons = computed(() => {
  const query = search.value.toLowerCase()
  return Object.keys(Icons).filter((key) => {
    return key !== 'vanillaLogo' && key.toLowerCase().includes(query)
  }) as Array<keyof typeof Icons>
})
</script>
<template>
  <div :class="$style.container">
    <p>
      Select icon for <span>{{ team.name }}</span>
    </p>
    <div :class="$style['colors-list']">
      <div
        v-for="color in ColorsMenu"
        :key="color"
        :style="{ backgroundColor: `${color}` }"
        :class="[$style.color, color === teamColor && $style.active]"
        @click="() => handleColor(color)"
      />
    </div>
    <TextField v-model="search" placeholder="Search icons">
      <template #pre>
        <Icon icon="magnifyingGlass" :size="18" />
      </template>
    </TextField>
    <div :class="$style['icons-list']">
      <Button
        v-for="icon in icons"
        :key="icon"
        :class="[icon === teamIcon && $style['active-icon']]"
        size="2"
        variant="ghost"
        theme="neutral"
        @click="() => handleIcon(icon)"
      >
        <template #icon>
          <Icon :icon="icon" :size="20" />
        </template>
      </Button>
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  width: 324px;
  padding: 16px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p {
    @extend .regular-3;

    span {
      @extend .semibold-3;
    }
  }

  .colors-list {
    display: flex;
    justify-content: flex-start;
    column-gap: 12px;

    .color {
      width: 22px;
      height: 22px;
      border-radius: 8px;
      border: 2px solid var(--background-1);
      padding: 2px;

      &:hover {
        box-shadow: 0px 0px 0px 2px var(--neutral-alpha-4);
      }

      &.active {
        box-shadow: 0px 0px 0px 2px var(--neutral-alpha-12);
      }
    }
  }

  .icons-list {
    display: flex;
    column-gap: 3px;
    row-gap: 4px;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 150px;
    overflow: auto;

    .active-icon {
      background-color: var(--neutral-alpha-5);
    }
  }
}
</style>
