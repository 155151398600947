<script setup lang="ts">
const props = defineProps<{
  width?: number
  height?: number
  marginTop?: number
}>()
const width = props.width ? `${props.width}px` : '100%'
const height = props.height ? `${props.height}px` : '100%'
const marginTop = props.marginTop ? `${props.marginTop}px` : null
</script>
<template>
  <div :class="$style.skeleton" />
</template>
<style module lang="scss">
@keyframes skeltonAnimation {
  0% {
    background-position: -50vw 0;
  }
  80% {
    background-position: 50vw 0;
  }

  100% {
    background-position: 50vw 0;
  }
}

.skeleton {
  background: var(--neutral-5);
  background: linear-gradient(
    to right,
    var(--neutral-5) 5%,
    var(--neutral-8) 15%,
    var(--neutral-5) 25%
  );

  display: inline-block;
  border-radius: 4px;
  width: v-bind('width');
  height: v-bind('height');
  background-size: 100vw 100vh;
  margin-top: v-bind('marginTop');
  animation: skeltonAnimation 1s linear infinite;
}
</style>
