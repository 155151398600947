import type User from '@/models/user'
import { TaskStatus } from './taskStatus'
import { Tag } from '@/models/tag'
import { Team } from '@modules/tasks/models/team.ts'
import { UploadedFile } from '@/models/file.ts'

export interface Task {
  id: number
  number: number
  title: string
  description: string
  priority: TaskPriority
  teamId: number
  statusId: number
  startDate: string
  dueDate: string
  ticketNo: string
  rank: string
  attachments: {
    files: UploadedFile[]
    images: UploadedFile[]
  }

  status: TaskStatus
  tags?: Tag[]
  assignees?: User[]
  team?: Team
  parent?: Task
  subTasks?: Task[]
}

export enum TaskPriority {
  none = 0,
  low = 1,
  medium = 2,
  high = 3,
  urgent = 4,
}
