<script setup lang="ts">
import { computed, ref } from 'vue'
import { useWorkspaceStore } from '@/store/workspace'
import { Icon, Button, Popup } from '@/components/common'
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import ConnectSteps from '@/modules/inbox/components/ConnectSteps.vue'

const workspaceStore = useWorkspaceStore()

const popup = ref<typeof Popup>()

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspaceStore.workspace?.title} Inbox`,
  },
])
</script>
<template>
  <Page>
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
    </template>
    <div :class="$style.container">
      <Icon icon="box2AltFill" :size="32" />
      <h4 :class="$style.heading">Welcome to Inbox!</h4>
      <p :class="$style.copy">
        All-in-one messaging tool. Perfect for customer support & sales. Ready
        to make your first steps? Let&rsquo;s set up a live chat widget!
      </p>
      <Button
        size="3"
        variant="solid"
        theme="neutral"
        :class="$style.cta"
        @click="$router.push({ name: 'WidgetSetup' })"
      >
        Set up live chat widget
      </Button>
      <Button
        size="3"
        variant="ghost"
        theme="neutral"
        :class="[$style.cta, $style['cta-link']]"
        @click="popup?.show()"
      >
        Set up email forwarding
      </Button>
    </div>
    <div :class="$style.banner"></div>
  </Page>
  <Popup ref="popup" title="Set up email forwarding">
    <ConnectSteps />
  </Popup>
</template>
<style module lang="scss">
.container {
  width: 556px;
  margin-top: 64px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    @extend .semibold-6;
    margin: 24px 0px 2px;
  }
  .copy {
    @extend .regular-3;
    color: var(--neutral-11);
    text-align: center;
    margin-bottom: 32px;
  }
  .cta {
    margin-bottom: 32px;
  }
  .cta-link {
    color: var(--neutral-alpha-11);
    text-decoration: underline;
    text-decoration-color: var(--neutral-alpha-7);
  }
}
.banner {
  width: 100%;
  background-image: url('/images/inbox-2.png');
  background-position: top;
  background-size: 95%;
  background-repeat: no-repeat;
  height: 300px;
  box-shadow: var(--overlays-white-12) 0px -30px 20px 20px inset;
}
</style>
