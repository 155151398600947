<script setup lang="tsx">
import { computed, ref } from 'vue'
import type { PipelineContact } from '../../models/pipelineContact'
import type { PipelineStage } from '../../models/pipelineStage'
import {
  FlexRender,
  getCoreRowModel,
  useVueTable,
  type SortingState,
  getSortedRowModel,
  type RowSelectionState,
  type ColumnDef,
} from '@tanstack/vue-table'
import { Checkbox, EmptyView, Icon, Table, Button } from '@/components/common'
import { useCrmPipelineStore } from '../../pipelineStore'
import StatusColumn from './StatusColumn.vue'
import TagsColumn from './TagsColumn.vue'
import AssigneeColumn from './AssigneeColumn.vue'
import BulkActions from '../actions/BulkActions.vue'
import { storeToRefs } from 'pinia'
import NameColumn from './NameColumn.vue'

const store = useCrmPipelineStore()
const { stages, contacts } = storeToRefs(store)

const sorting = ref<SortingState>([
  {
    id: 'createdAt',
    desc: true,
  },
])
const rowSelection = ref<RowSelectionState>({})

const stageMap = computed<Record<number, PipelineStage>>(() => {
  const map: Record<number, PipelineStage> = {}
  stages.value.forEach((stage) => {
    map[stage.id] = stage
  })
  return map
})

const columnWidths = ['3%', '40%', '15%', '20%', '12%', '10%']
const columns: ColumnDef<PipelineContact>[] = [
  {
    id: 'select',
    size: 48,
    maxSize: 48,
    header: ({ table }) => {
      return (
        <Checkbox
          modelValue={table.getIsAllRowsSelected()}
          intermediate={table.getIsSomeRowsSelected()}
          onClick={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    cell: ({ row }) => {
      return (
        <div class="selection">
          <Checkbox
            modelValue={row.getIsSelected()}
            onClick={row.getToggleSelectedHandler()}
          />
        </div>
      )
    },
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ row }) => {
      return <NameColumn contact={row.original} />
    },
  },
  {
    header: 'Company',
    accessorKey: 'company.name',
    cell: ({ row }) => row.original.company?.name || '-',
  },
  {
    header: 'Tags',
    enableSorting: false,
    cell: ({ row }) => {
      return <TagsColumn contact={row.original} mode="pipeline" />
    },
  },
  {
    header: 'Assignee',
    accessorFn: (row) => row.name,
    cell: ({ row }) => {
      return <AssigneeColumn contact={row.original} mode="pipeline" />
    },
  },
  {
    header: 'Status',
    accessorFn: (row) => stageMap.value[row.stageId].title,
    cell: ({ row }) => {
      const stage = stageMap.value[row.original.stageId]
      return <StatusColumn contact={row.original} stage={stage} />
    },
  },
]

const table = useVueTable({
  get data() {
    return contacts.value
  },
  state: {
    get sorting() {
      return sorting.value
    },
    get rowSelection() {
      return rowSelection.value
    },
  },
  getRowId: (data) => `${data.id}`,
  enableRowSelection: true,
  onSortingChange: (updaterOrValue) => {
    sorting.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(sorting.value)
        : updaterOrValue
  },
  onRowSelectionChange: (updateOrValue) => {
    rowSelection.value =
      typeof updateOrValue === 'function'
        ? updateOrValue(rowSelection.value)
        : updateOrValue
  },
  columns,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
})
</script>
<template>
  <BulkActions
    v-if="Object.keys(rowSelection).length > 0"
    mode="pipeline"
    :selection="Object.keys(rowSelection).map((r) => Number(r))"
  />
  <EmptyView
    v-if="store.isStatus('loaded') && store.pagination?.totalItems === 0"
    icon="group2"
    title="Your pipeline is empty right now."
    description="You don't have any contacts added to this pipeline. Let's start adding some!"
  >
    <Button
      size="2"
      variant="outline"
      theme="neutral"
      @click="store.isAddContactOpen = true"
      >Add contact</Button
    >
  </EmptyView>
  <EmptyView
    v-else-if="store.isStatus('loaded') && store.contacts.length === 0"
    icon="peopleRemove"
    title="No contacts found."
    description="Nothing found with your filters. Try to adjust your filters preferences or clear all filters."
  />
  <Table v-else>
    <thead>
      <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
        <th
          v-for="(header, index) in headerGroup.headers"
          :key="header.id"
          :colSpan="header.colSpan"
          :width="columnWidths[index]"
          @click="header.column.getToggleSortingHandler()?.($event)"
        >
          <div :class="[$style.header, index === 0 && 'selection']">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"
            />
            <Icon
              v-if="header.column.getCanSort() && !header.column.getIsSorted()"
              icon="chevronGrabberVertical"
            />
            <!-- <Icon
              v-else
              :icon="header.column.getIsSorted() === 'asc' ? 'ch' : ''"
            /> -->
            {{
            { asc: ' 🔼', desc: ' 🔽' }[header.column.getIsSorted() as string]
            }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in table.getRowModel().rows" :key="row.id">
        <td
          v-for="cell in row.getVisibleCells()"
          :key="cell.id"
          :width="`${cell.column.getSize()}px`"
        >
          <FlexRender
            :render="cell.column.columnDef.cell"
            :props="cell.getContext()"
          />
        </td>
      </tr>
    </tbody>
  </Table>
</template>
<style module lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
