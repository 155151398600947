<script setup lang="ts">
import { required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'

import { Button, Label, Popup, TextField } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import useVuelidate from '@vuelidate/core'

const deleteWorkspaceMail = `mailto:hello@vanillaapps.com?subject=Request%20for%20workspace%20deletion&body=Hello%2C%20i%20would%20like%20to%20have%20my%20workspace%20permanently%20deleted.%20Please%20respond%20with%20confirmation%20once%20the%20workspace%20has%20been%20deleted`

const workspaceStore = useWorkspaceStore()
const workspace = workspaceStore.workspace

const deletePopup = ref<InstanceType<typeof Popup> | null>(null)

const form = reactive({
  name: '',
})

const rules = {
  name: {
    required,
    sameAs: (value: string) => value === workspace?.title,
  },
}

const form$ = useVuelidate(rules, form, { $scope: false })

const handleOpen = () => {
  deletePopup.value?.show()
}

// Note: Commented functionality for future workspace deletion to navigate to new or existing one
// const handleClose = () => {
//   deletePopup.value?.hide()
//   form.name = ''
// }

// const handleDelete = async () => {
// const newWorkspace = await workspaceStore.deleteCurrentWorkspace()

// if (!newWorkspace?.id) {
//   return
// }

// await router.replace({
//   name: newWorkspace?.setupComplete ? 'Dashboard' : 'WorkspaceOnlyOnboarding',
//   force: true,
//   params: { workspaceId: newWorkspace.id },
// })

//   location?.reload()
//   handleClose()
// }
</script>

<template>
  <Button variant="ghost" size="2" theme="danger" @click="handleOpen">
    Delete workspace
  </Button>

  <Popup ref="deletePopup" title="Delete workspace" @hide="form.name = ''">
    <form>
      <div :class="$style.content">
        <div :class="$style.description">
          If you&apos;d like to delete this workspace, please, confirm workspace
          name by typing it. This action is permanent and cannot be undone. You
          and your team members will lose access to all apps in this workspace.
        </div>

        <Label>
          Confirm workspace deletion
          <TextField v-model="form$.name.$model" />
        </Label>
      </div>

      <div :class="$style.actions">
        <Button
          type="submit"
          variant="solid"
          theme="danger"
          size="3"
          :as="form$.$invalid ? 'button' : 'a'"
          :disabled="form$.$invalid"
          :href="deleteWorkspaceMail"
        >
          Delete workspace
        </Button>
      </div>
    </form>
  </Popup>
</template>

<style module lang="scss">
.content {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 52px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  .description {
    @extend .regular-3;

    color: var(--neutral-11);
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
}
</style>
