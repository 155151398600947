<script setup lang="ts">
import { AssigneeMenu, Icon, Menu } from '@/components/common'
import MenuItem from '@/components/common/Menu/MenuItem.vue'
import { inject, ref, watch, type Ref } from 'vue'
import StatusMenu from './StatusMenu.vue'
import IssuedMenu from './IssuedMenu.vue'
import type { Search } from '../../store'
import type User from '@/models/user'
import type { TicketInterval } from '../../models/ticket'

const emit = defineEmits<{
  (e: 'update', data: Search): void
}>()
const { search } = defineProps<{ search: Search }>()

const isOpen = inject<Ref<boolean>>('isOpen')
const menu = ref<'assignee' | 'status' | 'date' | undefined>(undefined)

const handleAssignee = (users: User[]) => {
  emit('update', {
    ...search,
    assignee: users.map((u) => u.id),
  })
}

const handleStatus = (statuses: number[]) => {
  emit('update', {
    ...search,
    status: statuses,
  })
}

const handleInterval = (interval: TicketInterval) => {
  emit('update', {
    ...search,
    timeInterval: interval,
  })
}

watch(
  () => isOpen?.value,
  () => {
    menu.value = undefined
  },
)
</script>
<template>
  <AssigneeMenu
    v-if="menu === 'assignee'"
    :ids="search.assignee"
    @update="handleAssignee"
  />
  <StatusMenu
    v-else-if="menu === 'status'"
    :statuses="search.status"
    @update="handleStatus"
  />
  <IssuedMenu
    v-else-if="menu === 'date'"
    :interval="search.timeInterval"
    @update="handleInterval"
  />

  <Menu v-else :width="164">
    <MenuItem title="Assignee" @click="menu = 'assignee'">
      <template #icon>
        <Icon icon="peopleCircle" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Status" @click="menu = 'status'">
      <template #icon>
        <Icon icon="circleHalfFill" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <MenuItem title="Issued date" @click="menu = 'date'">
      <template #icon>
        <Icon icon="calendar2" :size="20" :class="$style.icon" />
      </template>
    </MenuItem>
    <!-- <MenuItem title="From user">
      <template #icon>
        <Icon icon="group2" :size="20" :class="$style.icon" />
      </template>
    </MenuItem> -->
  </Menu>
</template>
<style module lang="scss">
.icon {
  color: var(--neutral-11);
}
</style>
