<script setup lang="ts">
import {
  TextField,
  Icon,
  Button,
  type DropDownItem,
  DropDown,
  Popup,
} from '@/components/common'
import Form from './Form.vue'
import { computed, ref } from 'vue'
import { useInboxStore } from '../../store'
import type Shortcut from '../../models/shortcut'

const store = useInboxStore()
const search = ref<string>('')
const formRef = ref<InstanceType<typeof Popup>>()
const editShortcut = ref<Shortcut>()

const handleAdd = () => {
  editShortcut.value = undefined
  formRef.value && formRef.value.show()
}

const shortcuts = computed(() => {
  if (search.value.length === 0) return store.shortcuts
  return store.shortcuts.filter(
    (s) =>
      s.command.toLowerCase().includes(search.value.toLowerCase()) ||
      s.description.toLowerCase().includes(search.value.toLowerCase()),
  )
})

const menu = (shortcut: Shortcut): DropDownItem[] => [
  {
    title: 'Edit',
    handler: () => {
      editShortcut.value = shortcut
      formRef.value && formRef.value.show()
    },
  },
  {
    title: 'Delete',
    type: 'destructive',
    handler: async () => store.deleteShortcut(shortcut),
  },
]
</script>
<template>
  <div :class="['popup-content', $style.content]">
    <div :class="$style.actions">
      <TextField v-model="search" :class="$style.search" placeholder="Search">
        <template #pre>
          <Icon icon="magnifyingGlass" :size="20" />
        </template>
      </TextField>
      <Button
        icon="plusSmall"
        size="2"
        variant="soft"
        theme="neutral"
        @click="handleAdd"
      />
    </div>
    <ul :class="$style.list">
      <li v-for="shortcut in shortcuts" :key="shortcut.id" :class="$style.item">
        <div :class="$style.title">
          <span>/{{ shortcut.command }}</span>
          <DropDown :menu="menu(shortcut)">
            <Button
              variant="ghost"
              theme="neutral"
              size="1"
              icon="dotGrid1x3Horizontal"
            />
          </DropDown>
        </div>
        <p :class="$style.subtitle">{{ shortcut.description }}</p>
      </li>
    </ul>
    <div v-if="shortcuts.length === 0" :class="$style['add-new']">
      <p
        v-if="search.length > 0 && shortcuts.length === 0"
        :class="$style.heading"
      >
        Whoops
      </p>
      <p
        v-if="search.length === 0 && shortcuts.length === 0"
        :class="$style.heading"
      >
        You don't have any shortcuts yet.
      </p>
      <p
        v-if="search.length > 0 && shortcuts.length === 0"
        :class="$style.description"
      >
        Looks like there's no shortcut with this name. Would you like to create
        one?
      </p>
      <p
        v-if="search.length === 0 && shortcuts.length === 0"
        :class="$style.description"
      >
        Create your first shortcut and speed up your messaging with your
        customers!
      </p>
      <Button
        size="2"
        theme="neutral"
        variant="soft"
        :class="$style.cta"
        @click="handleAdd()"
        >Create new shortcut</Button
      >
    </div>
  </div>
  <Popup ref="formRef" :title="editShortcut ? 'Edit Shortcut' : 'New Shortcut'">
    <Form :shortcut="editShortcut" />
  </Popup>
</template>
<style module lang="scss">
.actions {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.search {
  flex-grow: 1;
}
.content {
  width: 380px;
  height: 450px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.list {
  margin-top: 12px;
}
.item {
  border-bottom: 1px solid var(--neutral-alpha-4);
  padding: 12px 0px;

  .title {
    @extend .semibold-3;
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    @extend .regular-2;
    color: var(--neutral-11);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.add-new {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .heading {
    @extend .semibold-3;
    margin-bottom: 8px;
  }
  .description {
    @extend .regular-3;
    color: var(--neutral-11);
    width: 70%;
  }

  .cta {
    margin: 24px 0px;
  }
}
</style>
