<script setup lang="ts">
import { ref } from 'vue'
import { Icon, Popover } from '.'
import { type DropDownItem } from './types'
import Menu from './Menu/Menu.vue'
import MenuItem from './Menu/MenuItem.vue'

type PopoverInstance = InstanceType<typeof Popover>

interface DropDownProps {
  menu: DropDownItem[]
  placement?: PopoverInstance['$props']['placement']
  offset?: PopoverInstance['$props']['offset']
  closeOnSelect?: boolean
  width?: number
}

const props = defineProps<DropDownProps>()

const highlighted = ref<number>()

const handleItemClick = (item: DropDownItem, close?: () => void) => {
  item.handler && item.handler()

  if (props.closeOnSelect) {
    close?.()
  }
}
</script>
<template>
  <Popover
    :placement="placement || 'bottom-end'"
    :offset="offset"
    v-bind="$attrs"
  >
    <template #default="data"><slot v-bind="data" /></template>
    <template #content="{ close }">
      <Menu :width="width" @click.prevent @mousemove="highlighted = undefined">
        <template v-for="(item, index) in menu" :key="index">
          <hr v-if="item.type === 'separator'" />
          <MenuItem
            v-else
            :title="item.title || ''"
            :subtitle="item.subtitle"
            role="menuitem"
            :class="[
              [
                $style.item,
                highlighted === index && $style.focused,
                item.type === 'primary' && $style.primary,
                item.type === 'destructive' && $style.destructive,
              ],
            ]"
            @click="handleItemClick(item, close)"
          >
            <template #icon>
              <Icon v-if="item.icon" :icon="item.icon" :size="16" />
            </template>
          </MenuItem>
        </template>
      </Menu>
    </template>
  </Popover>
</template>
<style module lang="scss">
.item {
  &.primary {
    color: var(--primary-12);
  }
  &.destructive {
    color: var(--danger-9);
  }
}
.hr {
  height: 1px;
  border: none;
  background-color: var(--neutral-100);
  margin: 4px 0;
}
</style>
