<script setup lang="ts">
import { Button, Checkbox, Confirm } from '@/components/common'
import { computed, ref, toRefs } from 'vue'
import AssignListPopout from './AssignListPopout.vue'
import StatusListPopout from './StatusListPopout.vue'
import type { TicketStatus } from '../../models/ticket'
import { useInboxStore } from '../../store'
import { showToast } from '@/utils'

const store = useInboxStore()

const confirmDeletePopup = ref<typeof Confirm>()

const props = defineProps<{
  selectedIds: number[]
  total: number
}>()

const { selectedIds, total } = toRefs(props)

const emit = defineEmits<{
  'check-all': [value: boolean]
}>()

const amount = computed(() => selectedIds.value.length)

const isIntermediate = computed(() => {
  return !!amount.value && amount.value !== total.value
})

const handleCheckbox = () => {
  if (amount.value === total.value) {
    emit('check-all', false)
    return
  }

  emit('check-all', true)
}

const handleDelete = async () => {
  store.deleteTicketsBulk(selectedIds.value).then(() => {
    emit('check-all', false)
    showToast({
      type: 'success',
      text: `(${amount.value}) tickets were deleted`,
    })
  })
}

const handleDeleteConfirm = () => {
  confirmDeletePopup.value?.show({
    title: 'Delete tickets',
    message: `You're about to delete ${amount.value} tickets. This action is permanent and cannot be undone. You and your team members will lose access to those tickets forever.`,
    actions: [
      {
        title: `Delete (${amount.value}) tickets`,
        theme: 'danger',
        handler: handleDelete,
      },
    ],
  })
}

const handleAssign = (to: number | null) => {
  const assignTo = [to].filter((e) => Number.isSafeInteger(e)) as number[]

  store.updateAssigneeBulk(selectedIds.value, assignTo).then(() => {
    showToast({
      type: 'success',
      text: `Assignee for (${amount.value}) tickets was changed`,
    })
  })
}

const handleStatus = (status: TicketStatus) => {
  store.updateStatusBulk(selectedIds.value, status).then(() => {
    showToast({
      type: 'success',
      text: `Status for (${amount.value}) tickets was changed`,
    })
  })
}
</script>

<template>
  <tr :class="$style.row">
    <th>
      <Checkbox
        size="1"
        :intermediate="isIntermediate"
        :model-value="amount === total"
        @click.prevent="handleCheckbox"
      />
    </th>

    <th colspan="5">
      <div :class="$style.content">
        <div :class="$style.count">{{ amount }} selected</div>

        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          :class="$style.link"
          icon="trashCan2"
          @click.prevent="handleDeleteConfirm"
        >
          Delete
        </Button>

        <AssignListPopout
          :selected-ids="selectedIds"
          @assignee-selected="handleAssign"
        />

        <StatusListPopout
          :selected-ids="selectedIds"
          @status-selected="handleStatus"
        />
      </div>
    </th>
  </tr>

  <Confirm ref="confirmDeletePopup" />
</template>

<style module lang="scss">
.row {
  background-color: var(--neutral-alpha-3);
  border-bottom: 1px solid var(--neutral-alpha-4);

  th {
    padding: 10px 0;
    vertical-align: middle;

    &:first-of-type {
      border-top-left-radius: 12px;
      padding-left: 20px;
      padding-right: 16px;
    }

    &:last-of-type {
      border-top-right-radius: 12px;
      padding-right: 20px;
      vertical-align: middle;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 16px;

    .count {
      @extend .medium-3;

      flex: 1;
      text-align: left;
    }
  }

  .link {
    padding: 0;
    color: var(--neutral-alpha-11);

    &:hover {
      color: var(--neutral-alpha-12);
      background-color: transparent;
    }
  }
}
</style>
