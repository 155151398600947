import { createRouter, createWebHistory } from 'vue-router'

import UIKit from '@/pages/UIKit.vue'
import Splash from '@/pages/Splash.vue'
import Dashboard from '@/pages/Dashboard/index.vue'

import AuthLogin from '@/pages/auth/Login.vue'
import AuthForgot from '@/pages/auth/Forgot.vue'
import AuthReset from '@/pages/auth/Reset.vue'
import AuthSignupWrapper from './pages/auth/SignupWrapper.vue'

import Onboarding from '@/pages/Onboarding/index.vue'
import OnboardingMarketplace from '@/pages/Onboarding/Marketplace.vue'
import OnboardingMarketplaceApp from '@/pages/Onboarding/App.vue'

import InboxRoutes from '@/modules/inbox/routes'
import SettingsRoutes from '@/modules/settings/routes'
import CRMRoutes from '@/modules/crm/routes'
import TaskRoutes from '@/modules/tasks/routes'
import Notifications from '@/pages/Notifications.vue'

import Marketplace from './pages/Marketplace/index.vue'
import MarketPlaceApp from './pages/Marketplace/App.vue'

const routes = [
  {
    name: 'uikit',
    path: '/ui-kit',
    component: UIKit,
    meta: { title: 'UI Kit' },
  },
  {
    name: 'login',
    path: '/login',
    component: AuthLogin,
    meta: { title: 'Login' },
  },
  {
    name: 'signup',
    path: '/signup',
    component: AuthSignupWrapper,
    meta: { title: 'Signup' },
  },
  {
    name: 'forgot',
    path: '/forgot',
    component: AuthForgot,
    meta: { title: 'Forgot Password' },
  },
  {
    name: 'reset',
    path: '/reset/:token',
    component: AuthReset,
    meta: { title: 'Reset Password' },
  },
  {
    name: 'accept',
    path: '/accept-invite',
    component: AuthSignupWrapper,
    meta: { title: 'Accept Invite' },
  },

  {
    name: 'Splash',
    path: '/',
    component: Splash,
    meta: { title: 'Spash' },
  },

  {
    name: 'Onboarding',
    path: '/onboarding',
    component: Onboarding,
    meta: { title: 'On Boarding' },
  },

  {
    name: 'OnboardingMarketplace',
    path: '/:workspaceId(\\d+)/onboarding/marketplace',
    component: OnboardingMarketplace,
    meta: { title: 'Marketplace' },
  },

  {
    name: 'OnboardingMarketplaceApp',
    path: '/:workspaceId(\\d+)/onboarding/marketplace/:appId',
    component: OnboardingMarketplaceApp,
    meta: { title: 'Application' },
  },

  {
    name: 'Dashboard',
    path: '/:workspaceId(\\d+)',
    component: Dashboard,
    meta: { title: 'Dashboard' },
    children: [
      ...SettingsRoutes,
      ...InboxRoutes,
      ...CRMRoutes,
      ...TaskRoutes,
      {
        name: 'Notifications',
        path: 'notifications',
        component: Notifications,
        meta: { title: 'Notifications' },
      },
      {
        name: 'Marketplace',
        path: 'marketplace',
        component: Marketplace,
        meta: { title: 'Marketplace' },
      },
      {
        name: 'MarketplaceApp',
        path: 'marketplace/:appId',
        component: MarketPlaceApp,
        meta: { title: 'Application' },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, _from, next) => {
  document.title = to.meta.title as string
  next()
})
export default router
