import Base from './index.vue'
import Inbox from './Inbox.vue'
import Thread from './Thread.vue'
import ComingSoon from '@/pages/ComingSoon.vue'
import Widget from './Widget.vue'
import Setup from './Setup.vue'
import WidgetSetup from './Setup/WidgetSetup.vue'

export default [
  {
    name: 'HelpDesk',
    path: 'inbox',
    components: { default: Base },
    meta: { title: 'Help Desk' },
    redirect: { name: 'HDInbox' },
    children: [
      {
        name: 'HDInbox',
        path: '',
        meta: { title: 'Inbox' },
        components: { default: Inbox },
      },
      {
        name: 'HDThread',
        path: 'thread/:id',
        component: Thread,
        meta: { title: 'Inbox' },
      },
      {
        name: 'HDReports',
        path: 'reports',
        component: ComingSoon,
        meta: { title: 'Reports' },
        props: {
          icon: 'chart5',
          title: 'Reports',
          subtitle: 'See all the data about your inbox emails!',
          description: `Unfortunately, Reports are not available at that moment. We're currently
          working hard to bring Inbox Reports to Vanilla. Stay tuned for future
          updates!`,
        },
      },
      {
        name: 'HDWidget',
        path: 'widget',
        meta: { title: 'Widget' },
        component: Widget,
      },
      {
        name: 'InboxSetup',
        meta: { title: 'Inbox Setup' },
        path: 'setup',
        component: Setup,
      },
      {
        name: 'WidgetSetup',
        path: 'setup/widget',
        meta: { title: 'Widget Setup' },
        component: WidgetSetup,
      },
    ],
  },
]
