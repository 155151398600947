<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { ImageAttachments } from './constants'
import { type File } from '@modules/inbox/models/message'

const props = defineProps<{
  files: File[]
}>()
const { files } = toRefs(props)

const displayFiles = computed(() => {
  return files.value.filter((file) => ImageAttachments.includes(file.mime))
})
</script>
<template>
  <div v-if="displayFiles.length" :class="$style.container">
    <a
      v-for="(file, index) in displayFiles"
      :key="index"
      :class="$style.thumbnail"
      :href="file.url"
      target="_blank"
      :style="{ backgroundImage: `url('${file.url}')` }"
    >
    </a>
  </div>
</template>
<style module lang="scss">
.container {
  display: flex;
  width: 80%;
  gap: 8px;
  margin-top: 8px;
  border-radius: 8px;
  padding: 8px;
  flex-wrap: wrap;
}
.thumbnail {
  display: block;
  width: 180px;
  height: 180px;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  border: 1px solid var(--neutral-alpha-4);
}
</style>
