<script setup lang="ts">
import Paywall from '@/components/Dashboard/Paywall.vue'

import { useInboxStore } from './store'

const store = useInboxStore()
store.getShortcuts()
</script>
<template>
  <Paywall>
    <router-view />
  </Paywall>
</template>
