<script setup lang="ts">
import Icon from '@/components/common/Icon.vue'
import { toRefs } from 'vue'
import { TaskPriority } from '../models/task'

type IconInstance = InstanceType<typeof Icon>

const props = defineProps<{
  priority: TaskPriority
  size?: number
}>()
const { priority } = toRefs(props)

const map: Record<TaskPriority, IconInstance['$props']['icon']> = {
  [TaskPriority.none]: 'priority0',
  [TaskPriority.low]: 'priority1',
  [TaskPriority.medium]: 'priority2',
  [TaskPriority.high]: 'priority3',
  [TaskPriority.urgent]: 'circleExclamation',
}
</script>
<template>
  <Icon
    :size="size || 20"
    :icon="map[priority]"
    :class="[priority === TaskPriority.urgent && $style.urgent]"
  />
</template>
<style module lang="scss">
.urgent {
  color: var(--danger-9);
}
</style>
