<script setup lang="ts">
import { Button, Icon, UpgradePopupKey } from '@/components/common'
import { useWorkspaceApi } from '@/composables/api'
import { Subscription } from '@/models/Subscription'
import { useWorkspaceStore } from '@/store/workspace'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, inject, toRefs } from 'vue'

const props = defineProps<{
  subscription?: Subscription
}>()
const { subscription } = toRefs(props)

const openUpgrade = inject(UpgradePopupKey.show)

const { api, isStatus } = useWorkspaceApi()
const store = useWorkspaceStore()
const { apps } = storeToRefs(store)

const claimedApp = computed(() => apps.value.find((app) => app.claimed))
const isPremiumActive = computed(
  () => subscription.value && !subscription.value.endsAt,
)

const handleUpgrade = () => {
  if (isPremiumActive.value) return
  if (
    subscription.value?.endsAt &&
    DateTime.fromISO(subscription.value.endsAt) > DateTime.now()
  ) {
    api.workspace.resumePremium().then(() => {
      window.location.reload()
    })
  } else {
    openUpgrade?.()
  }
}

const handleDowngrade = () => {
  if (!isPremiumActive.value) return
  api.workspace.cancelPremium().then(() => {
    window.location.reload()
  })
}
</script>
<template>
  <div :class="$style.pricing">
    <div :class="$style.plan">
      <div>
        <h2 :class="$style.heading">Free</h2>
        <p :class="$style.description">
          Get access to one app for free. Forever. Great to try Vanilla.
        </p>
      </div>
      <span :class="$style.price">USD $0/user/month</span>
      <Button
        :variant="isPremiumActive ? 'outline' : 'soft'"
        size="3"
        theme="neutral"
        :loading="isStatus('deleting')"
        @click="handleDowngrade"
      >
        {{ isPremiumActive ? 'Downgrade' : 'Your Current Plan' }}</Button
      >
      <div v-if="claimedApp" :class="$style.claimedApps">
        <p :class="$style.heading">Claimed free app</p>
        <div :class="$style.app">
          <Icon :icon="claimedApp.icon" :size="20" />
          <span :class="$style.name">{{ claimedApp.name }}</span>
        </div>
      </div>
    </div>
    <div :class="$style.plan">
      <div>
        <h2 :class="$style.heading">Premium</h2>
        <p :class="$style.description">
          Unlimited access to all existing and future apps in Vanilla.
        </p>
      </div>

      <span :class="$style.price">USD $20/user/month</span>
      <Button
        size="3"
        :variant="isPremiumActive ? 'outline' : 'solid'"
        theme="neutral"
        :loading="isStatus('posting')"
        @click="handleUpgrade"
        >{{ isPremiumActive ? 'Your Current Plan' : 'Upgrade' }}</Button
      >

      <ul>
        <li>
          <Icon :class="$style.icon" icon="circleCheck" :size="16" />
          <p>Full access to all apps</p>
        </li>
        <li>
          <Icon :class="$style.icon" icon="circleCheck" :size="16" />
          <p>Sync between apps</p>
        </li>
        <li>
          <Icon :class="$style.icon" icon="circleCheck" :size="16" />
          <p>Priority support</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<style module lang="scss">
.pricing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  .plan {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .heading {
      @extend .semibold-4;
      color: var(--neutral-12);
    }

    .description {
      @extend .regular-3;
      color: var(--neutral-11);
    }

    .price {
      @extend .medium-3;
      color: var(--neutral-12);
    }

    .claimedApps {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .heading {
        @extend .medium-2;
        color: var(--neutral-11);
      }

      .app {
        display: flex;
        flex-direction: row;
        gap: 6px;

        .name {
          @extend .semibold-3;
          color: var(--neutral-12);
        }
      }
    }

    ul {
      display: grid;
      gap: 12px;

      li {
        display: flex;
        gap: 8px;
        .icon {
          margin: auto 0;
        }
        p {
          @extend .regular-3;
          color: var(--neutral-12);
          margin: auto 0;
        }
      }
    }
  }
}
</style>
