<script setup lang="ts">
import { Subscription } from '@/models/Subscription'
import { useWorkspaceStore } from '@/store/workspace'
import { ISODate } from '@/utils/helpers'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  subscription?: Subscription
}>()
const { subscription } = toRefs(props)

const store = useWorkspaceStore()
const { members } = storeToRefs(store)

const nextAction = computed<string[]>(() => {
  if (
    subscription.value?.endsAt &&
    DateTime.fromISO(subscription.value.endsAt) < DateTime.now()
  ) {
    return ['Next invoice issue date', '--', '0']
  }
  if (
    subscription.value?.endsAt &&
    DateTime.fromISO(subscription.value.endsAt) > DateTime.now()
  ) {
    return [
      'Ends at',
      ISODate(subscription.value.endsAt, { format: 'dd LLL, yyyy' }),
      '0',
    ]
  }
  if (subscription.value?.renewsAt) {
    return [
      'Next invoice issue date',
      ISODate(subscription.value.renewsAt, { format: 'dd LLL, yyyy' }),
      `${(subscription.value?.quantity || 0) * (subscription.value?.amount || 0)}`,
    ]
  }
  return ['Next invoice issue date', '--', '0']
})
</script>
<template>
  <div :class="$style.infoCards">
    <div :class="$style.infoCard">
      <h5 :class="$style.heading">Members</h5>
      <p :class="$style.data">{{ members.length }} Members</p>
    </div>
    <div :class="$style.infoCard">
      <h5 :class="$style.heading">Invoice Total</h5>
      <p :class="$style.data">${{ nextAction[2] }}</p>
    </div>
    <div :class="$style.infoCard">
      <h5 :class="$style.heading">{{ nextAction[0] }}</h5>
      <p :class="$style.data">
        {{ nextAction[1] }}
      </p>
    </div>
  </div>
</template>

<style module lang="scss">
.infoCards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .heading {
    @extend .medium-3;
    color: var(--neutral-11);
  }

  .data {
    @extend .semibold-4;
    color: var(--neutral-12);
  }
}
</style>
