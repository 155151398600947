<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  modelValue?: File | File[]
  multiple?: boolean
}>()
const { multiple } = toRefs(props)

const emit = defineEmits<{
  (e: 'update:modelValue', data: File | File[]): void
  (e: 'clear'): void
}>()

const handleSelect = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (!target.files) return
  if (multiple?.value === true) {
    return emit('update:modelValue', Array.from(target.files))
  }
  emit('update:modelValue', target.files[0])
}
</script>
<template>
  <label :class="$style.container">
    <input
      type="file"
      :class="$style.input"
      :multiple="multiple"
      v-bind="$attrs"
      @input="handleSelect"
    />
    <slot></slot>
  </label>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.input {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
}
</style>
