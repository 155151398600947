<script setup lang="ts">
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { useWorkspaceStore } from '@/store/workspace'
import { computed, reactive, ref, watch } from 'vue'
import { useTasksStore } from './store'
import { Button, EmptyView, Popover, Popup } from '@/components/common'
import Row from './components/MyTasks/Row.vue'
import { watchDebounced } from '@vueuse/core'
import { TaskSearch } from './types'
import FilterMenu from './components/FilterMenu.vue'
import FilterSummary from './components/FilterSummary.vue'
import AddTask from './components/AddTask.vue'
import { useTasksTeamStore } from './teamStore'
import { useApplicationStore } from '@/store'

const appStore = useApplicationStore()
const store = useTasksStore()
const tasksTeamStore = useTasksTeamStore()
const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore

const { user } = appStore

store.loadMyTasks()

const addTaskRef = ref<typeof Popup>()

const searchState = reactive<TaskSearch>({})
watchDebounced(
  searchState,
  () => {
    store.loadMyTasks(searchState)
  },
  { debounce: 200 },
)

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Tasks`,
    url: { name: 'Tasks' },
  },
  {
    title: 'My tasks',
  },
])

const handleAddTask = () => {
  tasksTeamStore.isAddTaskOpen = true
}

watch(
  () => tasksTeamStore.isAddTaskOpen,
  (value) => {
    value && addTaskRef.value?.show()
    !value && addTaskRef.value?.hide()
  },
)
</script>
<template>
  <Page>
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
      <Button size="2" variant="outline" theme="neutral" @click="handleAddTask"
        >Create</Button
      >
    </template>
    <div :class="$style['top-bar']">
      <h2 :class="$style.heading">
        My tasks <span>{{ store.tasks.length }} tasks</span>
      </h2>
      <Popover placement="bottom-end">
        <template #content>
          <FilterMenu
            mode="mine"
            :search="searchState"
            @update="(data) => Object.assign(searchState, data)"
          />
        </template>
        <Button variant="soft" theme="neutral" size="2" icon="filter1"
          >Filters</Button
        >
      </Popover>
    </div>
    <FilterSummary
      v-if="Object.values(searchState).filter(Boolean).length > 0"
      mode="mine"
      :search="searchState"
      @update="(data) => Object.assign(searchState, data)"
    />
    <EmptyView
      v-if="store.tasks.length === 0 && store.isStatus('loaded')"
      :class="$style.empty"
      icon="thumbsUp"
      title="You're a rockstar!"
      description="You got nothing left. You're so productive. You're loved!"
    >
      <Button size="2" variant="solid" theme="neutral" @click="handleAddTask"
        >Create Task</Button
      >
    </EmptyView>
    <template v-else>
      <table :class="$style.table">
        <thead>
          <th :class="$style.status"></th>
          <th :class="$style.status"></th>
          <th :class="$style.team"></th>
          <th :class="$style['task-no']"></th>
          <th></th>
          <th></th>
          <th :class="$style.status"></th>
        </thead>
        <tbody>
          <Row v-for="task in store.tasks" :key="task.id" :task="task" />
        </tbody>
      </table>
    </template>
    <Popup
      ref="addTaskRef"
      :persistent="true"
      @hide="tasksTeamStore.isAddTaskOpen = false"
    >
      <AddTask
        :team="store.teams.length ? store.teams[0] : undefined"
        :assignee="user"
        :team-editable="true"
        :description-height="200"
        @cancel="tasksTeamStore.isAddTaskOpen = false"
      />
    </Popup>
  </Page>
</template>
<style module lang="scss">
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .heading {
    @extend .semibold-6;
    margin: var(--page-margin) 0px;

    span {
      @extend .regular-3;
      color: var(--neutral-11);
      padding-left: 8px;
    }
  }
}
.empty {
  margin-top: 64px;
}
.table {
  width: 100%;

  tr {
    td {
      padding: 16px 0px;
      vertical-align: middle;
      transition: background-color 0.1s;
      padding-left: 16px;

      &:last-child {
        padding-right: 16px;
      }
    }

    &:hover {
      td {
        background-color: var(--neutral-alpha-3);
      }
    }
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid var(--neutral-alpha-3);
    }
  }
}
.status {
  width: 32px;
}
.team {
  width: 75px;
}
.task-no {
  width: 80px;
}
</style>
