import { toast, type ToastOptions, type ToastType } from 'vue3-toastify'

export const showToast = ({
  text,
  type = 'success',
  duration = 1000,
}: {
  text: string
  type?: ToastType
  duration?: number
}) => {
  toast(text, {
    autoClose: duration,
    type,
  } as ToastOptions)
}
