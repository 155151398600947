<script setup lang="ts">
import { useApplicationStore } from '@/store'
import { clsx } from 'clsx'
import { computed, inject, useSlots } from 'vue'
import { ActiveAppKey, Button } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import Icon from '../Icon.vue'
import { DateTime } from 'luxon'

const activeApp = inject(ActiveAppKey)
const store = useWorkspaceStore()

const { fullScreen } = defineProps<{
  fullScreen?: boolean
}>()

const slots = useSlots()
const { sidebars, toggleLeftBar, toggleRightBar } = useApplicationStore()

const hasRightBar = computed(
  () => sidebars.right && (slots.details || slots.detailsTopBar),
)

const trialDaysLeft = computed(() => {
  if (!activeApp?.value) return 0
  if (activeApp?.value?.claimed) return 0

  if (store.workspace?.trialEndsAt) {
    const endsAt = DateTime.fromISO(store.workspace.trialEndsAt)
    const diff = endsAt.diff(DateTime.now(), ['days'])
    return Math.ceil(diff.days)
  }

  return 0
})
</script>
<template>
  <div
    id="page"
    :class="
      clsx(
        $style.container,
        !sidebars.left && $style.noLeft,
        !hasRightBar && $style.noRight,
      )
    "
  >
    <div :class="$style.topBar">
      <Button
        icon="layoutAlignLeft"
        theme="neutral"
        variant="ghost"
        size="2"
        @click="toggleLeftBar"
      ></Button>
      <div :class="$style.slot">
        <slot name="topBar" />
      </div>
      <Button
        v-if="slots.details || slots.detailsTopBar"
        icon="layoutAlignRight"
        theme="neutral"
        variant="ghost"
        size="2"
        @click="toggleRightBar"
      ></Button>
    </div>
    <div v-if="trialDaysLeft > 0" :class="$style.trialBanner">
      <Icon icon="circleExclamation" :size="20" />
      You're on a free trial of this app. {{ trialDaysLeft }} days left.
    </div>
    <div :class="[$style.content, fullScreen && $style.fullScreen]">
      <slot />
    </div>
  </div>
  <Transition name="slide-right">
    <div v-if="hasRightBar" :class="$style.details">
      <div v-if="$slots.detailsTopBar" :class="$style.topBar">
        <slot name="detailsTopBar" />
      </div>
      <slot name="details" />
    </div>
  </Transition>
</template>
<style module lang="scss">
.topBar {
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid var(--neutral-alpha-4);
  padding: 0px 16px;
  justify-content: space-between;
  z-index: 1;
}

.container {
  @extend .transition;
  margin: 0px var(--sidebar-width);
  background-color: var(--panel-1, #fff);
  padding-top: 56px;
  transition-property: margin-left, margin-right;

  .topBar {
    @extend .transition;
    position: fixed;
    left: var(--sidebar-width);
    right: var(--sidebar-width);
    top: 0;
    background-color: var(--panel-1);
    justify-content: unset;
    transition-property: left, right;

    .slot {
      @extend .medium-3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      padding: 0px 16px;
    }
  }
}

.content {
  padding: 0px var(--page-margin);
  display: flex;
  flex-direction: column;

  &.fullScreen {
    height: calc(100vh - 56px);
  }
}

.noLeft {
  margin-left: 0;

  .topBar {
    left: 0;
  }
}

.noRight {
  margin-right: 0;

  .topBar {
    right: 0;
  }
}

.details {
  width: var(--sidebar-width);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid var(--neutral-alpha-4);
  background-color: var(--panel-1);

  .topBar {
    @extend .medium-3;
    justify-content: space-between;
  }
}

.trialBanner {
  @extend .medium-3;
  display: flex;
  align-items: center;
  padding: 12px 32px;
  background-color: var(--accent-alpha-3);
  color: var(--accent-11);
  column-gap: 8px;
}
</style>
