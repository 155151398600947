import Base from './index.vue'
import Team from './Team.vue'
import MyTasks from './MyTasks.vue'
import Task from './Task.vue'
import type { RouteRecordRaw } from 'vue-router'
import Teams from './Teams.vue'

const routes: RouteRecordRaw[] = [
  {
    name: 'Tasks',
    path: 'tasks',
    meta: { title: 'Tasks' },
    components: { default: Base },
    redirect: { name: 'TasksMine' },
    children: [
      {
        name: 'TasksMine',
        path: '',
        component: MyTasks,
        meta: { title: 'My Tasks' },
      },
      {
        name: 'TasksTeams',
        path: 'teams',
        component: Teams,
        meta: { title: 'Teams' },
      },
      {
        name: 'TasksTeam',
        path: 'team/:teamId',
        component: Team,
        meta: { title: 'Team' },
      },
      {
        name: 'TasksTask',
        path: ':teamId/task/:taskNumber',
        component: Task,
        meta: { title: 'Task' },
      },
    ],
  },
]

export default routes
