<script setup lang="ts">
import { useWorkspaceApi } from '@/composables/api'
import SettingsHeader from './SettingsHeader.vue'
import InfoCards from './billing/InfoCards.vue'
import Pricing from './billing/Pricing.vue'
import { Subscription } from '@/models/Subscription'
import { ref } from 'vue'
import { useWorkspaceStore } from '@/store/workspace'

const subscription = ref<Subscription>()

const store = useWorkspaceStore()
store.getMembers()

const { api } = useWorkspaceApi()
api.workspace.subscription().then((data) => (subscription.value = data))

const handleGeneratePortalLink = () => {
  api.workspace.portalLink().then((data) => (window.location.href = data))
}
</script>
<template>
  <SettingsHeader title="Billing" subtitle="Manage your Vanilla plan" />
  <hr :class="$style.separator" />

  <InfoCards :subscription="subscription" />
  <hr :class="$style.separator" />
  <Pricing :subscription="subscription" />

  <div :class="$style.links">
    <span :class="$style.link" @click="handleGeneratePortalLink"
      >Change billing info
    </span>
    <span :class="$style.link" @click="handleGeneratePortalLink"
      >Manage subscription
    </span>
  </div>
</template>
<style module lang="scss">
@import url('./styles.scss');
.links {
  display: grid;
  gap: 8px;
  margin-top: 24px;
  .link {
    color: var(--neutral-alpha-11);
    border-bottom: 1px solid var(--neutral-alpha-7);
    width: max-content;
    cursor: pointer;
    @extend .medium-3;
  }
}
</style>
