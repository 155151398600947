<script setup lang="ts">
import { useWorkspaceStore } from '@/store/workspace'
import { Menu } from '.'
import { computed, ref } from 'vue'
import type User from '@/models/user'
import { clone } from '@/utils'
import MenuItem from './Menu/MenuItem.vue'
import Avatar from './Avatar.vue'
import Checkbox from './Checkbox.vue'
import TextField from './TextField.vue'
import Icon from './Icon.vue'

const emits = defineEmits<{
  (e: 'update', users: User[]): void
}>()

const props = defineProps<{
  assignees?: User[]
  ids?: number[]
  onlyMenu?: boolean
  singleSelection?: boolean
}>()

const { members } = useWorkspaceStore()
const search = ref<string>('')

const highlighted = ref<number>()

const assignees = computed(
  () => props.assignees || members.filter((m) => props.ids?.includes(m.id)),
)

const selectionIds = computed(() => assignees.value.map((user) => user.id))

const displayedMembers = computed(() => {
  const users = members.filter((member) =>
    member.name.toLowerCase().includes(search.value.toLowerCase()),
  )

  return users
})

const handleCheckbox = (user: User) => {
  if (props.singleSelection) {
    return emits('update', [user])
  }

  const index = selectionIds.value.indexOf(user.id)
  const selection = clone(assignees.value)

  if (index === -1) {
    selection.push(user)
  } else {
    selection.splice(index, 1)
  }
  emits('update', selection)
}

const handleUnassign = () => {
  emits('update', [])
}

const performSearch = (query: string) => {
  search.value = query || ''
  highlighted.value === undefined
}

const handleDownKey = () => {
  if (highlighted.value === undefined) return (highlighted.value = 0)
  highlighted.value = Math.min(
    displayedMembers.value.length - 1,
    highlighted.value + 1,
  )
}

const handleUpKey = () => {
  if (highlighted.value === undefined) return (highlighted.value = 0)
  highlighted.value = Math.max(0, highlighted.value - 1)
}

const handleEnter = () => {
  if (highlighted.value === undefined) return
  const item = displayedMembers.value[highlighted.value]
  emits('update', [item])
}

defineExpose({
  performSearch,
  handleDownKey,
  handleUpKey,
  handleEnter,
})
</script>
<template>
  <Menu :width="248">
    <template v-if="!onlyMenu">
      <TextField v-model="search" placeholder="Search">
        <template #pre>
          <Icon icon="magnifyingGlass" :size="18" />
        </template>
      </TextField>

      <hr />
    </template>
    <div :class="$style.members">
      <MenuItem v-if="!onlyMenu" title="No assignee" @click="handleUnassign">
        <template #icon>
          <Checkbox
            size="1"
            :is-radio="$props.singleSelection"
            :model-value="selectionIds.length === 0"
            @update:model-value="handleUnassign"
          />
          <Icon icon="unassignedIcon" :size="24" :class="$style.unassigned" />
        </template>
      </MenuItem>
      <MenuItem
        v-for="(member, index) in displayedMembers"
        :key="member.id"
        :title="member.name"
        :highlighted="index === highlighted"
        @click="handleCheckbox(member)"
      >
        <template #icon>
          <Checkbox
            v-if="!onlyMenu"
            size="1"
            :is-radio="$props.singleSelection"
            :model-value="selectionIds.includes(member.id)"
            @update:model-value="handleCheckbox(member)"
          />
          <Avatar :title="member.name" :src="member.avatar" :size="24" />
        </template>
      </MenuItem>
    </div>
    <slot v-if="!displayedMembers?.length" name="empty" />
  </Menu>
</template>
<style module lang="scss">
.unassigned {
  color: var(--neutral-alpha-8);
}
.members {
  max-height: 250px;
  overflow: auto;
}
</style>
