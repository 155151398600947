<script setup lang="ts">
import { Button, Badge } from '@/components/common'
import router from '@/routes'
import { ref } from 'vue'

const banner = ref<boolean>(true)

const redirectToBilling = () => {
  router.push({ name: 'BillingSettings' })
}
</script>

<template>
  <div v-if="banner" :class="$style.banner">
    <div :class="$style.topActions">
      <Button
        theme="neutral"
        variant="ghost"
        size="1"
        icon="crossSmall"
        @click="banner = false"
      />
    </div>
    <Badge size="2" :class="$style.badgeDiscount">50% off</Badge>
    <div :class="$style.content">
      <h2>Premium</h2>
      <p>Get unlimited access to all existing and future apps in Vanilla.</p>
    </div>

    <Button size="2" variant="solid" theme="neutral" @click="redirectToBilling"
      >Upgrade</Button
    >
  </div>
</template>

<style module lang="scss">
.banner {
  padding: 12px;
  border-radius: 8px;
  margin: 12px;
  background-color: var(--panel-2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  .topActions {
    display: flex;
    justify-content: flex-end;
  }

  .badgeDiscount {
    background: var(--accent-alpha-3);
    color: var(--accent-alpha-11);
  }

  .content {
    margin: 16px auto;
    h2 {
      @extend .medium-3;
      color: var(--neutral-12);
    }
    p {
      color: var(--neutral-11);
      @extend .regular-3;
    }
  }
}
</style>
