<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { ImageAttachments } from './constants'
import { type File } from '@modules/inbox/models/message'
import { Icon } from '@/components/common'

const props = defineProps<{
  files: File[]
}>()
const { files } = toRefs(props)

const displayFiles = computed(() =>
  files.value.filter((file) => !ImageAttachments.includes(file.mime)),
)
</script>
<template>
  <div :class="$style.grid">
    <a
      v-for="(file, index) in displayFiles"
      :key="index"
      :class="$style.container"
      :href="file.url"
      target="_blank"
    >
      <div :class="$style.icon">
        <Icon icon="fileBend" :size="16" />
      </div>
      <div :class="$style.content">
        {{ file.name }}
      </div>
      <div :class="$style.icon">
        <Icon icon="arrowInbox" :size="16" />
      </div>
    </a>
  </div>
</template>
<style module lang="scss">
.grid {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
}
.container {
  @extend .medium-3;
  display: flex;
  align-items: center;
  background: var(--neutral-alpha-3);
  border-radius: 4px;
  padding: 6px;
}
.icon {
  color: var(--neutral-11);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 12px;
}
</style>
