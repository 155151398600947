<script setup lang="ts">
import { AutoComplete, Avatar, Button } from '@/components/common'
import { useWorkspaceApi } from '@/composables/api'
import User from '@/models/user'
import { reactive, toRefs } from 'vue'
import { Team } from '../models/team'
import { useWorkspaceStore } from '@/store/workspace'
import { useTasksStore } from '../store'
import { useTasksTeamStore } from '../teamStore'

const props = defineProps<{
  team: Team
}>()
const { team } = toRefs(props)

const taskStore = useTasksStore()
const tasksTeamStore = useTasksTeamStore()
const store = useWorkspaceStore()
const { api } = useWorkspaceApi()
const state = reactive<{
  members: User[]
}>({
  members: [],
})

api.task.loadTeamMembers(team.value.id).then((data) => (state.members = data))

const handleAdd = (user: User) => {
  const isExisting = !!state.members.find((member) => member.id === user.id)

  if (!isExisting) {
    state.members.push(user)
    taskStore.addTeamMember(team.value.id, user.id)

    tasksTeamStore.addTeamMember(team.value.id, user)
  }
}

const handleDelete = (index: number) => {
  const [user] = state.members.splice(index, 1)
  taskStore.deleteTeamMember(team.value.id, user.id)

  tasksTeamStore.removeTeamMember(team.value.id, user.id)
}
</script>
<template>
  <div :class="['popup-content', $style.container]">
    <AutoComplete
      size="2"
      :items="store.members"
      :clear-on-select="true"
      item-key="id"
      title-key="name"
      subtitle-key="email"
      placeholder="Invite others by name or email"
      @select="handleAdd"
    >
      <template #icon="{ item }">
        <Avatar :size="32" :title="item.name" :src="item.avatar" />
      </template>
    </AutoComplete>
    <h2 :class="$style.heading">Who has access</h2>
    <ul v-if="state.members.length" :class="$style.list">
      <li v-for="(member, index) in state.members" :key="member.id">
        <Avatar :size="32" :title="member.name" :src="member.avatar" />
        <div :class="$style.details">
          <b>{{ member.name }}</b>
          <p>{{ member.email }}</p>
        </div>
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          icon="crossSmall"
          @click="() => handleDelete(index)"
        />
      </li>
    </ul>
    <p v-else :class="$style.empty">
      Nobody has access to this team yet. Invite workspace members to give the
      access to this team.
    </p>
  </div>
</template>
<style module lang="scss">
.container {
  width: 420px;
}
.heading {
  @extend .medium-2;
  margin: 24px 0px;
}
.empty {
  @extend .regular-3;
  color: var(--neutral-11);
}
.list {
  margin-bottom: 32px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;

    &:not(:first-child) {
      padding-top: 12px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--neutral-alpha-4);
      padding-bottom: 12px;
    }

    .details {
      flex-grow: 1;
      row-gap: 2px;

      b {
        @extend .medium-3;
      }
      p {
        @extend .regular-2;
        color: var(--neutral-11);
      }
    }
  }
}
</style>
