<script setup lang="ts">
import { Button, Icon, Popover } from '@/components/common'
import type { Search } from '../store'
import { isEqual } from 'lodash'
import { initialFilters, type InitialFilterType } from '../constants'
import { computed } from 'vue'
import FilterMenu from './menus/FilterMenu.vue'
import { statusLabels } from '../models/ticket'

const emit = defineEmits<{
  (e: 'update', data: Search): void
}>()
const { search } = defineProps<{ search: Search }>()

const statusLabel = computed(() => search.status.map((s) => statusLabels[s]))

const handleRemove = (k: keyof InitialFilterType) => {
  emit('update', { ...search, [k]: initialFilters[k] })
}
</script>
<template>
  <hr :class="$style.hr" />
  <div :class="$style.container">
    <div v-if="search.assignee.length > 0" :class="$style.tag">
      {{ search.assignee.length }} assignee
      <Icon icon="crossSmall" :size="16" @click="handleRemove('assignee')" />
    </div>
    <div v-if="search.timeInterval !== 'all'" :class="$style.tag">
      Issued this {{ search.timeInterval }}
      <Icon
        icon="crossSmall"
        :size="16"
        @click="handleRemove('timeInterval')"
      />
    </div>
    <div
      v-if="!isEqual(search.status, initialFilters.status)"
      :class="$style.tag"
      @click="handleRemove('status')"
    >
      {{ statusLabel.join(', ') }}
      <Icon icon="crossSmall" :size="16" />
    </div>
    <Popover>
      <template #content>
        <FilterMenu
          :search="search"
          @update="(data) => $emit('update', data)"
        />
      </template>
      <Button size="2" variant="ghost" theme="neutral" icon="circlePlus" />
    </Popover>
  </div>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.hr {
  margin: 16px 0px;
}
.tag {
  @extend .medium-3;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--neutral-alpha-3);
  padding: 0px 12px;
  height: 32px;
  column-gap: 4px;

  i {
    cursor: pointer;
  }
}
</style>
