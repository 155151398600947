<script setup lang="ts">
import Paywall from '@/components/Dashboard/Paywall.vue'
import { useTasksStore } from './store'
import useWebsockets from '@/composables/websockets.ts'
import { provide } from 'vue'

const socket = useWebsockets()
const store = useTasksStore()
store.loadTeams()

provide('socket', socket)
</script>
<template>
  <Paywall>
    <router-view />
  </Paywall>
</template>
