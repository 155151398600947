import { DateTime } from 'luxon'
import { TaskPriority } from '../models/task'
import { DueDateItem } from '../teamStore'
import { TaskPriorityItem } from '../types'

export const TaskPriorityMenu: TaskPriorityItem[] = [
  {
    id: TaskPriority.urgent,
    name: 'Urgent',
  },
  {
    id: TaskPriority.high,
    name: 'High',
  },
  {
    id: TaskPriority.medium,
    name: 'Medium',
  },
  {
    id: TaskPriority.low,
    name: 'Low',
  },
  {
    id: TaskPriority.none,
    name: 'No priority',
  },
]

export const DueDateMenu: DueDateItem[] = [
  {
    label: 'Today',
    date: DateTime.now().toISODate(),
  },
  {
    label: 'Tomorrow',
    date: DateTime.now().plus({ day: 1 }).toISODate(),
  },
  {
    label: 'Next Week',
    date: DateTime.now().plus({ week: 1 }).startOf('week').toISODate(),
  },
  {
    label: 'Next Month',
    date: DateTime.now().plus({ month: 1 }).startOf('month').toISODate(),
  },
]

export const ColorsMenu = [
  '#1744ff',
  '#693edd',
  '#1e1f24',
  '#e5484d',
  '#ffc53d',
  '#46a758',
]
