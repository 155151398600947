import { TicketStatus } from './models/ticket'
import type { Search } from './store'

export const tabs = [
  {
    title: 'All tickets',
    key: 'all',
  },
  {
    title: 'Live Chat',
    key: 'chat',
  },
  {
    title: 'Mine',
    key: 'mine',
  },
  {
    title: 'Unassigned',
    key: 'unassigned',
  },
]

export type InitialFilterType = Pick<
  Search,
  'status' | 'assignee' | 'timeInterval'
>
export const initialFilters: InitialFilterType = {
  status: [TicketStatus.new, TicketStatus.active],
  assignee: [],
  timeInterval: 'all',
}
