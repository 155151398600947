<script setup lang="ts">
import {
  computed,
  inject,
  nextTick,
  onMounted,
  provide,
  reactive,
  ref,
} from 'vue'
import { useWorkspaceApi } from '@/composables/api'
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import Composer from './components/Composer.vue'
import MessageItem from './components/messages/Message.vue'
import { useRouter } from 'vue-router'
import type Ticket from './models/ticket'
import { TicketStatus } from './models/ticket'
import type Message from './models/message'
import ThreadRightBar from './components/ThreadRightBar.vue'
import { ISODate } from '@/utils/helpers'
import Echo from 'laravel-echo'
import { useWorkspaceStore } from '@/store/workspace'
import { Button, Confirm } from '@/components/common'
import { useInboxStore } from './store'
import { MessageType } from './models/message'
import { showToast } from '@/utils'

interface State {
  ticket: Ticket | null
  messages: Message[]
}

const composerRef = ref<typeof Composer>()

const store = useInboxStore()
const workspaceStore = useWorkspaceStore()
const router = useRouter()
const socket = inject<Echo>('socket')
const scrollRef = ref<HTMLDivElement>()
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const { api } = useWorkspaceApi()

const state = reactive<State>({
  ticket: null,
  messages: [],
})

provide(
  'ticket',
  computed(() => state.ticket),
)

onMounted(async () => {
  const { id } = router.currentRoute.value.params
  const data = await api.inbox.loadMessages(Number(id))
  const { messages = [] } = data

  const readMessageIds: number[] = []

  state.ticket = data
  state.messages = messages.map((message, index) => {
    message.contactId && !message.readerId && readMessageIds.push(message.id)
    const previous = messages[index - 1]
    return prepareMessage(message, previous)
  })

  readMessageIds.length && api.inbox.markAsRead(Number(id), readMessageIds)

  socket &&
    socket
      .private(`inbox.ticket.${state.ticket.id}`)
      .listen('.MessageReceived', async (message: Message) => {
        handleMessage(message)
        await nextTick()

        message.contactId &&
          !message.readerId &&
          api.inbox.markAsRead(Number(id), [message.id])

        if (scrollRef.value)
          scrollRef.value.scrollIntoView({ behavior: 'smooth' })
      })
  await nextTick()
  if (scrollRef.value) scrollRef.value.scrollIntoView()
})

const prepareMessage = (message: Message, previous: Message): Message => {
  const time = ISODate(message.createdAt, { time: true })
  let date: string | null = ISODate(message.createdAt, { format: 'LLL dd' })
  if (previous) {
    const pDate = ISODate(previous.createdAt, { format: 'LLL dd' })
    if (date === pDate) {
      date = null
    }
  }
  const contact = message.contactId ? state.ticket?.contact : undefined
  const operator = message.userId
    ? workspaceStore.members.find((o) => o.id === message.userId)
    : undefined
  return { ...message, date, time, contact, operator }
}
const handleMessage = (message: Message) => {
  const duplicateCheck = state.messages.find((m) => m.id === message.id)
  if (duplicateCheck) return

  const [lastMessage] = state.messages.slice(-1)

  const preparedMessage = prepareMessage(message, lastMessage)

  state.messages = [...state.messages, preparedMessage]

  const isNote = preparedMessage.messageType === MessageType.note
  const isInNotes = !!state.ticket?.notes?.find(
    (note) => note.id === message.id,
  )?.id

  if (isNote && !isInNotes) {
    state.ticket?.notes?.push(preparedMessage)
  }
}

const handleStatus = (status: TicketStatus) => {
  confirmPopup.value &&
    confirmPopup.value.show({
      title: status === TicketStatus.closed ? 'Close ticket' : 'Reopen ticket',
      message:
        status === TicketStatus.closed
          ? 'Are you sure you want to close this ticket? This will end messaging with user and other team members.'
          : 'Are you sure you want to re-open this ticket?',
      actions: [
        {
          title: 'Cancel',
          theme: 'neutral',
          variant: 'ghost',
        },
        {
          title:
            status === TicketStatus.closed ? 'Close Ticket' : 'Reopen ticket',
          theme: 'neutral',
          handler: async () => {
            if (status === TicketStatus.closed) {
              router.replace({ name: 'HDInbox' })
            }

            return state.ticket && store.updateStatus(state.ticket, status)
          },
        },
      ],
    })
}

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspaceStore.workspace?.title} Inbox`,
    url: { name: 'HDInbox' },
  },
  {
    title: state.ticket?.title || '',
  },
])

const handleCreateNote = () => {
  composerRef.value?.switchToNote()
}

const handleCopyLink = () => {
  navigator.clipboard.writeText(window.location.href).then(() => {
    showToast({ text: 'Ticket URL copied to clipboard', type: 'success' })
  })
}
</script>
<template>
  <Page v-if="state.ticket" :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
      <Button
        size="2"
        variant="outline"
        theme="neutral"
        @click="
          state.ticket?.status === TicketStatus.closed
            ? handleStatus(TicketStatus.active)
            : handleStatus(TicketStatus.closed)
        "
        >{{
          state.ticket.status === TicketStatus.closed
            ? 'Reopen Ticket'
            : 'Close ticket'
        }}</Button
      >
    </template>

    <div :class="$style.content">
      <ul v-if="state.messages" :class="$style.pageWidth">
        <MessageItem
          v-for="message in state.messages"
          :key="message.id"
          :message="message"
        />
        <li ref="scrollRef" />
      </ul>
    </div>
    <div :class="$style.pageWidth">
      <Composer ref="composerRef" @send="handleMessage" />
    </div>

    <template #detailsTopBar>
      Details
      <Button
        size="2"
        variant="ghost"
        theme="neutral"
        icon="chainLink"
        @click="handleCopyLink"
      />
    </template>
    <template #details>
      <ThreadRightBar @create-note="handleCreateNote" />
    </template>
    <Confirm ref="confirmPopup" />
  </Page>
</template>
<style module lang="scss">
.pageWidth {
  align-self: center;
  width: 100%;
  max-width: 772px;
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
}
</style>
