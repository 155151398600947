<script setup lang="ts">
import { useWorkspaceStore } from '@/store/workspace'
import { inject } from 'vue'
import { ActiveAppKey, UpgradePopupKey } from '../common'
import Page from '../common/layout/Page.vue'
import EmptyView from '../common/EmptyView.vue'
import { Button } from '../common'

const app = inject(ActiveAppKey)
const showUpgradePopup = inject(UpgradePopupKey.show)
const store = useWorkspaceStore()
</script>
<template>
  <Page v-if="!app" :full-screen="true">
    <div :class="$style.container">
      <EmptyView
        icon="lock"
        :title="`Not found`"
        description="The app you are trying to access doesn't exist or isn't installed. Head over to marketplace to install it"
      >
        <router-link :to="{ name: 'Marketplace' }">
          <Button size="2" variant="solid" theme="neutral"
            >Open Marketplace</Button
          >
        </router-link>
      </EmptyView>
    </div>
  </Page>
  <slot v-else-if="(app && app?.claimed) || store.workspace?.isPremium" />
  <Page v-else :full-screen="true">
    <div :class="$style.container">
      <EmptyView
        icon="lock"
        :title="`Your ${app?.name} free trial has expired.`"
        description="You're trial has expired, please upgrade to Premium to continue using this app again."
      >
        <Button
          size="2"
          variant="solid"
          theme="neutral"
          @click="showUpgradePopup?.()"
          >Upgrade now</Button
        >
      </EmptyView>
    </div>
  </Page>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
