<script setup lang="ts">
import Icon from '@/components/common/Icon.vue'
import useApi from '@/composables/api'
import { provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

defineProps<{ title?: string; subtitle?: string; fullWidth?: boolean }>()

const route = useRoute()
const router = useRouter()
const { api } = useApi(import.meta.env.VITE_API_HOST)
const error = ref<string>()

const { token, error: e } = route.query
error.value = e?.toString()
if (token) {
  api.auth
    .socialVerify(token as string)
    .then(() => router.push({ name: 'Splash' }))
}

provide('error', error)
</script>
<template>
  <nav :class="$style.nav">
    <div :class="$style.logo">
      <router-link to="/">
        <Icon icon="vanillaLogo" :width="80" :height="24" />
      </router-link>
      <slot name="logo" />
    </div>

    <slot name="extra" />
  </nav>

  <div :class="$style.page">
    <div :class="$style.content">
      <div v-if="title || subtitle" :class="$style.labels">
        <div v-if="!!title" :class="$style.title">{{ title }}</div>

        <div v-if="!!subtitle" :class="$style.subtitle">{{ subtitle }}</div>
      </div>
      <slot v-if="!fullWidth" />
    </div>
    <slot v-if="fullWidth" />
  </div>
</template>

<style module lang="scss">
.nav {
  padding: 0 32px;
  height: 56px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
}

.logo {
  @extend .medium-3;
  display: flex;
  align-items: center;
  column-gap: 32px;
}

.page {
  padding: 120px 32px 64px;
  overflow: auto;
  height: 100vh;

  .content {
    max-width: 360px;
    margin: 0 auto;

    .labels {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      margin-bottom: 32px;
    }

    .title {
      @extend .semibold-6;
      text-align: center;
    }

    .subtitle {
      @extend .regular-4;
      text-align: center;
      color: var(--neutral-11);
    }
  }
}
</style>
