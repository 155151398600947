<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'

import { Avatar, Button, FileField, TextField } from '@/components/common'
import { useApplicationStore } from '@/store'
import { showToast } from '@/utils'

import SettingsHeader from './SettingsHeader.vue'
import ChangeEmail from './account/ChangeEmail.vue'
import ChangePassword from './account/ChangePassword.vue'
import DangerZone from './danger-zone/DangerZone.vue'
import DeleteAccount from './account/DeleteAccount.vue'

const appStore = useApplicationStore()
const { user } = appStore

const file = ref<File>()
const filePreview = ref<string>()

const state = reactive({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
})

const stateRules = {
  firstName: { required },
  lastName: { required },
}

const stateForm$ = useVuelidate(stateRules, state, { $scope: false })

const handleFile = (selected: File | File[]) => {
  file.value = selected as File
  const reader = new FileReader()
  reader.readAsDataURL(selected as File)
  reader.onloadend = function () {
    filePreview.value = `${this.result}`
  }
}

const handleSave = async () => {
  await appStore
    .updateUser(state, file.value)
    .then(() => showToast({ text: 'Profile updated!' }))
}
</script>

<template>
  <SettingsHeader title="My Account" subtitle="Manage your Vanilla account" />
  <hr :class="$style.separator" />

  <div :class="$style.container">
    <div :class="$style.field">
      <div :class="$style.label">Profile picture</div>
      <div :class="[$style.value, $style.avatarContainer]">
        <Avatar
          :src="filePreview || user?.avatar"
          :title="user?.name"
          :size="40"
        />

        <FileField
          :multiple="false"
          accept="image/*"
          @update:model-value="handleFile"
        >
          <Button as="span" variant="soft" theme="neutral" size="2">
            Upload new
          </Button>
        </FileField>
      </div>
    </div>

    <div :class="$style.field">
      <div :class="$style.label">First name</div>
      <TextField
        v-model="stateForm$.firstName.$model"
        size="2"
        placeholder="Your first name"
        :class="$style.value"
      />
    </div>

    <div :class="$style.field">
      <div :class="$style.label">Last name</div>
      <TextField
        v-model="stateForm$.lastName.$model"
        size="2"
        placeholder="Your last name"
        :class="$style.value"
      />
    </div>

    <div :class="$style.field">
      <div :class="$style.label">Email</div>
      <div :class="$style.value">
        <ChangeEmail />
      </div>
    </div>

    <div :class="$style.field">
      <div :class="$style.label">Password</div>
      <div :class="$style.value">
        <ChangePassword />
      </div>
    </div>

    <Button
      variant="soft"
      theme="neutral"
      size="2"
      :disabled="stateForm$.$invalid"
      @click="handleSave"
    >
      Save changes
    </Button>
  </div>

  <DangerZone title="Delete your account">
    <DeleteAccount />
  </DangerZone>
</template>

<style module lang="scss">
@import url('./styles.scss');

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
