<script setup lang="ts">
import { Page } from '@/components/common/layout'
import AppCard from '@/components/marketplace/AppCard.vue'
import { AppStatus } from '@/models/app'
import { useApplicationStore } from '@/store'
import { computed } from 'vue'

const store = useApplicationStore()
store.getAllApps()

const essentialApps = computed(() => {
  const essentials = store.apps.filter(
    (app) => app.status === AppStatus.Available,
  )
  return essentials
})
</script>
<template>
  <Page>
    <template #topBar> Marketplace </template>
    <div :class="$style.content">
      <h2 :class="$style.title">Essential Apps</h2>
      <p :class="$style.subtitle">
        Access the most important apps for your startup
      </p>
      <div :class="$style['apps-grid']">
        <AppCard
          v-for="app in essentialApps"
          :key="app.id"
          :app="app"
          @click="
            $router.push({ name: 'MarketplaceApp', params: { appId: app.id } })
          "
        />
      </div>
    </div>
    <div :class="$style.content">
      <h2 :class="$style.title">All Vanilla Apps</h2>
      <p :class="$style.subtitle">Browse all Vanilla apps available for you</p>
      <div :class="$style['apps-grid']">
        <AppCard
          v-for="app in store.apps"
          :key="app.id"
          :app="app"
          @click="
            $router.push({ name: 'MarketplaceApp', params: { appId: app.id } })
          "
        />
      </div>
    </div>
  </Page>
</template>
<style module lang="scss">
.content {
  padding: 32px 0;

  .title {
    @extend .semibold-6;
    color: var(--neutral-12);
  }
  .subtitle {
    @extend .regular-3;
    color: var(--neutral-11);
  }
  .apps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
    margin: 32px 0;
  }
}
</style>
