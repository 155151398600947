<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'

import AuthLayout from '@/components/layouts/AuthLayout.vue'
import useApi from '@/composables/api'
import type { InvitationResponse } from '@/utils/api/types'
import { Avatar, Icon } from '@/components/common'

import AcceptInvite from './AcceptInvite.vue'
import Signup from './Signup.vue'

const route = useRoute()
const { api, isStatus } = useApi()

const success = ref(false)
const step = ref<'signup' | 'invitation'>('signup')

const invitation = ref<InvitationResponse>()

const token = route.query?.['invitation-token'] as string

if (token) {
  api.auth.invitation({ token }).then((res) => {
    step.value = 'invitation'
    invitation.value = res
  })
}

const workspace = computed(() => invitation.value?.workspace)

const showInvitation = computed(
  () => step.value === 'invitation' && invitation.value?.id,
)

const showSignup = computed(() => !showInvitation.value && !isStatus('loading'))

const layoutTitle = computed(() => {
  if (showInvitation.value || !showSignup.value || success.value) {
    return
  }

  return 'Create a new Vanilla account'
})
</script>

<template>
  <auth-layout :title="layoutTitle">
    <template v-if="workspace?.id" #extra>
      <div :class="$style.extra">
        <Avatar v-if="workspace?.logo" :src="workspace?.logo" :size="18" />

        <div v-else :class="$style.missing">
          <Icon icon="block" :size="10" />
        </div>

        <div>{{ workspace?.title }}</div>
      </div>
    </template>

    <AcceptInvite
      v-if="showInvitation"
      :invitation="invitation"
      @handle-create="step = 'signup'"
    />

    <Signup
      v-else-if="showSignup"
      :token="token"
      :invitation="invitation"
      @handle-success="success = true"
    />
  </auth-layout>
</template>

<style module lang="scss">
.extra {
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 6px;

  height: 32px;
  border-radius: 6px;
  border: 1px solid var(--neutral-alpha-4);

  @extend .medium-3;

  .missing {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral-3);
    color: var(--neutral-8);

    min-width: 18px;
    width: 18px;
    height: 18px;
    border-radius: 18px;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
