export const checkIsAuthRoute = (path: string | null | undefined) => {
  if (!path) return false
  return (
    path.startsWith('login') ||
    path.startsWith(`signup`) ||
    path.startsWith('forgot') ||
    path.startsWith('reset') ||
    path.startsWith('accept')
  )
}

export const checkIsGatedRoute = (path: string | null | undefined) => {
  if (!path) return true
  return (
    !path.startsWith('login') &&
    !path.startsWith(`signup`) &&
    !path.startsWith('forgot') &&
    !path.startsWith('reset') &&
    !path.startsWith('accept')
  )
}
