<script setup lang="ts">
import { AssigneeBadge, AssigneeMenu, Popover } from '@/components/common'
import type { PipelineContact } from '../../models/pipelineContact'
import { useCrmPipelineStore } from '../../pipelineStore'
import { useCrmStore } from '../../store'
import { computed, toRefs } from 'vue'
import type User from '@/models/user'

const props = defineProps<{
  contact: PipelineContact
  mode: 'pipeline' | 'global'
}>()
const { contact, mode } = toRefs(props)

const crmStore = useCrmStore()
const store = useCrmPipelineStore()

const assignee = computed(() =>
  mode.value === 'pipeline'
    ? contact.value.pipelineAssignee
    : contact.value.assignee,
)

const handleUpdate = (data: User[]) => {
  const action =
    mode.value === 'pipeline' ? store.assignContact : crmStore.assignContact
  return action(contact.value, data[0])
}
</script>
<template>
  <Popover>
    <template #content>
      <AssigneeMenu
        :single-selection="true"
        :assignees="assignee ? [assignee] : []"
        @update="handleUpdate"
      />
    </template>
    <AssigneeBadge :users="assignee ? [assignee] : []" />
  </Popover>
</template>
<style module lang="scss"></style>
