export default function detectLuminosity(color: string) {
  const input = +`0x${color
    .slice(1)
    .replace(color.length < 5 ? /./g : '', '$&$&')}`

  const r = input >> 16
  const g = (input >> 8) & 255
  const b = input & 255
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  if (hsp > 175) {
    return 'light'
  }

  return 'dark'
}
