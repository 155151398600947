import type { AxiosInstance } from 'axios'
import type { Team } from '../models/team'
import { TaskStatus } from '../models/taskStatus'
import { LoadTasksRequest, TaskCreateRequest, TaskUpdateRequest } from './types'
import { Task } from '../models/task'
import { Pagination } from '@/utils/api/types'
import User from '@/models/user'
import { TaskComment } from '../models/taskComment'
import { TaskLog } from '@modules/tasks/models/taskLog.ts'
import icons from '@/assets/icons'

export class TasksService {
  constructor(private instance: AxiosInstance) {}

  createTeam = (name: string) =>
    this.instance
      .post<Team>(`/task/teams`, { name, color: '#1e1f24', icon: 'arCube1' })
      .then((response) => response.data)

  updateTeam = (
    id: number,
    payload: { name?: string; color?: string; icon?: keyof typeof icons },
  ) =>
    this.instance
      .put<Team>(`/task/teams/${id}`, payload)
      .then((response) => response.data)

  deleteTeam = (id: number) =>
    this.instance.delete(`/task/teams/${id}`).then((response) => response.data)

  loadTeam = (id: number) =>
    this.instance
      .get<Team>(`/task/teams/${id}`)
      .then((response) => response.data)

  loadTeams = () =>
    this.instance.get<Team[]>(`/task/teams`).then((response) => response.data)

  createTeamStatus = (teamId: number, name: string) =>
    this.instance
      .post<TaskStatus>(`/task/teams/${teamId}/statuses`, { name })
      .then((response) => response.data)

  listTeamStatus = (teamId: number) =>
    this.instance
      .get<TaskStatus[]>(`/task/teams/${teamId}/statuses`)
      .then((response) => response.data)

  updateTeamStatus = (teamId: number, statusId: number, data: Partial<TaskStatus>) =>
    this.instance
      .put<TaskStatus>(`/task/teams/${teamId}/statuses/${statusId}`, data)
      .then((response) => response.data)

  updateTeamStatusOrder = (teamId: number, order: number[]) =>
    this.instance
      .put<TaskStatus>(`/task/teams/${teamId}/statuses/reorder`, {
        ids: order,
      })
      .then((response) => response.data)

  deleteTeamStatus = (teamId: number, statusId: number) =>
    this.instance
      .delete(`/task/teams/${teamId}/statuses/${statusId}`)
      .then((response) => response.data)

  loadTeamStatuses = (teamId: number) =>
    this.instance
      .get<TaskStatus[]>(`/task/teams/${teamId}/statuses`)
      .then((response) => response.data)

  createTask = (payload: TaskCreateRequest) => {
    const formData = new FormData()
    formData.append('title', payload.title)
    formData.append('status_id', payload.statusId.toString())
    formData.append('priority', payload.priority.toString())

    if (payload.parentId) {
      formData.append('parent_id', payload.parentId.toString())
    }

    if (payload.description) {
      formData.append('description', payload.description)
    }

    if (payload.startDate) {
      formData.append('start_date', payload.startDate)
    }

    if (payload.dueDate) {
      formData.append('due_date', payload.dueDate)
    }

    if (payload.assignees?.length) {
      payload.assignees.forEach((assignee) =>
        formData.append('assignees[]', assignee.toString()),
      )
    }

    if (payload.tags?.length) {
      payload.tags.forEach((tag) => formData.append('tags[]', tag.toString()))
    }

    if (payload.attachments.files?.length) {
      payload.attachments.files.forEach((file: File) =>
        formData.append('attachments[files][]', file),
      )
    }

    if (payload.attachments.images?.length) {
      payload.attachments.images.forEach((image: File) =>
        formData.append('attachments[images][]', image),
      )
    }

    return this.instance
      .post<Task>('/task/tasks', formData)
      .then((response) => response.data)
  }

  loadTasks = (request: LoadTasksRequest) =>
    this.instance
      .get<Task[]>(`/task/tasks`, {
        params: {
          ...request,
        },
      })
      .then((response) => response.data)

  getTaskByTeamIdAndNumber = (teamId: number, taskNumber: number) => this.instance
    .get<Task>(`/task/teams/${teamId}/tasks/${taskNumber}`)
    .then((response) => response.data)

  loadTask = (taskId: number) =>
    this.instance
      .get<Task>(`/task/tasks/${taskId}`)
      .then((response) => response.data)

  updateTask = (payload: Partial<TaskUpdateRequest>, taskId: number) => {
    const formData = new FormData()
    formData.append('_method', 'PUT')

    if (payload.title) {
      formData.append('title', payload.title)
    }

    if (payload.statusId) {
      formData.append('status_id', payload.statusId.toString())
    }

    if (payload.rank) {
      formData.append('rank', payload.rank)
    }

    if ('priority' in payload) {
      formData.append('priority', (payload.priority as number).toString())
    }

    if ('description' in payload) {
      formData.append('description', payload.description as string)
    }

    if (payload.startDate) {
      formData.append('start_date', payload.startDate)
    }

    if (payload.dueDate) {
      formData.append('due_date', payload.dueDate)
    }

    if ('assignees' in payload) {
      payload.assignees?.length
        ? payload.assignees.forEach((assignee) =>
            formData.append('assignees[]', assignee.toString()),
          )
        : formData.append('assignees', '')
    }

    if ('tags' in payload) {
      payload.tags?.length
        ? payload.tags.forEach((assignee) =>
            formData.append('tags[]', assignee.toString()),
          )
        : formData.append('tags', '')
    }

    if (payload.attachments?.files?.length) {
      payload.attachments.files
        .filter((file) => file instanceof File)
        .forEach((file) => formData.append('attachments[files][]', file))
    }

    if (payload.attachments?.images?.length) {
      payload.attachments.images
        .filter((image) => image instanceof File)
        .forEach((image: File) =>
          formData.append('attachments[images][]', image),
        )
    }

    if (payload.attachments?.removed_images?.length) {
      payload.attachments.removed_images.forEach((id: number) =>
        formData.append('attachments[removed_images][]', id.toString()),
      )
    }

    if (payload.attachments?.removed_files?.length) {
      payload.attachments.removed_files.forEach((id: number) =>
        formData.append('attachments[removed_files][]', id.toString()),
      )
    }

    return this.instance
      .post<Task>(`/task/tasks/${taskId}`, formData)
      .then((response) => response.data)
  }

  deleteTask = (taskId: number) =>
    this.instance
      .delete(`/task/tasks/${taskId}`)
      .then((response) => response.data)

  loadTeamMembers = (teamId: number) =>
    this.instance
      .get<User[]>(`task/teams/${teamId}/members`)
      .then((response) => response.data)

  createTeamMember = (teamId: number, memberId: number) =>
    this.instance
      .post<User>(`task/teams/${teamId}/members`, { memberId })
      .then((response) => response.data)

  deleteTeamMember = (teamId: number, userId: number) =>
    this.instance
      .delete(`task/teams/${teamId}/members/${userId}`)
      .then((response) => response.data)

  getTaskLogs = (taskId: number) =>
    this.instance
      .get<Pagination<TaskLog>>(`task/tasks/${taskId}/logs`)
      .then((response) => response.data)

  addTaskComment = (taskId: number, data: FormData) =>
    this.instance
      .post<TaskComment>(`task/tasks/${taskId}/comments`, data)
      .then((response) => response.data)
}
