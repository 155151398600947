import type User from '@/models/user'
import type { AxiosInstance } from 'axios'
import axios from 'axios'
import type { BrevoAttributes, BrevoError, BrevoUser } from '@/types/brevo'
import type { SignupRequest } from './api/types'

const isDevelopment = import.meta.env.MODE === 'development'

class BrevoService {
  private listId: number
  private instance: AxiosInstance

  private currentContact: BrevoUser | null = null

  constructor() {
    this.listId = Number(import.meta.env.VITE_BREVO_REQUESTS_LIST_ID)

    this.instance = axios.create({
      validateStatus: () => true,
      baseURL: import.meta.env.VITE_BREVO_API_HOST,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'api-key': import.meta.env.VITE_BREVO_API_KEY,
      },
    })
  }

  updateAttributes = async (attributes: Partial<BrevoAttributes> = {}) => {
    if (isDevelopment) {
      return console.log('Brevo attributes update', attributes)
    }

    if (!this.currentContact?.id) {
      return
    }

    await this.instance
      .put(encodeURI(this.currentContact.email), { attributes })
      .catch()
  }

  initializeUser = async (user: User | SignupRequest) => {
    if (!user?.email) {
      return
    }

    if (isDevelopment) {
      return console.log('Brevo init user', user?.email)
    }

    const response = await this.getBrevoUser(user)
    const brevoId = (response as BrevoUser)?.id

    if (brevoId) {
      this.currentContact = response as BrevoUser
      this.checkUsersList()

      return
    }

    const notFound = (response as BrevoError)?.code === 'document_not_found'

    if (notFound) {
      await this.createBrevoUser(user)
    }
  }

  reset = () => {
    if (isDevelopment) {
      return console.log('Brevo user reset')
    }

    this.currentContact = null
  }

  private createBrevoUser = async (user: User | SignupRequest) => {
    const attributes: Partial<BrevoAttributes> = {
      SIGNUP_STARTED: true,
      SUCCESSFUL_SIGNUP: !!(user as User).workspaces?.length,
    }

    await this.instance
      .post('', {
        email: user.email,
        listIds: [this.listId],
        updateEnabled: true,
        attributes,
      })
      .then((res) => res.data)
      .catch()

    const response = await this.getBrevoUser(user)

    const brevoId = (response as BrevoUser)?.id

    if (brevoId) {
      this.currentContact = response as BrevoUser
      this.checkUsersList()

      return
    }
  }

  private getBrevoUser = async (
    user: User | SignupRequest,
  ): Promise<BrevoUser | BrevoError | undefined> => {
    if (!user?.email) {
      return
    }

    const response = await this.instance
      .get<BrevoUser>(encodeURIComponent(user.email))
      .then((res) => res.data)
      .catch()

    return response
  }

  private checkUsersList = async () => {
    if (isDevelopment) {
      return
    }

    const isInList = this.currentContact?.listIds?.includes(this.listId)

    if (!this.currentContact?.id || isInList) {
      return
    }

    await this.instance
      .put(encodeURIComponent(this.currentContact.email), {
        listIds: [this.listId],
      })
      .catch()

    this.currentContact = {
      ...this.currentContact,
      listIds: [...this.currentContact.listIds, this.listId],
    }
  }
}

export const brevo = new BrevoService()
