<script setup lang="ts">
import { reactive, ref } from 'vue'
import { required, email } from '@vuelidate/validators'

import { Button, Label, Popup, TextField } from '@/components/common'
import useVuelidate from '@vuelidate/core'
import { useWorkspaceStore } from '@/store/workspace'
import { showToast, tracker } from '@/utils'
import { brevo } from '@/utils/brevo'

const popup = ref<typeof Popup>()
const store = useWorkspaceStore()

const form = reactive({
  email: '',
})

const rules = {
  email: { required, email },
}

const form$ = useVuelidate(rules, form, { $scope: false })

const handleOpen = () => {
  popup.value?.show()
}

const handleClose = () => {
  popup.value?.hide()
  form.email = ''
}

const handleInvite = async () => {
  await store.inviteMember(form.email)

  tracker.trackEvent('invited_user')
  brevo.updateAttributes({ INVITED_USER: true })

  showToast({ text: 'Invite has been sent to this user', type: 'success' })
  handleClose()
}
</script>

<template>
  <Button size="2" variant="soft" theme="neutral" @click="handleOpen">
    Invite new member
  </Button>

  <Popup ref="popup" title="Invite" @hide="form.email = ''">
    <form @submit.prevent="handleInvite">
      <div :class="$style.content">
        <div :class="$style.description">
          Invite your team member to this workspace to use Vanilla with you and
          your team!
        </div>

        <Label>
          Enter member email address
          <TextField v-model="form$.email.$model" placeholder="Email address" />
        </Label>
      </div>

      <div :class="$style.actions">
        <Button
          type="submit"
          size="3"
          variant="solid"
          theme="neutral"
          :loading="store.isStatus('posting')"
          :disabled="form$.$invalid"
        >
          Send invite
        </Button>
      </div>
    </form>
  </Popup>
</template>

<style module lang="scss">
.content {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 52px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  .description {
    @extend .regular-3;

    color: var(--neutral-11);
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
}
</style>
