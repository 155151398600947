<script setup lang="ts">
import { computed } from 'vue'

const { width } = defineProps<{
  width?: number
}>()

const menuWidth = computed(() => (width ? `${width}px` : '100%'))
</script>
<template>
  <ul :class="$style.container">
    <slot />
  </ul>
</template>
<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  row-gap: 4px;
  width: v-bind('menuWidth');

  hr {
    border-top: 0.5px solid var(--neutral-alpha-4);
    margin: 4px 0px;
  }

  > hr + hr {
    display: none;
  }
}
</style>
