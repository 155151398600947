<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'

import { Alert, Button, Icon, Label, TextField } from '@/components/common'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import useApi from '@/composables/api'
import { showToast } from '@/utils'

import SuccessIcon from './SuccessIcon.vue'

const success = ref(false)
const resent = ref(false)

const { api, error, isStatus } = useApi()

const form = reactive({
  email: '',
})

const formRules = {
  email: { email, required },
}

const form$ = useVuelidate(formRules, form)
const handleSubmit = () => {
  api.auth.forgot(form).then(() => {
    showToast({ text: 'Instructions sent to email' })

    if (!success.value) {
      success.value = true
      return
    } else if (success.value && !resent.value) {
      resent.value = true
    }
  })
}
</script>
<template>
  <auth-layout
    v-if="!success"
    title="Forgot password?"
    subtitle="Enter your email address and we will send you a recovery link to reset your password."
  >
    <form @submit.prevent="handleSubmit">
      <Label :class="$style.field">
        Email
        <TextField
          v-model="form$.email.$model"
          size="2"
          placeholder="johndoe@gmail.com"
          :error="error"
        />
      </Label>

      <div :class="$style.actions">
        <Button
          size="3"
          variant="solid"
          theme="neutral"
          :disabled="form$.$invalid || isStatus('posting')"
          :loading="isStatus('posting')"
        >
          Send recovery link
        </Button>
      </div>
    </form>

    <div :class="$style.footerActions">
      <router-link :to="{ name: 'login' }" :class="$style.link">
        Back to login
      </router-link>
    </div>

    <Alert v-if="error" :error="error" :class="$style.alert">
      <template #icon>
        <Icon icon="circleExclamation" :size="20" />
      </template>
    </Alert>
  </auth-layout>

  <auth-layout v-else>
    <div :class="$style.success">
      <SuccessIcon />
      <div :class="$style.labels">
        <div :class="$style.title">We&apos;ve just sent a recovery link to</div>
        <div :class="$style.value">{{ form.email }}</div>
      </div>

      <div :class="$style.resend">
        <Button
          v-if="!resent"
          size="3"
          variant="soft"
          theme="neutral"
          @click.prevent="handleSubmit"
        >
          Resend link
        </Button>

        <Alert
          v-else
          success="Link sent! Check your email"
          :class="$style.successAlert"
        />

        <div :class="$style.footerActions">
          <router-link :to="{ name: 'login' }" :class="$style.link">
            Back to login
          </router-link>
        </div>
      </div>

      <div :class="$style.check">
        Not getting an email? Check your spam folder!
      </div>
    </div>

    <Alert v-if="error" :error="error" :class="$style.alert">
      <template #icon>
        <Icon icon="circleExclamation" :size="20" />
      </template>
    </Alert>
  </auth-layout>
</template>

<style lang="scss" module>
@import './styles.scss';

.check {
  @extend .medium-3;

  margin-top: 32px;
  padding: 16px;

  color: var(--neutral-11);
  text-align: center;

  width: 100%;
  border: 1px dashed var(--neutral-alpha-4);
  border-radius: 12px;
}
</style>
