<script lang="ts" setup>
import Popup from './Popup.vue'
import Button from './Button.vue'
import { reactive, ref } from 'vue'
import TextField from './TextField.vue'
import Label from './Label.vue'

type ButtonInstance = InstanceType<typeof Button>

interface Action {
  title: string
  isConfirm?: boolean
  theme: ButtonInstance['$props']['theme']
  variant?: ButtonInstance['$props']['variant']
  handler?: () => void
}

interface Confirm {
  title: string
  message?: string
  confirmString?: string
  actions: Action[]
}

const popup = ref<InstanceType<typeof Popup> | null>(null)

const state = reactive<Confirm>({
  title: 'Are you sure?',
  message: undefined,
  confirmString: undefined,
  actions: [],
})
const confirm = ref('')

const show = ({ title, message, actions, confirmString }: Confirm) => {
  state.title = title ?? state.title
  state.message = message
  state.confirmString = confirmString
  state.actions = actions ?? [
    {
      title: 'Ok',
      variant: 'dark',
      handler: () => {
        close()
      },
    },
  ]
  popup.value && popup.value.show()
}
const close = () => {
  popup.value && popup.value.hide()
}
const handleClick = async (action: Action) => {
  action.handler && (await action.handler())
  close()
}
defineExpose({ show, close })
</script>
<template>
  <Popup ref="popup" :title="state.title">
    <div :class="['popup-content', $style.container]">
      <div :class="$style.message">{{ state.message }}</div>

      <Label v-if="state.confirmString" :class="$style.confirmString">
        Type to confirm
        <TextField v-model="confirm" size="2" />
      </Label>
    </div>
    <div :class="['popup-content', 'popup-actions']">
      <Button
        v-for="action in state.actions"
        :key="action.title"
        :theme="action.theme"
        :variant="action.variant"
        size="3"
        :disabled="
          state.confirmString &&
          action.isConfirm &&
          state.confirmString !== confirm
        "
        @click="handleClick(action)"
        >{{ action.title }}</Button
      >
    </div>
  </Popup>
</template>

<style module lang="scss">
.container {
  width: 384px;
  padding-bottom: 1rem;
}
.message {
  @extend .regular-3;
  color: var(--neutral-11);
}
.confirmString {
  margin-top: 24px;
}
</style>
