<script setup lang="ts">
import { computed, toRefs } from 'vue'

import { Avatar, Button, Icon } from '@/components/common'
import type { InvitationResponse } from '@/utils/api/types'

const props = defineProps<{ invitation?: InvitationResponse }>()
defineEmits<{ 'handle-create': [] }>()

const { invitation } = toRefs(props)

const workspace = computed(() => invitation.value?.workspace)

const name = computed(() => {
  return [workspace.value?.userFirstName, workspace.value?.userLastName]
    .filter(Boolean)
    .join(' ')
})
</script>
<template>
  <div :class="[$style.success, $style.container]">
    <Avatar v-if="workspace?.logo" :src="workspace?.logo" :size="72" />

    <div v-else :class="$style.placeholder">
      <Icon icon="block" :size="32" />
    </div>

    <div :class="$style.labels">
      <div :class="$style.join">Join your team on Vanilla</div>

      <div v-if="workspace?.id" :class="$style.data">
        {{ name }} <span>{{ workspace?.userEmail }}</span> has invited you to
        use Vanilla in a <span>{{ workspace?.title }}</span> workspace
      </div>
    </div>

    <Button
      variant="solid"
      size="3"
      theme="neutral"
      :class="$style.button"
      @click="$emit('handle-create')"
    >
      Create an account
    </Button>

    <hr />

    <div :class="$style.linksContainer">
      <div>By creating a Vanilla account you agree to the</div>
      <div :class="$style.links">
        <a
          href="https://vanillaapps.com/terms"
          target="_blank"
          :class="$style.link"
        >
          Terms Of Service
        </a>
        <div>and</div>
        <a
          href="https://vanillaapps.com/privacy"
          target="_blank"
          :class="$style.link"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import './styles.scss';

.container {
  align-items: center;

  .placeholder {
    width: 72px;
    height: 72px;
    border-radius: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral-3);
    color: var(--neutral-8);
  }

  .join {
    @extend .semibold-6;
  }

  .data {
    @extend .regular-4;
    color: var(--neutral-11);

    span {
      @extend .medium-4;
      color: var(--neutral-12);
    }
  }

  .button {
    width: 100%;
  }

  .linksContainer {
    @extend .regular-2;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: var(--neutral-11);

    * {
      text-align: center;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    a {
      @extend .medium-2;
      color: var(--neutral-11);
    }
  }
}
</style>
