<script setup lang="ts">
import {
  Button,
  Confirm,
  DropDown,
  Icon,
  type DropDownItem,
} from '@/components/common'
import type { Pipeline } from '../../models/pipeline'
import { reactive, ref } from 'vue'
import { useCrmStore } from '../../store'

const store = useCrmStore()
const props = defineProps<{
  pipeline: Pipeline
}>()

const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)

const rename = reactive({
  isActive: false,
  name: props.pipeline.name,
})

const handleUpdate = () => {
  rename.name.length &&
    store.updatePipeline(props.pipeline.id, rename.name).then(() => {
      rename.isActive = false
    })
}

const menu: DropDownItem[] = [
  {
    id: 'rename',
    title: 'Rename',
    handler: () => {
      rename.isActive = true
    },
  },
  {
    id: 'duplicate',
    title: 'Duplicate',
    handler: () => store.duplicatePipeline(props.pipeline.id),
  },
  {
    id: 'delete',
    title: 'Delete',
    type: 'destructive',
    handler: () =>
      confirmPopup.value?.show({
        confirmString: 'delete',
        title: 'Delete column',
        message: `You're about to delete pipeline with ${props.pipeline.contactCount} contacts in it. This action is permanent and cannot be undone. You and your team members will lose access to this pipeline forever. Please, confirm your purpose by typing “delete” in the input below. `,
        actions: [
          {
            title: 'Cancel',
            theme: 'neutral',
            variant: 'ghost',
          },
          {
            title: `Delete Column`,
            theme: 'danger',
            isConfirm: true,
            handler: () => store.deletePipeline(props.pipeline.id),
          },
        ],
      }),
  },
]
</script>
<template>
  <router-link
    v-slot="{ navigate }"
    custom
    :to="{
      name: 'CRMPipeline',
      params: { pipelineId: pipeline.id },
    }"
  >
    <tr :class="$style.row">
      <td width="32" @click="navigate">
        <span :class="$style.pipelineIcon">
          <Icon
            :icon="rename.isActive ? 'pencil' : 'layoutColumn'"
            :size="16"
          />
        </span>
      </td>
      <td v-if="rename.isActive" :class="$style.title">
        <input
          v-model="rename.name"
          v-focus
          :class="$style.textField"
          @keypress.enter="handleUpdate"
        />
      </td>
      <td v-else :class="$style.title" @click="navigate">
        {{ pipeline.name }}
      </td>
      <td width="124" :class="$style.meta" @click="navigate">
        {{ pipeline.contactCount || 0 }} contacts
      </td>
      <td width="124" :class="$style.meta" @click="navigate">
        {{ pipeline.stageCount || 0 }} columns
      </td>
      <td width="32">
        <DropDown :width="160" :menu="menu" :close-on-select="true">
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            icon="dotGrid1x3Horizontal"
          />
        </DropDown>
        <Confirm ref="confirmPopup" />
      </td>
    </tr>
  </router-link>
</template>
<style module lang="scss">
@import url(./common.module.scss);
.title {
  @extend .medium-3;
}
.meta {
  @extend .regular-3;
  color: var(--neutral-11);
}
.row {
  cursor: pointer;
}
</style>
