<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  modelValue: boolean
  size?: '1' | '2' | '3'
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits<{
  (e: 'update:modelValue', data: boolean): void
}>()

const handleChange = (e: Event) => {
  const target = e.target as HTMLInputElement
  emit('update:modelValue', target.checked)
}
</script>

<template>
  <label>
    <input
      type="checkbox"
      :checked="modelValue"
      :class="$style.input"
      @input="handleChange"
    />

    <span
      :class="[
        $style.toggle,
        modelValue && $style.active,
        $style[`size-${size || 1}`],
      ]"
    >
      <span :class="[$style.indicator, modelValue && $style.active]" />
    </span>
  </label>
</template>

<style module lang="scss">
.input {
  display: none;
}

.toggle {
  display: block;
  border-radius: 999px;
  background-color: var(--neutral-alpha-5);
  transition: all 0.1s;
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  padding: 2px;

  &:hover {
    background-color: var(--neutral-alpha-7);
  }

  &.active {
    background-color: var(--success-9);

    &:hover {
      background-color: var(--success-10);
    }
  }

  &.size-1 {
    width: 28px;
    height: 16px;

    --size: 12px;
  }

  &.size-2 {
    width: 36px;
    height: 20px;

    --size: 16px;
  }

  &.size-3 {
    width: 40px;
    height: 24px;

    --size: 20px;
  }

  .indicator {
    display: block;
    border-radius: 999px;
    background-color: var(--colors-basic-white);

    width: var(--size);
    height: var(--size);

    position: absolute;
    left: 2px;

    transition: all 0.1s;

    &.active {
      left: calc(100% - var(--size) - 2px);
    }
  }
}
</style>
