<script setup lang="ts">
import { computed, toRefs } from 'vue'

import { Avatar, Icon } from '@/components/common'
import type { WorkspaceMember } from '@/models/user'
import type WorkspaceInvitation from '@/models/workspaceInvitation'

import ActionDropdown from './ActionDropdown.vue'

const props = defineProps<{
  member?: WorkspaceMember
  invitation?: WorkspaceInvitation
}>()

const { member, invitation } = toRefs(props)

const displayData = computed(() => {
  if (member.value) {
    const name = member.value.name?.trim()

    return {
      main: name || member.value?.email,
      sub: name && member.value?.email,
    }
  }

  return {
    main: invitation.value?.email,
  }
})

const memberRole = computed(() =>
  member.value?.role === 'owner' ? 'Owner' : 'Admin',
)
</script>

<template>
  <tr
    :class="[
      $style.row,
      !!invitation && $style.invitation,
      !displayData.sub && $style.simple,
    ]"
  >
    <td width="50%">
      <div :class="$style.container">
        <Avatar
          v-if="member?.avatar"
          :src="member?.avatar"
          :title="displayData.main"
          :size="32"
        />
        <div v-else :class="$style.placeholder">
          <Icon icon="people" :size="18" />
        </div>

        <div :class="$style.labels">
          <div :class="$style.main">{{ displayData.main }}</div>
          <div :class="$style.sub">{{ displayData.sub }}</div>
        </div>
      </div>
    </td>
    <td width="40%" :class="$style.role">
      <slot v-if="member">{{ memberRole }}</slot>
      <slot v-else>Pending</slot>
    </td>
    <td width="10%">
      <ActionDropdown :member="member" :invitation="invitation" />
    </td>
  </tr>
</template>

<style module lang="scss">
.row {
  td {
    padding: 12px 0;
    vertical-align: middle;

    &:last-of-type {
      text-align: right;
    }
  }

  &.invitation {
    color: var(--neutral-11);
  }

  &.simple {
    td {
      padding: 16px 0;
    }
  }

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--neutral-3);
    color: var(--neutral-8);
  }

  .role {
    @extend .medium-3;
  }

  &:first-of-type {
    td {
      padding-top: 0;
    }
  }

  &:last-of-type {
    td {
      padding-bottom: 0;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  gap: 16px;

  .labels {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .main {
      @extend .medium-3;
    }

    .sub {
      @extend .regular-2;
      color: var(--neutral-11);
    }
  }
}
</style>
