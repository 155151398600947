<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { Icon } from '@/components/common'
import type icons from '@/assets/icons'
import type Ticket from '../models/ticket'
import { TicketStatus } from '../models/ticket'

const props = defineProps<{
  ticket: Ticket
  oval?: boolean
}>()
const { ticket } = toRefs(props)

const ticketStatus = computed(() => {
  if (ticket.value.status === TicketStatus.new) return 'New'
  if (ticket.value.status === TicketStatus.active) return 'Active'
  return 'Closed'
})

const styleClass = computed(() => {
  if (ticket.value.status === TicketStatus.new) return 'new'
  if (ticket.value.status === TicketStatus.active) return 'active'
  return 'complete'
})

const icon = computed<keyof typeof icons>(() => {
  if (ticket.value.status === TicketStatus.new) return 'ticketStatusNew'
  if (ticket.value.status === TicketStatus.active) return 'ticketStatusActive'
  return 'ticketStatusComplete'
})
</script>
<template>
  <div :class="[$style.container, $style[styleClass], oval && $style.oval]">
    <Icon :icon="icon" :size="16" />
    {{ ticketStatus }}
  </div>
</template>
<style module lang="scss">
.container {
  @extend .medium-3;
  display: inline-flex;
  align-items: center;
  text-wrap: nowrap;
  column-gap: 8px;
  padding: 6px 10px;
  border-radius: 6px;

  &.oval {
    border-radius: 999px;
  }
}

.new {
  i {
    color: var(--accent-11);
  }

  &.oval {
    color: var(--accent-alpha-11);
    background-color: var(--accent-alpha-3);
  }
}

.active {
  i {
    color: var(--warning-alpha-9);
  }

  &.oval {
    i {
      color: var(--warning-alpha-11);
    }
    background-color: var(--warning-alpha-3);
  }
}

.complete {
  i {
    color: var(--neutral-8);
  }

  &.oval {
    border: 1px dashed var(--neutral-alpha-6);
  }
}
</style>
