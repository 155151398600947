<script setup lang="ts">
import { Menu, MenuItem } from '@/components/common'
import Checkbox from '@/components/common/Checkbox.vue'
import type { TicketInterval } from '../../models/ticket'

const emits = defineEmits<{
  (e: 'update', status: TicketInterval): void
}>()

defineProps<{
  interval: TicketInterval
}>()

const handleCheckbox = (interval: TicketInterval) => {
  emits('update', interval)
}
</script>
<template>
  <Menu :width="164">
    <!-- <TextField placeholder="Add custom date" type="date" /> -->
    <MenuItem title="Today" @click="handleCheckbox('day')">
      <template #icon>
        <Checkbox :is-radio="true" :model-value="interval === 'day'" />
      </template>
    </MenuItem>
    <MenuItem title="This Week" @click="handleCheckbox('week')">
      <template #icon>
        <Checkbox :is-radio="true" :model-value="interval === 'week'" />
      </template>
    </MenuItem>
    <MenuItem title="This Month" @click="handleCheckbox('month')">
      <template #icon>
        <Checkbox :is-radio="true" :model-value="interval === 'month'" />
      </template>
    </MenuItem>
    <MenuItem title="This Year" @click="handleCheckbox('year')">
      <template #icon>
        <Checkbox :is-radio="true" :model-value="interval === 'year'" />
      </template>
    </MenuItem>
  </Menu>
</template>
<style module lang="scss"></style>
