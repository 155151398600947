<script setup lang="ts">
import type User from '@/models/user'
import { toRefs } from 'vue'
import { Avatar, Icon } from '@/components/common'

const props = defineProps<{
  avatarOnly?: boolean
  users?: User[]
}>()
const { users } = toRefs(props)
</script>
<template>
  <div :class="$style.container">
    <span v-if="users && users.length > 0" :class="$style.avatars">
      <avatar
        v-for="user in users"
        :key="user.id"
        :size="avatarOnly ? 24 : 16"
        :title="user.name"
        :src="user.avatar"
      />
    </span>
    <Icon v-else icon="unassignedIcon" :size="avatarOnly ? 24 : 16" />
    <div v-if="users && users.length > 0 && !avatarOnly" :class="$style.name">
      {{ users.length === 1 ? users[0].name : `${users.length} users` }}
    </div>
    <div v-if="users && users.length === 0 && !avatarOnly" :class="$style.name">
      Unassigned
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  @extend .medium-3;
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;

  transition: background-color 0.15s;

  &:hover {
    background-color: var(--neutral-alpha-3);
  }

  .avatars {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    > * + * {
      margin-left: -8px;
    }
  }

  &.name {
    flex-grow: 1;
  }
}
</style>
