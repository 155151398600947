<script setup lang="ts">
import { computed } from 'vue'

import { Button, DropDown, type DropDownItem } from '@/components/common'
import type Shortcut from '@/modules/inbox/models/shortcut'

defineProps<{
  shortcut: Shortcut
}>()

const emit = defineEmits<{
  (e: 'edit'): void
  (e: 'delete'): void
}>()

const dropdownOptions = computed<DropDownItem[]>(() => [
  {
    title: 'Edit shortcut',
    handler: () => emit('edit'),
  },
  {
    title: 'Delete shortcut',
    handler: () => emit('delete'),
  },
])
</script>

<template>
  <div :class="$style.shortcut">
    <div :class="$style.labels">
      <div :class="$style.command">{{ shortcut.command }}</div>
      <div :class="$style.description">{{ shortcut.description }}</div>
    </div>

    <DropDown
      close-on-select
      placement="bottom-end"
      :class="$style.dropdown"
      :menu="dropdownOptions"
    >
      <template #default="{ isOpen }">
        <Button
          icon="dotGrid1x3Horizontal"
          size="2"
          variant="ghost"
          theme="neutral"
          :class="isOpen && $style.opened"
        />
      </template>
    </DropDown>
  </div>
</template>

<style module lang="scss">
.shortcut {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 0;

  border-bottom: 1px solid var(--neutral-alpha-4);

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  .labels {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .command {
      @extend .medium-3;
    }

    .description {
      @extend .regular-2;
      color: var(--neutral-11);
    }
  }
}

.dropdown {
  color: var(--neutral-12);
  text-align: left;
}

.opened {
  background-color: var(--neutral-alpha-3);
}
</style>
