import type Contact from '@/models/contact'
import type User from '@/models/user'
import Ticket from '@modules/inbox/models/ticket.ts'

export interface File {
  url: string
  size: number
  mime: string
  name: string
}

export interface Email {
  id: string
  subject: string
  body: string
}

interface Attachments {
  email?: Email
  files: File[]
  userId?: number
}

export enum MessageType {
  normal = 0,
  note = 1,
  welcome = 2,
  chatEnded = 3,
  chatReopened = 4,
  operatorAssigned = 5,
  operatorRemoved = 6,
}

export default interface Message {
  id: number
  channel: 'chat' | 'email'
  message: string
  createdAt: string
  userId?: number
  contactId?: number
  messageType: MessageType
  attachments: Attachments
  readerId: number

  // computed properties for render, not from api
  date: string | null
  time: string | null
  operator?: User
  contact?: Contact
  ticket?: Ticket
}
