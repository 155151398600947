<script setup lang="tsx">
import { reactive, ref, watch } from 'vue'
import { Page } from '@/components/common/layout'
import {
  Button,
  Checkbox,
  Table,
  Icon,
  Popover,
  ResourceSelector,
  Popup,
  EmptyView, TextField,
} from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { useCrmStore, type ContactSearch } from './store'
import { storeToRefs } from 'pinia'
import {
  getCoreRowModel,
  getSortedRowModel,
  useVueTable,
  FlexRender,
  type ColumnDef,
  type RowSelectionState,
  type SortingState,
} from '@tanstack/vue-table'
import type { PipelineContact } from './models/pipelineContact'
import FilterMenu from './components/menus/FilterMenu.vue'
import FilterSummary from './components/FilterSummary.vue'
import BulkActions from './components/actions/BulkActions.vue'
import NameColumn from './components/ListView/NameColumn.vue'
import TagsColumn from './components/ListView/TagsColumn.vue'
import AssigneeColumn from './components/ListView/AssigneeColumn.vue'
import { watchDebounced } from '@vueuse/core'
import PipelineColumn from './components/ListView/PipelineColumn.vue'
import AddContact from './components/AddContact.vue'

const workspaceStore = useWorkspaceStore()

const store = useCrmStore()
store.loadContacts({})
const { contacts } = storeToRefs(store)

const addContactRef = ref<typeof Popup>()

const searchState = reactive<ContactSearch>({})
watchDebounced(
  searchState,
  () => {
    store.loadContacts(searchState)
  },
  { debounce: 500 },
)

watch(
  () => store.isAddContactOpen,
  (value) => {
    value && addContactRef.value?.show()
    !value && addContactRef.value?.hide()
  },
)

const sorting = ref<SortingState>([
  {
    id: 'createdAt',
    desc: true,
  },
])
const rowSelection = ref<RowSelectionState>({})

const columnWidths = ['3%', '40%', '15%', '20%', '12%', '10%']
const columns: ColumnDef<PipelineContact>[] = [
  {
    id: 'select',
    header: ({ table }) => {
      return (
        <Checkbox
          modelValue={table.getIsAllRowsSelected()}
          intermediate={table.getIsSomeRowsSelected()}
          onClick={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    cell: ({ row }) => {
      return (
        <div class="selection">
          <Checkbox
            modelValue={row.getIsSelected()}
            onClick={row.getToggleSelectedHandler()}
          />
        </div>
      )
    },
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ row }) => {
      return <NameColumn contact={row.original} />
    },
  },
  {
    header: 'Company',
    accessorKey: 'company.name',
    cell: ({ row }) => row.original.company?.name || '-',
  },
  {
    header: 'Tags',
    enableSorting: false,
    cell: ({ row }) => {
      return <TagsColumn contact={row.original} mode="global" />
    },
  },
  {
    header: 'Assignee',
    accessorFn: (row) => row.name,
    cell: ({ row }) => {
      return <AssigneeColumn contact={row.original} mode="global" />
    },
  },
  {
    header: 'Pipeline',
    enableSorting: false,
    cell: ({ row }) => {
      return <PipelineColumn contact={row.original} />
    },
  },
]

const table = useVueTable({
  get data() {
    return contacts.value
  },
  state: {
    get sorting() {
      return sorting.value
    },
    get rowSelection() {
      return rowSelection.value
    },
  },
  getRowId: (data) => `${data.id}`,
  enableRowSelection: true,
  onSortingChange: (updaterOrValue) => {
    sorting.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(sorting.value)
        : updaterOrValue
  },
  onRowSelectionChange: (updateOrValue) => {
    rowSelection.value =
      typeof updateOrValue === 'function'
        ? updateOrValue(rowSelection.value)
        : updateOrValue
  },
  columns,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
})
</script>

<template>
  <Page>
    <template #topBar>
      {{ `${workspaceStore.workspace?.title} CRM` }}
      <Button
        variant="outline"
        theme="neutral"
        size="2"
        @click="store.isAddContactOpen = true"
      >
        Create Contact
      </Button>
    </template>
    <div :class="$style.topBar">
      <div :class="$style.rightSide">
        <Popover placement="bottom-start">
          <template #content>
            <ResourceSelector
              :items="store.pipelines"
              item-key="id"
              title-key="name"
              @update="
                (data) =>
                  $router.push({
                    name: 'CRMPipeline',
                    params: { pipelineId: data.id },
                  })
              "
            />
          </template>
          <Button
            :class="$style.selector"
            icon-right="chevronGrabberVertical"
            variant="ghost"
            theme="neutral"
            size="2"
            >All contacts</Button
          >
        </Popover>
        <span :class="$style.contacts"
          >{{ store.pagination?.totalItems }} contacts</span
        >
      </div>
      <div style="display: flex; gap: 8px">
        <TextField v-model="searchState.search" placeholder="Search">
          <template #pre>
            <Icon icon="magnifyingGlass" :size="18" />
          </template>
        </TextField>

        <Popover placement="bottom-end">
          <template #content>
            <FilterMenu
              mode="global"
              :search="searchState"
              @update="(data) => Object.assign(searchState, data)"
            />
          </template>
          <Button variant="soft" theme="neutral" size="2" icon="filter1"
          >Filters</Button
          >
        </Popover>
      </div>
    </div>
    <FilterSummary
      v-if="Object.values(searchState).filter(Boolean).length > 0"
      mode="global"
      :search="searchState"
      @update="(data) => Object.assign(searchState, data)"
    />
    <BulkActions
      v-if="Object.keys(rowSelection).length > 0"
      mode="global"
      :selection="Object.keys(rowSelection).map((r) => Number(r))"
    />
    <EmptyView
      v-if="store.isStatus('loaded') && store.pagination?.totalItems === 0"
      icon="group2"
      title="Your Contacts list is empty right now."
      description="You don't have any contacts currently. Let's start adding some!"
    >
      <Button
        size="2"
        variant="outline"
        theme="neutral"
        @click="store.isAddContactOpen = true"
        >Create Contact</Button
      >
    </EmptyView>
    <EmptyView
      v-else-if="store.isStatus('loaded') && store.contacts.length === 0"
      icon="peopleRemove"
      title="No contacts found."
      description="Nothing found with your filters. Try to adjust your filters preferences or clear all filters."
    />
    <Table v-else>
      <thead>
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
        >
          <th
            v-for="(header, index) in headerGroup.headers"
            :key="header.id"
            :colSpan="header.colSpan"
            :width="columnWidths[index]"
            @click="header.column.getToggleSortingHandler()?.($event)"
          >
            <div :class="[$style.header, index === 0 && 'selection']">
              <FlexRender
                v-if="!header.isPlaceholder"
                :render="header.column.columnDef.header"
                :props="header.getContext()"
              />
              <Icon
                v-if="
                  header.column.getCanSort() && !header.column.getIsSorted()
                "
                icon="chevronGrabberVertical"
              />
              <!-- <Icon
              v-else
              :icon="header.column.getIsSorted() === 'asc' ? 'ch' : ''"
            /> -->
              {{
            { asc: ' 🔼', desc: ' 🔽' }[header.column.getIsSorted() as string]
              }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in table.getRowModel().rows" :key="row.id">
          <td
            v-for="cell in row.getVisibleCells()"
            :key="cell.id"
            :width="`${cell.column.getSize()}px`"
            :class="cell.column.getIndex() === 3 ? $style.noPadding : ''"
          >
            <FlexRender
              :render="cell.column.columnDef.cell"
              :props="cell.getContext()"
            />
          </td>
        </tr>
      </tbody>
    </Table>
    <Popup
      ref="addContactRef"
      title="Add Contact"
      @hide="store.isAddContactOpen = false"
    >
      <AddContact />
    </Popup>
  </Page>
</template>

<style module lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noPadding {
  padding: 0 !important;
}

.topBar {
  margin: 32px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rightSide {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .selector {
    @extend .semibold-4;
    padding-left: 8px;
    padding-right: 8px;
  }

  .contacts {
    @extend .regular-3;
    color: var(--neutral-11);
  }
}
</style>
