<script setup lang="ts">
import Icon from '@/components/common/Icon.vue'
import { useApplicationStore } from '@/store'
import { noop } from 'lodash'
import { useRouter } from 'vue-router'

const router = useRouter()
const appStore = useApplicationStore()

appStore
  .getUser()
  .then((data) => {
    const { workspaces, firstName } = data

    if (workspaces.length > 0 && firstName) {
      const [workspace] = workspaces
      router.replace({
        name: 'Dashboard',
        params: { workspaceId: workspace.id },
      })
    } else {
      return router.replace({
        name: 'Onboarding',
      })
    }
  })
  .catch(noop)
</script>
<template>
  <div :class="$style.layout">
    <div :class="$style.container">
      <Icon icon="vanillaIcon" :size="100" class="animate-bounce" />
      <b>Loading...</b>
    </div>
  </div>
</template>
<style module lang="scss">
.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.container {
  display: flex;
  width: 128px;
  height: 128px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
