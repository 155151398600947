import axios from 'axios'
import Echo from 'laravel-echo'

interface Channel {
  name: string
}

type Callback = (status: boolean, data: object) => void

export default function useWebsockets() {
  const echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: Channel) => {
      return {
        authorize: (socketId: string, callback: Callback) => {
          const instance = axios.create({
            withCredentials: true,
            withXSRFToken: true,
          })
          instance
            .post(`${import.meta.env.VITE_API_HOST}broadcasting/auth`, {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(false, response.data)
            })
            .catch((error) => {
              callback(true, error)
            })
        },
      }
    },
  })

  return echo
}
