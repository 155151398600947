<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'

import { Button, Label, Popup, TextField } from '@/components/common'
import { useApplicationStore } from '@/store'

const deleteAccountMail = `mailto:hello@vanillaapps.com?subject=Request%20for%20account%20deletion&body=Hello%2C%20i%20would%20like%20to%20have%20my%20account%20permanently%20deleted.%20Please%20respond%20with%20confirmation%20once%20the%20account%20has%20been%20deleted`

const appStore = useApplicationStore()

const emailRef = ref(appStore.user?.email)

const deletePopup = ref<InstanceType<typeof Popup> | null>(null)

const form = reactive({
  email: '',
})

const rules = {
  email: { required, email, sameAs: (val: string) => val === emailRef.value },
}

const form$ = useVuelidate(rules, form, { $scope: false })

const handleOpen = () => {
  deletePopup.value?.show()
}

const handleClose = () => {
  deletePopup.value?.hide()
  form.email = ''
}

const handleDelete = async () => {
  await appStore.deleteAccount()
  handleClose()
}
</script>

<template>
  <Button variant="soft" size="2" theme="danger" @click="handleOpen">
    Delete account
  </Button>

  <Popup ref="deletePopup" title="Delete account" @hide="form.email = ''">
    <form @submit.prevent="handleDelete">
      <div :class="$style.content">
        <div :class="$style.description">
          If you&apos;d like to delete your account, please, confirm your email
          by typing it. This action is permanent and cannot be undone.
          You&apos;ll lose your access to all workspaces that you are a member
          of.
        </div>

        <Label>
          Confirm account deletion
          <TextField v-model="form$.email.$model" />
        </Label>
      </div>

      <div :class="$style.actions">
        <Button
          type="submit"
          variant="solid"
          theme="danger"
          size="3"
          :as="form$.$invalid ? 'button' : 'a'"
          :disabled="form$.$invalid"
          :href="deleteAccountMail"
        >
          Delete account
        </Button>
      </div>
    </form>
  </Popup>
</template>

<style module lang="scss">
.content {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 52px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  .description {
    @extend .regular-3;

    color: var(--neutral-11);
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
}
</style>
