<script setup lang="ts">
import {
  TextField,
  Button,
  Icon,
  Select,
  type SelectItem,
} from '@/components/common'
import { useInboxStore } from '@/modules/inbox/store'
import { showToast } from '@/utils'
import { ref } from 'vue'

const provider = ref<SelectItem>()
const email = ref('')
const store = useInboxStore()

const providers: SelectItem[] = [
  {
    label: 'AWS',
    value: 'aws',
    url: 'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/resource-record-sets-creating.html',
  },
  {
    label: 'Azure',
    value: 'azure',
    url: 'https://azure.microsoft.com/en-us/blog/a-records-cname-and-using-dns-with-windows-azure-web-sites-waws',
  },
  {
    label: 'Cloudflare',
    value: 'cloudflare',
    url: 'https://developers.cloudflare.com/dns/manage-dns-records/how-to/create-dns-records',
  },
  {
    label: 'Godaddy',
    value: 'godaddy',
    url: 'https://ie.godaddy.com/help/add-a-cname-record-19236',
  },
  {
    label: 'Google Domains',
    value: 'google',
    url: 'https://support.google.com/domains/answer/3290350#zippy=%2Cadd-a-resource-record',
  },
]

const handleAdd = () => {
  store.addDomain(email.value)
}

const handleCopy = (value: string) => {
  navigator.clipboard.writeText(value).then(() => {
    showToast({ text: 'Copied!' })
  })
}

store.getDomain()
</script>
<template>
  <div v-if="store.isStatus('loading') && !store.domain">
    <Icon icon="vanillaIcon" :size="16" />
  </div>

  <ul :class="$style.steps">
    <li :class="$style.step">
      <span :class="$style.number">1</span>
      <form :class="$style.content" @submit.prevent="handleAdd">
        <p :class="$style.heading">Add your email</p>
        <div :class="$style.body">
          <TextField
            v-model="email"
            type="email"
            placeholder="johndoe@example.com"
            size="2"
          />
        </div>
        <div :class="$style.body">
          <Button
            :loading="store.isStatus('posting')"
            size="2"
            variant="soft"
            theme="neutral"
            >Generate DNS records</Button
          >
        </div>
      </form>
    </li>

    <li :class="$style.step">
      <span :class="$style.number">2</span>
      <div :class="$style.content">
        <p :class="$style.heading">Get started authenticating your domain</p>
        <p :class="$style.body">
          From your domain provider's website, find the page or area that has
          your domain or domain records - usually called manage domain, DNS
          custom records or zone files.
        </p>

        <div :class="$style.provider">
          <p :class="$style.providerHeading">
            Need detailed guide of how to do it?
          </p>
          <p :class="$style.body">
            <Select
              v-model="provider"
              size="2"
              placeholder="Select your domain provider"
              item-key="label"
              :options="providers"
            />
          </p>
        </div>
        <p v-if="provider" :class="$style.body">
          <a :href="provider.url as string" target="_blank">
            <Button icon="arrowOutOfBox" size="2" variant="soft" theme="neutral"
              >Open {{ provider.label }} instructions</Button
            >
          </a>
        </p>
      </div>
    </li>

    <li :class="$style.step">
      <span :class="$style.number">3</span>
      <div :class="$style.content">
        <p :class="$style.heading">Copy your records</p>
        <p :class="$style.body">
          You are going to add two CNAME records to your domain on your domain
          provider’s website. They give Vanilla permission to send emails on
          your behalf by authenticating this domain
        </p>

        <div v-if="!store.domain?.domain" :class="$style.addEmailContainer">
          <div>
            <Icon icon="emailEnvelope" :size="24" />
          </div>
          <div>
            <p :class="$style.heading">Add your email address</p>
            <p :class="$style.body">
              To get a DNS records, you need to add your email address first
            </p>
          </div>
        </div>

        <ul v-else-if="store.domain?.domain">
          <li
            v-for="(dns, index) in store.domain.dns"
            :key="index"
            :class="$style.records"
          >
            <p :class="$style.heading">{{ dns.type }}</p>
            <div :class="$style.record">
              <p :class="$style.body">{{ dns.name }}</p>

              <Button
                :class="$style.btn"
                size="1"
                variant="ghost"
                theme="neutral"
                icon="squareBehindSquare1"
                @click="handleCopy(dns.name)"
              />
            </div>
            <div :class="$style.record">
              <p :class="$style.body">{{ dns.value }}</p>
              <Button
                :class="$style.btn"
                size="1"
                variant="ghost"
                theme="neutral"
                icon="squareBehindSquare1"
                @click="handleCopy(dns.value)"
              />
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>
<style module lang="scss">
.addEmailContainer {
  padding: 16px;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 1px dashed var(--neutral-alpha-4, rgba(0, 11, 54, 0.09));
  margin-top: 24px;
  gap: 16px;
  margin-bottom: 52px;
}

.provider {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.providerHeading {
  @extend .medium-3;
  margin: auto 0;
}

.steps {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.step {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  .number {
    @extend .medium-2;
    display: flex;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 9999px;
    background-color: var(--neutral-alpha-3);
    align-items: center;
    justify-content: center;
    color: var(--neutral-alpha-12);
  }

  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .heading {
    @extend .medium-3;
  }

  .body {
    @extend .regular-3;
    margin-top: 8px;
    color: var(--neutral-11);
  }
}

.records {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 16px;
  justify-content: space-between;
  padding-bottom: 10px;

  .record {
    gap: 6px;
    display: flex;
    flex-direction: row;

    .body {
      width: 250px;
      overflow: hidden;
    }

    .btn {
      margin-top: 7px;
    }
  }

  .heading {
    @extend .medium-3;
    margin-top: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--neutral-alpha-4);
  }
}
</style>
