<script setup lang="ts">
import { Button } from '@/components/common'
import { Breadcrumbs, Crumb } from '@/components/common/layout'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import AppDetail from '@/components/marketplace/AppDetail.vue'
import { useApplicationStore } from '@/store'
import { useWorkspaceStore } from '@/store/workspace'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const appId = Number(route.params.appId)

const workspaceStore = useWorkspaceStore()
workspaceStore.getApps()

const store = useApplicationStore()
store.getApp(appId)

const crumbs = computed<Crumb[]>(() => [
  {
    title: 'Marketplace',
    url: { name: 'OnboardingMarketplace' },
  },
  {
    title: store.app?.name || '',
  },
])
</script>
<template>
  <auth-layout :full-width="true">
    <template #logo>
      <Breadcrumbs :crumbs="crumbs" />
    </template>
    <template #extra>
      <div :class="$style.extra">
        <div :class="$style.email">{{ store.user?.email }}</div>

        <Button
          size="2"
          variant="outline"
          theme="neutral"
          @click="store.signOut"
        >
          Logout
        </Button>
      </div>
    </template>
    <div :class="$style.container">
      <AppDetail v-if="store.app" :app="store.app" />
    </div>
  </auth-layout>
</template>
<style module lang="scss">
.extra {
  display: flex;
  align-items: center;
  gap: 12px;

  .email {
    @extend .medium-3;
  }
}
.container {
  display: flex;
  justify-content: center;
}
</style>
