<script setup lang="ts">
import { computed } from 'vue'
import { Task } from '../../models/task'
import PriorityIndicator from '../PriorityIndicator.vue'
import StageIndicator from '../StageIndicator.vue'
import {
  AssigneeBadge,
  Tag,
  Popover,
  ResourceSelector,
  Button,
  AssigneeMenu,
  Icon,
} from '@/components/common'
import { DateTime } from 'luxon'
import { TaskPriorityMenu } from '../constants'
import { useTasksStore } from '../../store'

const props = defineProps<{
  task: Task
}>()

const store = useTasksStore()

const urgency = computed<string | undefined>(() => {
  if (!props.task.dueDate) return undefined

  const dueDate = DateTime.fromSQL(props.task.dueDate)

  const tomorrow = DateTime.now().plus({ day: 1 })
  if (tomorrow.hasSame(dueDate, 'day')) return 'Tomorrow'

  const today = DateTime.now()
  if (today.hasSame(dueDate, 'day')) return 'Today'

  return undefined
})

const stageCount = computed(() => {
  const count = props.task.team?.statuses?.length

  return count || 0
})

const taskPriority = computed(() => {
  return TaskPriorityMenu.find((p) => p.id === props.task?.priority)
})

const statuses = computed(() => {
  return props.task.team?.statuses || []
})
</script>
<template>
  <router-link
    v-slot="{ navigate }"
    custom
    :to="{
      name: 'TasksTask',
      params: { taskNumber: task.number, teamId: task.teamId },
    }"
  >
    <tr :class="$style.row">
      <td>
        <Popover>
          <template #content>
            <ResourceSelector
              :items="statuses"
              :selected="task?.status"
              item-key="id"
              title-key="name"
              selection="single"
              @update="
                (data) => {
                  store.updateTask(task.id, {
                    status: data,
                  })
                }
              "
            />
          </template>
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            :class="$style.button"
          >
            <template #icon>
              <StageIndicator
                :stage="task.status.order"
                :size="16"
                :total-stages="stageCount"
              />
            </template>
          </Button>
        </Popover>
      </td>
      <td>
        <Popover>
          <template #content>
            <ResourceSelector
              :items="TaskPriorityMenu"
              :selected="taskPriority"
              item-key="id"
              title-key="name"
              selection="single"
              @update="
                (data) => {
                  store.updateTask(task.id, {
                    priority: data,
                  })
                }
              "
            >
              <template #icon="{ item }">
                <PriorityIndicator v-if="item" :priority="item.id" />
              </template>
            </ResourceSelector>
          </template>
          <Button
            icon="unassignedIcon"
            size="2"
            variant="ghost"
            theme="neutral"
            :class="$style.button"
          >
            <template #icon>
              <PriorityIndicator :priority="task.priority || 0" :size="16" />
            </template>
          </Button>
        </Popover>
      </td>
      <td>
        <Tag size="2" variant="outline">{{ task.team?.name }}</Tag>
      </td>
      <td :class="$style.meta" @click="navigate">
        {{ task.team?.code }}-{{ task.number }}
      </td>
      <td @click="navigate">
        <div :class="$style.title">
          {{ task.title }}
        </div>
      </td>
      <td :class="$style.tags">
        <Tag
          v-for="tag in task.tags"
          :key="tag.id"
          size="2"
          variant="outline"
          >{{ tag.name }}</Tag
        >
        <Tag
          v-if="urgency"
          size="2"
          variant="outline"
          icon="calendar2"
          :class="$style.urgent"
          >Due {{ urgency }}</Tag
        >
      </td>
      <td>
        <Popover>
          <template #content>
            <AssigneeMenu
              :assignees="task?.assignees?.length ? [task?.assignees[0]] : []"
              :single-selection="true"
              @update="
                (data) => {
                  store.updateTask(
                    task.id,
                    {
                      assignee: data[0],
                    },
                    true,
                  )
                }
              "
            />
          </template>
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            :class="$style.button"
          >
            <template #icon>
              <AssigneeBadge
                v-if="task?.assignees?.length"
                :users="task.assignees"
                avatar-only
              />
              <Icon v-else icon="unassignedIcon" :size="16" />
            </template>
          </Button>
        </Popover>
      </td>
    </tr>
  </router-link>
</template>
<style module lang="scss">
.title {
  @extend .medium-3;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 800px;
}
.meta {
  @extend .regular-3;
  color: var(--neutral-11);
}
.row {
  cursor: pointer;
}
.tags {
  span {
    margin-right: 8px;
  }
}
.urgent {
  color: var(--danger-alpha-11);
}
</style>
